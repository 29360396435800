.AppActivityWorkflow {
}

.AppActivityWorkflow .graphic {
  padding: 35px 45px 45px 0;
  display: flex;
  align-items: center;
}
.AppActivityWorkflow .graphic > .pointers {
  flex: 0 0 30px;
  position: relative;
}
.AppActivityWorkflow .graphic > .pointers hr {
  margin: 0;
  border-width: 4px;
  border-color: #C7C6CC;
}

.AppActivityWorkflow .graphic > .end span {
  left: auto;
  right: 0;
}
.AppActivityWorkflow .graphic > .tag {
  position: relative;
  top: 18px;
}
.AppActivityWorkflow .graphic > .tag hr {
  margin: 0 0 0 50%;
  width: 50%;
  border-width: 4px;
  border-color: #C7C6CC;
}
.AppActivityWorkflow .graphic > .tag a,
.AppActivityWorkflow .graphic > .tag span.copy {
  padding: 14px 10px 10px;
  display: inline-block;
  color: #908C99;
  line-height: 1;
  font-size: 12px;
  white-space: nowrap;
  font-family: "Source Code Pro", monospace;
}
.AppActivityWorkflow .graphic > .tag span.separator {
  margin-left: 50%;
  position: absolute;
  top: -3px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #C7C6CC;
  border-radius: 50%;
}
.AppActivityWorkflow .graphic > .tag.end hr {
  margin-left: 0;
}

.AppActivityWorkflow .list {
  margin: 0;
  padding: 15px;
  background-color: #E2E2E5;
  flex: 0 0 300px;
  width: 320px;
  border-radius: 6px;
}

.AppActivityWorkflow .AppActivityWorkflowBuild {
  margin-bottom: 15px;
}
.AppActivityWorkflow .AppActivityWorkflowBuild:last-child {
  margin-bottom: 0;
}
