.UpdatePasswordForm {
}

.UpdatePasswordForm .help-block {
  margin-top: 7px;
  font-size: 14px;
}

.UpdatePasswordForm .controls {
  margin-top: 20px;
}
