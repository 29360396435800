.ResourceSearchModal {
}

@media all and (min-width: 768px) {
  .ResourceSearchModal .modal-dialog {
    width: 80%;
    min-width: 600px;
    max-width: 900px;
  }
}

.ResourceSearchModal .modal-content {
  overflow: hidden;
}
.ResourceSearchModal .modal-body {
  padding: 0;
}

/**
 * Search Form
 */
.ResourceSearchModal form .form-group {
  margin-bottom: 0;
  z-index: 1;
  position: relative;
}
.ResourceSearchModal form input.form-control {
  border-radius: 0;
  border-color: #E2E2E5;
  border-width: 0 0 1px 0;
  padding: 14px 40px 13px 36px;
  font-size: 18px;
  cursor: pointer;
  height: auto;
  background-color: transparent;
}
.ResourceSearchModal form input.form-control:disabled {
  cursor: not-allowed;
}
.ResourceSearchModal form input.form-control:focus {
  border-radius: 0;
  border-color: #E2E2E5;
  cursor: auto;
}
.ResourceSearchModal form .form-group label {
  position: absolute;
  left: 12px;
  top: 50%;
  margin-top: -12px;
  font-size: 17px;
  opacity: 0.7;
  color: #AEACB5;
  pointer-events: none;
}
.ResourceSearchModal form .form-group .TextButton {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -22px;
  color: #BBB;
  width: 40px;
  height: 44px;
  text-align: center;
}
.ResourceSearchModal form .form-group .TextButton:hover {
  color: #888;
}
.ResourceSearchModal form .form-group .TextButton i.fa {
  display: block;
  font-size: 18px;
  line-height: 44px;
}

.ResourceSearchModal .placeholder-info {
  padding: 14px 15px 11px;
}

/**
 * Search Tips
 */
.ResourceSearchModal .search-tips {
}
.ResourceSearchModal .search-tips:last-child {
  padding-right: 0;
  border-right: none;
}
.ResourceSearchModal .search-tips .SectionHeader {
  color: #AEACB5;
  margin-bottom: 8px;
}
.ResourceSearchModal .search-tips .SectionHeader i.fa {
  margin-right: 1px;
  opacity: 0.8;
}
.ResourceSearchModal .search-tips ul {
  margin-bottom: 0;
  padding-left: 22px;
}
.ResourceSearchModal .search-tips ul li {
  color: #908C99;
  font-size: 14px;
  line-height: 1.8;
}
.ResourceSearchModal .search-tips ul li span {
  font-weight: 500;
}

/**
 * Recent Searches
 */
.ResourceSearchModal .recent-searches {
  margin-bottom: 15px;
}
.ResourceSearchModal .recent-searches .SectionHeader {
  color: #AEACB5;
  margin-bottom: 8px;
}
.ResourceSearchModal .recent-searches .SectionHeader i.fa {
  margin-right: 1px;
  opacity: 0.8;
}
.ResourceSearchModal .recent-searches .item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  display: block;
  line-height: 1.8;
}
.ResourceSearchModal .recent-searches .item span:nth-child(1) {
  opacity: 0.8;
}
.ResourceSearchModal .recent-searches .item i.fa {
  margin: 0 6px 0 2px;
  color: #AEACB5;
}

/**
 * Results
 */
.ResourceSearchModal .search-results {
}
.ResourceSearchModal .search-results .item {
  padding: 11px 12px 12px;
  border-top: 1px solid #EFEFF1;
  display: block;
  cursor: pointer;
}
.ResourceSearchModal .search-results .item p {
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: #908C99;
}
.ResourceSearchModal .search-results .item p .full-name {
  color: #5D5966;
}
.ResourceSearchModal .search-results .item p b {
  font-weight: 500;
  color: #2E2D33;
}
.ResourceSearchModal .search-results .item i.fa {
  margin: 0 6px 0 2px;
  color: #AEACB5;
}
.ResourceSearchModal .search-results .item.cursor {
  background-color: #F9F9FA;
}
.ResourceSearchModal .search-results .item.current {
  background-color: #F9F9FA;
}
.ResourceSearchModal .search-results .item:first-child {
  border-top-color: transparent;
}
.ResourceSearchModal .search-results .empty {
  padding: 12px;
  display: block;
  color: #C7C6CC;
}
