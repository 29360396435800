.ServiceStageRemove {
  padding: 20px;
}

.ServiceStageRemove .BuildInfoPanel {
  margin-bottom: 20px;
}

.ServiceStageRemove .build-log,
.ServiceStageRemove .build-sls-info {
}

.ServiceStageRemove .BuildHelpPanel {
  margin-bottom: 30px;
}
