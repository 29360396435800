.OrgSSHPanel {
}

.OrgSSHPanel p.current {
  font-size: 14px;
  color: #5D5966;
}
.OrgSSHPanel p.current.disabled {
  opacity: 0.5;
}

.OrgSSHPanel > .SectionHeader {
  margin-bottom: 15px;
}

.OrgSSHPanel .SectionDescriptionLabel {
}

.OrgSSHPanel .btnUpdate {
}

.OrgSSHPanel form .controls {
  margin-top: 20px;
}
