.EmptyListPanel {
  height: 240px;
  border-radius: 4px;
  border: 4px dashed #DADEE2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.EmptyListPanel p {
  color: #908C99;
  font-size: 20px;
}
.EmptyListPanel .TextButton {
  font-size: 20px;
}
