.NewAppRepoForm {
}

.NewAppRepoForm.enabled > .controls {
  margin-top: 20px;
}
.NewAppRepoForm > .controls {
  margin-top: 10px;
}
.NewAppRepoForm > .controls .TextButton {
  font-size: 14px;
}

.NewAppRepoForm .validation {
  padding-top: 7px;
  font-size: 14px;
  color: #FC6020;
}

