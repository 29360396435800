.AppReports {
  padding: 20px;
}

.AppReports .ScreenHeader {
}
.AppReports.multiple .ScreenHeader {
  margin-bottom: 0;
}

.AppReports > .title {
  margin: 15px 0 20px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E2E2E5;
}

.AppReports > .title h4 {
  margin: 0 30px 0 0;
  color: #5D5966;
}
