.Signup {
  padding: 20px;
}

.Signup .BetaSignupForm,
.Signup .SsoLoginPanel,
.Signup .SignupConfirmForm,
.Signup .username-form {
  margin: 60px auto;
  max-width: 400px;
}

.Signup .username-form p {
  margin-bottom: 30px;
}

.Signup .complete {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
.Signup .complete p {
  font-size: 18px;
}
.Signup .complete p:first-child {
  font-size: 50px;
  color: #FD9527;
}
