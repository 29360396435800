.MachineSettingsPanel {
}

.MachineSettingsPanel .SectionHeader {
  margin-bottom: 7px;
}
.MachineSettingsPanel p.info {
  margin-bottom: 0;
  font-size: 14px;
  color: #908C99;
}

.MachineSettingsPanel form {
}
.MachineSettingsPanel form .form-group {
  margin-bottom: 0;
}

.MachineSettingsPanel p.status {
  margin: 7px 0 -1px;
  font-size: 14px;
  color: #FD9527;
}

.MachineSettingsPanel .controls {
  margin-top: 20px;
}
.MachineSettingsPanel .controls .TextButton {
  margin-left: 15px;
  font-size: 14px;
}
