.OrgBilling {
  padding: 25px 20px 20px;
}

.OrgBilling .ScreenHeader {
  margin-bottom: 33px;
}

.OrgBilling .SectionInfo {
  margin-bottom: 45px;
}
.OrgBilling .SectionInfo:last-child {
  margin-bottom: 0;
}

.OrgBilling .CurrentUsagePanel,
.OrgBilling .BillingHistoryTable {
  max-width: 640px;
}

.OrgBilling .form-container > hr {
  margin: 40px 0;
}
.OrgBilling .SectionInfo {
}
.OrgBilling .SectionInfo:last-child {
  margin-bottom: 0;
}
.OrgBilling .SectionInfo.current-plan .promo {
  margin-bottom: 0;
  margin-left: 7px;
  font-size: 14px;
  color: #908c99;
}
.OrgBilling .SectionInfo.current-plan p.plan-info {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 16px;
  color: #908c99;
}

/**
 * Update Plan
 */
.OrgBilling .SectionInfo.current-plan .update-plan {
  margin-top: 15px;
  max-width: 640px;
}
.OrgBilling .update-plan .account-alert {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 0;
}
.OrgBilling .update-plan .account-alert b {
  font-weight: 500;
}

.OrgBilling hr.history {
  margin: 30px 0;
}

.OrgBilling .billing-form {
  max-width: 640px;
}
.OrgBilling .billing-form > h4 {
  margin: 15px 0;
  color: #5d5966;
}

.OrgBilling .billing-form > .pick-plan,
.OrgBilling .billing-form form.option-fields,
.OrgBilling .billing-form .PricingAddonsPanel,
.OrgBilling .billing-form .future-info {
  margin-bottom: 30px;
  background-color: #efeff1;
  padding: 15px;
  border-radius: 4px;
}

/**
 * Pick Plan
 */
.OrgBilling .billing-form > .pick-plan {
}
.OrgBilling .billing-form .pick-plan .header {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.OrgBilling .billing-form .pick-plan .header .SectionHeader {
  margin-bottom: 0;
}
.OrgBilling .billing-form .pick-plan .header a {
  font-size: 13px;
}

/**
 * Option Fields
 */
.OrgBilling .billing-form form.option-fields {
  display: flex;
}
.OrgBilling .billing-form form.option-fields .form-group {
  margin-bottom: 0;
  flex: 1 1 50%;
}
.OrgBilling .billing-form form.option-fields .form-group.seats {
  margin-right: 15px;
}
.OrgBilling .billing-form form.option-fields .form-group .help-block {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 14px;
}

/**
 * Add-ons
 */
.OrgBilling .billing-form .PricingAddonsPanel {
}

/**
 * Future Info
 */
.OrgBilling .billing-form .future-info {
}
.OrgBilling .billing-form .future-info > p {
  margin-bottom: 3px;
  font-size: 16px;
}
.OrgBilling .billing-form .future-info b {
  font-weight: 500;
}
.OrgBilling .billing-form .future-info ul {
  margin-top: 0;
  margin-bottom: 0;
}
.OrgBilling .billing-form .future-info ul li {
  font-size: 15px;
  line-height: 1.8;
  color: #5d5966;
}
.OrgBilling .billing-form .future-info ul li i.fa {
  margin-top: 5px;
  color: #aeacb5;
  font-size: 13px;
}
.OrgBilling .billing-form .future-info > span {
  line-height: 1.6;
  font-size: 15px;
}
.OrgBilling .billing-form .future-info > hr {
  margin: 15px 0;
}

/**
 * Credit Card Form
 */
.OrgBilling hr.credit-card {
  margin: 35px 0 30px;
  border-color: #c7c6cc;
}
.OrgBilling .billing-form .StripeCardForm {
}

.OrgBilling p.copy {
  margin-bottom: 30px;
}
