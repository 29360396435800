.ApiResourceList {
}

.ApiResourceList > .api {
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
              0 1px 2px rgba(0, 0, 0, 0.04),
              0 2px 4px rgba(0, 0, 0, 0.03);
}
.ApiResourceList > .api:last-child {
  margin-bottom: 0;
}

.ApiResourceList > .api > div.header {
  padding: 15px 15px 0;
}
.ApiResourceList > .api > div.header h4 {
  margin: 0;
  font-size: 20px;
}

.ApiResourceList > .api ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ApiResourceList > .api ul.endpoint .ResourceItem {
  padding-top: 10px;
}
.ApiResourceList > .api .ResourceItem {
  border-bottom: 1px solid #EFEFF1;
}
.ApiResourceList > .api .ResourceItem:last-child {
  border-bottom: 0 none;
}

.ApiResourceList > .api > div.separator {
  padding: 10px 0;
  position: relative;
  text-align: center;
}
.ApiResourceList > .api > div.separator hr {
  margin: 0;
  border-color: #E2E2E5;
}
.ApiResourceList > .api > div.separator .SectionHeader {
  position: absolute;
  left: 50%;
  margin: 0 0 0 -36px;
  top: 4px;
  background-color: white;
  padding: 0 7px;
  display: inline;
}

.ApiResourceList .empty {
  padding: 60px 15px;
  text-align: center;
  color: #908C99;
}
