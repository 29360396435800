.SelectKeyMetricsModal {
}

.SelectKeyMetricsModal .modal-body > p.copy {
}

/**
 * Form
 */
.SelectKeyMetricsModal .form {
}
.SelectKeyMetricsModal .form .form-group {
  margin-bottom: 5px;
  position: relative;
}
.SelectKeyMetricsModal .form input {
  border-color: #E2E2E5;
  padding-left: 28px;
  padding-bottom: 5px;
  padding-right: 40px;
  cursor: pointer;
}
.SelectKeyMetricsModal .form input:disabled {
  cursor: not-allowed;
}
.SelectKeyMetricsModal .form input:focus {
  cursor: auto;
}
.SelectKeyMetricsModal .form .form-group label {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -9px;
  font-size: 13px;
  opacity: 0.7;
  color: #908C99;
}
.SelectKeyMetricsModal .form .form-group .TextButton {
  position: absolute;
  top: 0;
  right: 0;
  color: #BBB;
  width: 30px;
  height: 34px;
  text-align: center;
}
.SelectKeyMetricsModal .form .form-group .TextButton:hover {
  color: #888;
}
.SelectKeyMetricsModal .form .form-group .TextButton i.fa {
  display: block;
  font-size: 16px;
  line-height: 34px;
}

/**
 * Results
 */
.SelectKeyMetricsModal .modal-body > .empty {
  border-radius: 4px;
  border: 2px dashed #DADEE2;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #908C99;
  font-size: 14px;
}

/**
 * Results
 */
.SelectKeyMetricsModal .modal-body > .list {
  position: relative;
  border-radius: 4px;
  border: 1px solid #DADEE2;
  max-height: 250px;
  overflow: scroll;
}
.SelectKeyMetricsModal .modal-body > .list .item {
  display: flex;
  align-items: center;
  padding: 7px 8px;
  cursor: pointer;
}
.SelectKeyMetricsModal .modal-body > .list .item:nth-child(even) {
  background-color: #F9F9FA;
}
.SelectKeyMetricsModal .modal-body > .list .item:hover {
  background-color: #EFEFF1;
}
.SelectKeyMetricsModal .modal-body > .list .item > i.fa {
  margin-right: 8px;
  color: #908C99;
}
.SelectKeyMetricsModal .modal-body > .list .item > i.fa-star,
.SelectKeyMetricsModal .modal-body > .list .item:hover > i.fa {
  color: #FD9527;
}
.SelectKeyMetricsModal .modal-body > .list .item p {
  margin: 2px 0 0;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #5D5966;
}
.SelectKeyMetricsModal .modal-body > .list .item p span:first-child {
  color: #908C99;
}
.SelectKeyMetricsModal .modal-body > .list .item p i.fa {
  margin: 0 6px 0 2px;
  color: #AEACB5;
}
.SelectKeyMetricsModal .modal-body > .list .empty {
  padding: 7px 8px;
  font-size: 14px;
  color: #908C99;
}

/**
 * Selected Items
 */
.SelectKeyMetricsModal .modal-body > .selected {
  margin-top: 20px;
}
.SelectKeyMetricsModal .modal-body > .selected .SectionHeader {
  margin-bottom: 7px;
  color: #AEACB5;
}
.SelectKeyMetricsModal .modal-body > .selected.limit.count-4 .SectionHeader {
  color: #D9534F;
}
.SelectKeyMetricsModal .modal-body > .selected .list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-top: -7px;
}
.SelectKeyMetricsModal .modal-body > .selected .item {
  margin-left: 5px;
  margin-top: 7px;
  background-color: #EFEFF1;
  border-radius: 15px;
  font-size: 13px;
  color: #908C99;
  display: flex;
  align-items: center;
}
.SelectKeyMetricsModal .modal-body > .selected .item span {
  padding: 6px 0 6px 15px;
  display: block;
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.SelectKeyMetricsModal .modal-body > .selected .item .TextButton {
  padding: 6px 15px 6px 7px;
  color: #908C99;
}
.SelectKeyMetricsModal .modal-body > .selected .item .TextButton:hover {
  color: #6A5CA2;
}

.SelectKeyMetricsModal .modal-footer button:last-child {
  margin-left: 15px;
}
