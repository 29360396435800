.RequestLogEntry {
  border-left: 1px solid #E2E2E5;
  border-right: 1px solid #E2E2E5;
}

.RequestLogEntry.expanded {
  background-color: white;
  border-bottom: 1px solid #E2E2E5;
}

/**
 * Summary
 */
.RequestLogEntry .summary {
  border-bottom: 1px solid #E2E2E5;
  font-family: "Source Code Pro", monospace;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
.RequestLogEntry .summary .contents {
  display: flex;
  align-items: center;
  margin-left: 23px;
  padding: 8px 0;
}
.RequestLogEntry.expanded .summary .contents {
}
.RequestLogEntry .summary .CaretIcon {
  margin-top: 1px;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -2px;
}
.RequestLogEntry .summary .CaretIcon.right {
  margin-top: -5px;
  border-color: transparent #C7C6CC;
}
.RequestLogEntry .summary .CaretIcon.down {
  left: 7px;
  border-color: #C7C6CC transparent;
}
.RequestLogEntry .summary .level {
  margin-right: 10px;
  padding: 3px 0;
  font-family: "Exo 2", sans-serif;
  letter-spacing: 1px;
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 2px;
  flex: 0 0 40px;
  display: inline-block;
  text-align: center;
  color: #C7C6CC;
  background-color: #EFEFF1;
}
.RequestLogEntry .summary.error .level {
  color: rgba(217, 83, 79, 0.5);
  background-color: rgba(217, 83, 79, 0.2);
}
.RequestLogEntry .summary.warn .level {
  color: rgba(253, 149, 39, 0.6);
  background-color: rgba(253, 149, 39, 0.2);
}
.RequestLogEntry .summary .time {
  font-size: 12px;
  flex: 0 0 auto;
}

/**
 * Summary Fields
 */
.RequestLogEntry .summary .duration {
  text-align: right;
  flex: 0 0 90px;
  font-size: 12px;
  color: #5D5966;
}
.RequestLogEntry .summary .memory {
  margin-right: 30px;
  text-align: right;
  flex: 0 0 70px;
  font-size: 12px;
  color: #5D5966;
}
.RequestLogEntry .summary .memory.high {
  color: #D9534F;
}
.RequestLogEntry .summary .duration.access-logs {
  margin-right: 5px;
}
.RequestLogEntry .summary .method {
  margin-right: 5px;
  text-align: right;
  flex: 0 0 65px;
  font-size: 12px;
  text-transform: uppercase;
  color: #5D5966;
}
.RequestLogEntry .summary .status {
  margin-right: 5px;
  text-align: right;
  flex: 0 0 35px;
  font-size: 12px;
  color: #5D5966;
}
.RequestLogEntry .summary .ip {
  margin-right: 5px;
  text-align: right;
  flex: 0 0 115px;
  font-size: 12px;
  color: #5D5966;
}
.RequestLogEntry .summary .path {
  margin-left: 20px;
  margin-right: 20px;
  flex: 0 0 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #5D5966;
}
.RequestLogEntry .summary .text {
  font-size: 14px;
  white-space: nowrap;
  color: #5D5966;
}
.RequestLogEntry .summary.error .text,
.RequestLogEntry .summary.error .status {
  color: #D9534F;
}
.RequestLogEntry .summary .spacer {
  flex: 0 0 30px;
}

/**
 * Details
 */
.RequestLogEntry .details {
  padding: 5px 10px 10px 10px;
  animation: show 0.4s ease;
}
.RequestLogEntry.single .details {
  animation: none;
}

/**
 * Controls
 */
.RequestLogEntry .details .controls {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.RequestLogEntry .details .controls .SectionHeader {
  margin: 0;
  line-height: 22px;
  color: #C7C6CC;
}
.RequestLogEntry .details .controls a {
  font-size: 12px;
}
.RequestLogEntry .details .controls a i.fa {
  font-size: 11px;
  opacity: 0.95;
}
.RequestLogEntry .details .controls a i.fa {
  margin-right: 5px;
}
.RequestLogEntry .details .controls a.xray i.fa {
  margin-left: 5px;
}
.RequestLogEntry .details .controls .separator {
  padding: 0 12px 0 9px;
  font-size: 12px;
  color: #E2E2E5;
}

/**
 * Access Log Fields
 */
.RequestLogEntry .details .AccessLogFieldsPanel {
  margin-bottom: 10px;
}

/**
 * Logs
 */
.RequestLogEntry .details .logs {
  background-color: #5D5966;
  padding: 7px 14px;
  border-radius: 3px;
}
.RequestLogEntry .logs .divider {
  margin-left: 167px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.RequestLogEntry .logs .divider:last-child {
  display: none;
}
