.AppAddStageModal {
}
.AppAddStageModal .SectionHeader {
  margin-bottom: 15px;
}

.AppAddStageModal form .validation {
  padding-top: 7px;
  font-size: 14px;
  color: #FC6020;
}

.AppAddStageModal form hr {
  margin: 20px 0 20px 20px;
}

.AppAddStageModal form .name.form-group {
  margin-bottom: 20px;
}
.AppAddStageModal form .form-group:last-child {
  margin-bottom: 7px;
}

.AppAddStageModal form .form-group .checkbox {
  font-size: 14px;
}
.AppAddStageModal form .form-group:first-child .help-block {
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 7px;
  font-size: 14px;
}
.AppAddStageModal form .form-group.branch-option .help-block,
.AppAddStageModal form .form-group.inherit-option .help-block {
  font-size: 14px;
  margin-left: 21px;
  margin-bottom: 0;
  color: #908C99;
}

.AppAddStageModal form .inherit-copy {
  margin-top: 15px;
  margin-bottom: 7px;
  font-size: 14px;
  color: #5D5966;
}
.AppAddStageModal form .inherit-copy p {
  margin-bottom: 6px;
}
.AppAddStageModal form .inherit-copy ul {
  margin-bottom: 0;
}
.AppAddStageModal form .inherit-copy ul li {
  margin-top: 4px;
  margin-bottom: 4px;
}
.AppAddStageModal form .inherit-copy ul li:last-child {
  margin-bottom: 0;
}
.AppAddStageModal form .inherit-copy ul li i {
  top: 3px;
  opacity: 0.8;
}

.AppAddStageModal form .branch-select,
.AppAddStageModal form .inherit-select {
  margin-left: 21px;
  animation: show 0.4s ease;
}
.AppAddStageModal form .GitBranchSelect {
  margin-left: 21px;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.AppAddStageModal form .btnLoading {
}

.AppAddStageModal .modal-footer button:last-child {
  margin-left: 15px;
}
