.AppPrModal {
}

.AppPrModal form .form-group:last-child {
  margin-bottom: 0;
}
.AppPrModal form .form-group .checkbox {
  font-size: 14px;
  margin-bottom: 0;
}
.AppPrModal form .form-group .help-block {
  margin-top: 4px;
  margin-left: 21px;
  color: #908C99;
  margin-bottom: 0;
  font-size: 14px;
}

.AppPrModal .modal-footer .TextButton {
  margin-right: 15px;
  padding: 10px;
  font-size: 14px;
}
