.ServiceRemoveStageTable {
  overflow-x: auto;
}

.ServiceRemoveStageTable > .content {
  display: flex;
}

/**
 * Stages
 */
.ServiceRemoveStageTable .stages {
  flex-basis: auto;
  flex-grow: 1;
  min-width: 200px;
  max-width: 400px;
}
.ServiceRemoveStageTable .stages .stage {
  height: 78px;
  display: flex;
  border-style: solid;
  border-color: #DDD;
  border-width: 1px 0 0 1px;
}
.ServiceRemoveStageTable .stages .stage:first-child {
  border-radius: 3px 0 0 0;
}
.ServiceRemoveStageTable .stages .stage:last-child {
  border-bottom: 1px solid #DDD;
  height: 79px;
  border-radius: 0 0 0 3px;
}
.ServiceRemoveStageTable .stages .stage .PipelineTableStageCell {
  padding: 10px 15px;
  overflow: hidden;
  width: 100%;
  background-color: transparent;
}
.ServiceRemoveStageTable .stages .stage .PipelineTableStageCell:after {
  background: linear-gradient(to right, rgba(249, 249, 250, 0), rgba(249, 249, 250, 1) 85%);
}

/**
 * Errors
 */
.ServiceRemoveStageTable .errors {
  flex: 1 0 auto;
  width: 400px;
}
.ServiceRemoveStageTable .errors .error {
  border-style: solid;
  border-color: #DDD;
  border-width: 1px 1px 0 1px;
  display: flex;
  align-items: center;
  height: 78px;
  padding: 0 15px;
  font-size: 14px;
}
.ServiceRemoveStageTable .errors .error > div {
  width: 100%;
}
.ServiceRemoveStageTable .errors .error p.copy {
  margin-bottom: 3px;
  color: #BE2F2B;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.ServiceRemoveStageTable .errors .error a i {
  font-size: 13px;
}
.ServiceRemoveStageTable .errors .error:first-child {
  border-radius: 0 3px 0 0;
}
.ServiceRemoveStageTable .errors .error:last-child {
  border-bottom: 1px solid #DDD;
  border-radius: 0 0 3px 0;
  height: 79px;
}

