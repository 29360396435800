.ResetPassword {
  padding: 20px;
}

.ResetPassword .ResetPwForm,
.ResetPassword .ResetPwConfirmForm {
  margin: 60px auto;
  max-width: 400px;
}

.ResetPassword .confirm-success {
  margin: 0 auto;
  text-align: center;
  padding: 60px 0;
  max-width: 400px;
}
.ResetPassword .confirm-success .glyphicon {
  margin-bottom: 30px;
  font-size: 60px;
  color: #FD9527;
}
.ResetPassword .confirm-success p {
}
.ResetPassword .confirm-success p a {
  font-weight: 500;
}
