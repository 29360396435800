.StageSettings {
  padding: 20px;
}

.StageSettings .ScreenHeader {
  margin-bottom: 33px;
}

.StageSettings .StageNameForm .col-body,
.StageSettings .StageRegionPanel .col-body {
  max-width: 500px;
}

.StageSettings .StageIamPanel .col-body,
.StageSettings .EditBranchPanel .col-body,
.StageSettings .AppStageNamePanel .col-body,
.StageSettings .PostDeployTestPanel .col-body {
  max-width: 600px;
}

.StageSettings .SectionGroupPanel {
  margin-top: 45px;
}
.StageSettings .settings-group hr,
.StageSettings .SectionGroupPanel hr {
  margin: 45px 0;
}
