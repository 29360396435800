.PricingAddonsPanel {
}
.PricingAddonsPanel .header {
  margin-bottom: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.PricingAddonsPanel .header .SectionHeader {
  margin-bottom: 0;
}
.PricingAddonsPanel .header a {
  font-size: 13px;
}

.PricingAddonsPanel ul {
  margin: -15px -15px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.PricingAddonsPanel ul li {
  box-sizing: border-box;
  padding-top: 15px;
  padding-right: 15px;
  cursor: pointer;
  flex: 1 1 50%;
}
.PricingAddonsPanel ul li:hover,
.PricingAddonsPanel ul li.selected {
}

.PricingAddonsPanel ul li > div {
  padding: 11px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: white;
  display: flex;
  justify-content: space-between;
}
.PricingAddonsPanel ul li:hover > div,
.PricingAddonsPanel ul li.selected > div {
  border-color: #fd9527;
}

.PricingAddonsPanel ul li > div > div:last-child {
  margin-left: 12px;
  color: #e2e2e5;
}
.PricingAddonsPanel ul li.selected > div > div:last-child {
  color: #fd9527;
}

.PricingAddonsPanel ul li > div > div:first-child p:first-child {
  margin-bottom: 5px;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
}
.PricingAddonsPanel ul li > div > div:first-child p:last-child {
  margin-bottom: 0;
  font-size: 13px;
  color: #908c99;
}
