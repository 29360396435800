.Services {
  padding: 20px;
}

.Services .ScreenHeader {
  margin-bottom: 33px;
}

.Services > hr {
  margin: 45px 0;
}

.Services .ItemDeletePanel .col-body,
.Services .ServicePathForm .col-body,
.Services .ServiceNameForm .col-body,
.Services .MachineSettingsPanel .col-body,
.Services .BuildImageSettingsPanel .col-body {
  max-width: 500px;
}

.Services .SectionGroupPanel {
  margin-top: 45px;
}
