.SectionInfo {
}

.SectionInfo .col-info {
  margin-bottom: 15px;
}
.SectionInfo .col-info > .SectionHeader {
  margin-bottom: 0;
  padding-top: 2px;
  font-size: 14px;
}
.SectionInfo .col-info > .desc {
  margin-top: 10px;
  margin-bottom: 0;
  color: #908C99;
  font-size: 14px;
}

.SectionInfo .col-body > a.info-link,
.SectionInfo .col-body > span.info-text {
  font-size: 18px;
}

@media (min-width: 992px) {
  .SectionInfo > .row > .col-info {
    margin-bottom: 0;
  }
}
