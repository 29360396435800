.PermissionErrorPanel.alert {
  padding: 0;
}
.PermissionErrorPanel .LoaderButton {
  text-align: center;
  width: 100%;
  display: block;
  padding: 8px 10px 7px;
}
.PermissionErrorPanel i.fa {
  margin-right: 6px;
  font-size: 13px;
}
