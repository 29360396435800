.DeleteUserPanel {
}

.DeleteUserPanel .SectionInfo .col-info .SectionHeader {
  color: #d9534f;
}

.DeleteUserPanel .SectionInfo .col-info p.desc {
  color: #d9534f;
}

.DeleteUserPanel .DeleteUserForm .checks {
  font-size: 14px;
  color: #d9534f;
}
.DeleteUserPanel .DeleteUserForm .checks b {
  font-weight: 500;
}
.DeleteUserPanel .DeleteUserForm .helperCopy {
  font-size: 14px;
  color: #d9534f;
}
.DeleteUserPanel .DeleteUserForm .form-group {
  margin-bottom: 0;
}
.DeleteUserPanel .DeleteUserForm .controls {
  margin-top: 20px;
}
.DeleteUserPanel .DeleteUserForm .controls .TextButton {
  margin-left: 20px;
  font-size: 14px;
}
