.AppActivityPanel {
  display: flex;
  align-items: flex-start;
}

.AppActivityPanel > .actor {
  margin-top: 1px;
  margin-right: 15px;
  flex-shrink: 0;
}

.AppActivityPanel > .panel {
  flex: 1 0 auto;
  width: 400px;
}
.AppActivityPanel.in_progress > .panel {
  border-color: #B1A9C5;
}

.AppActivityPanel .panel-heading {
  padding: 11px 12px 10px;
}

.AppActivityPanel.in_progress {
  border-color: #B1A9C5;
}

/** Heading **/
.AppActivityPanel.in_progress .panel-heading {
  background-color: #D0CBDC;
  border-color: #B1A9C5;
}
.AppActivityPanel .panel-heading a {
  display: flex;
  align-items: center;
  color: #5D5966;
}
.AppActivityPanel .panel-heading a:active {
  color: #5D5966;
}
.AppActivityPanel.in_progress .panel-heading a {
  color: #53437D;
}
.AppActivityPanel.in_progress .panel-heading a:active {
  color: #3C2969;
}
.AppActivityPanel .panel-heading a b {
  font-weight: 500;
}

.AppActivityPanel .panel-body {
  padding: 15px 15px 13px;
}

.AppActivityPanel .panel-body .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AppActivityPanel .panel-body .content .content-body {
  margin-top: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  /** Set the color to handle ellipsis on overflow to match commit messsage **/
  color: #5D5966;
  font-size: 14px;
}

/** Col 1 **/
.AppActivityPanel .panel-body .content .col1 {
  margin-right: 15px;
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}

/** Status Icon **/
.AppActivityPanel .panel-body a.icon {
  margin-top: 0px;
  margin-right: 7px;
}
.AppActivityPanel .panel-body a.icon .fa {
  opacity: 0.8;
  font-size: 28px;
  color: #908C99;
}
.AppActivityPanel.completed .panel-body a.icon .fa,
.AppActivityPanel.completed .panel-body a.icon:hover .fa,
.AppActivityPanel.in_progress .panel-body a.icon .fa,
.AppActivityPanel.in_progress .panel-body a.icon:hover .fa {
  color: #74649A;
}
.AppActivityPanel.failed .panel-body a.icon .fa,
.AppActivityPanel.failed .panel-body a.icon:hover .fa {
  color: #A35502;
}

/** Build Id **/
.AppActivityPanel .panel-body a.build-link,
.AppActivityPanel .panel-body a.build-link:hover {
  color: #5D5966;
  font-size: 18px;
}
.AppActivityPanel.in_progress .panel-body a.build-link,
.AppActivityPanel.in_progress .panel-body a.build-link:hover {
  color: #74649A;
}
.AppActivityPanel.failed .panel-body a.build-link,
.AppActivityPanel.failed .panel-body a.build-link:hover {
  color: #A35502;
}

/** Commit Message **/
.AppActivityPanel .panel-body .commit-message {
  margin-left: 7px;
  font-size: 14px;
  white-space: nowrap;
}
.AppActivityPanel .panel-body .commit-message a,
.AppActivityPanel .panel-body .commit-message span,
.AppActivityPanel .panel-body .commit-message a:hover {
  color: #2E2D33;
}

/** Info **/
.AppActivityPanel .panel-body .col1 .info {
  margin-top: 7px;
  font-size: 14px;
  color: #929C9A;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.AppActivityPanel .panel-body .col1 .info a.info-link,
.AppActivityPanel .panel-body .col1 .info a.info-link:hover {
  color: #929C9A;
}
.AppActivityPanel .panel-body .col1 .info .fa {
  opacity: 0.9;
  margin-right: 4px;
}
.AppActivityPanel .panel-body .col1 .info a.commit {
  font-family: "Source Code Pro", monospace;
}
.AppActivityPanel .panel-body .col1 .info span {
  margin: 0 8px;
}

/** Col2 **/
.AppActivityPanel .panel-body .content .col2 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.AppActivityPanel .panel-body .content .col2 .info {
  text-align: right;
}
.AppActivityPanel .panel-body .content .col2 .info p {
  margin-bottom: 0;
  font-size: 14px;
}
.AppActivityPanel .panel-body .content .col2 .info p.status {
  margin-bottom: 6px;
  color: #C7C6CC;
}
.AppActivityPanel .panel-body .content .col2 .info p.date {
  color: #929C9A;
  line-height: 1.1;
}
.AppActivityPanel .panel-body .content .col2 .LoaderButton {
  margin-left: 15px;
}
.AppActivityPanel .panel-body .content .col2 .LoaderButton.active {
  background-color: #E0DDE7;
  color: #53437D;
  cursor: default;
  border-color: #E0DDE7;
}
.AppActivityPanel .panel-body .content .col2 .LoaderButton:not(.loading) {
  width: 80px;
}
