.AppTabNav {
  padding: 0 20px 0 0;
  font-size: 13px;
  background-color: #EFEFF1;
  background-color: #EFEFF1;
  border-bottom: 1px solid #E2E2E5;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
}
.AppTabNav > li {
  padding: 0 10px;
}
.AppTabNav > li:first-child {
  padding-left: 15px;
}
.AppTabNav > li+li {
  margin-left: 0;
}
.AppTabNav > li > a {
  padding: 13px 5px 10px;
  border-radius: 0;
  border-bottom: 3px solid transparent;
  color: #908C99;
}
.AppTabNav > li > a:hover,
.AppTabNav > li.open > a:hover {
  color: #2E2D33;
  background-color: transparent;
  border-color: #E2E2E5;
}
.AppTabNav > li.active > a,
.AppTabNav > li.open.active > a {
  color: #2E2D33;
  background-color: #EFEFF1;
  border-color: #FD9527;
}
.AppTabNav > li.active > a:focus,
.AppTabNav > li.active > a:hover {
  color: #2E2D33;
  background-color: transparent;
}
