.BuildHelpPanel {
  margin-top: 12px;
  position: relative;
  background: #E2E2E5;
  border-radius: 3px;
  display: flex;
  padding: 18px;
}
.BuildHelpPanel:after, .BuildHelpPanel:before {
	bottom: 100%;
	left: 36px;
  left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.BuildHelpPanel:after {
  border-color: rgba(249, 249, 250, 0);
  border-bottom-color: #E2E2E5;
  border-width: 10px;
  margin-left: -10px;
}
.BuildHelpPanel:before {
  border-color: rgba(253, 149, 39, 0);
  border-bottom-color: #E2E2E5;
  border-width: 13px;
  margin-left: -13px;
}

.BuildHelpPanel .content {
}
.BuildHelpPanel .content p.title {
  margin-top: 2px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #5D5966;
}
.BuildHelpPanel .content .body {
  margin-bottom: 25px;
  font-size: 14px;
}
.BuildHelpPanel .content .body ol,
.BuildHelpPanel .content .body ul {
}
.BuildHelpPanel .content .body ol li:not(:last-child),
.BuildHelpPanel .content .body ul li:not(:last-child) {
  margin-bottom: 3px;
}
.BuildHelpPanel .content .body b {
  font-weight: 500;
}
.BuildHelpPanel .content .body code {
  background-color: transparent;
}
.BuildHelpPanel .content div.footer {
}

.BuildHelpPanel .content p.doc-link {
  margin-bottom: 17px;
  font-size: 15px;
}
.BuildHelpPanel .content p.doc-link > i.fa {
  margin-left: 1px;
  margin-right: 10px;
  color: #908C99;
  font-size: 26px;
  vertical-align: -4px;
}
.BuildHelpPanel .content p.doc-link a i.fa {
  margin-left: 2px;
}
