.TwoFactorLoginForm {
}

.TwoFactorLoginForm .help-block {
  margin-top: 7px;
  font-size: 14px;
}

.TwoFactorLoginForm .controls {
  margin-top: 30px;
  text-align: left;
}
.TwoFactorLoginForm .controls .TextButton {
  margin-left: 15px;
  font-size: 14px;
}
