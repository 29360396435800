.LoginForm {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.LoginForm form {
  flex: 1 1 auto;
  max-width: 340px;
}
.LoginForm form .help-block {
  margin-top: 7px;
  font-size: 14px;
}
.LoginForm form .controls {
  margin-top: 30px;
}
.LoginForm form .controls .TextButton {
  margin-left: 15px;
  font-size: 14px;
}
.LoginForm form .controls a {
  margin-left: 15px;
  font-size: 14px;
}

.LoginForm .show-sso {
  margin-left: 45px;
  margin-top: 30px;
  padding-top: 11px;
  padding-left: 45px;
  border-left: 1px solid #DBDADE;
  height: 116px;
  text-align: center;
}

.LoginForm .show-sso .SectionHeader {
  color: #AEACB5;
}
.LoginForm .show-sso .LoaderButton {
  padding: 14px 18px;
  width: 200px;
  font-size: 15px;
  background-color: #D5D2DE;
}
.LoginForm .show-sso .LoaderButton:hover {
  background-color: #C0BBCF;
}

@media all and (max-width: 600px) {
  .LoginForm {
    display: block;
  }
  .LoginForm form {
    max-width: none;
  }
  .LoginForm .show-sso {
    padding: 0;
    margin: 0;
    border-left: none;

    margin-top: 45px;
    padding-top: 45px;
    border-top: 1px solid #DBDADE;
  }
}
