.EnvVarsPanel {
  margin-bottom: 15px;
}

.EnvVarsPanel > .btnShow {
  margin-top: 5px;
}

.EnvVarsPanel .helperCopy {
  padding-top: 5px;
  font-size: 14px;
  color: #5d5966;
}

.EnvVarsPanel .vars {
  padding-top: 5px;
}

.EnvVarsPanel .vars .EnvVarPanel {
  margin-bottom: 15px;
}

.EnvVarsPanel .newVar form {
  display: flex;
  align-items: center;
}
.EnvVarsPanel .newVar .form-group {
  margin-left: 10px;
  margin-bottom: 0;
}
.EnvVarsPanel .newVar .form-group:first-child {
  flex: 1;
  margin-left: 0;
}
.EnvVarsPanel .newVar .form-group:nth-child(2) {
  flex: 0 0 280px;
}
.EnvVarsPanel .newVar .form-group textarea {
  resize: none;
  white-space: nowrap;
}
.EnvVarsPanel .newVar .controls {
  flex: 0 0 170px;
}
.EnvVarsPanel .newVar button {
  margin-left: 15px;
}
.EnvVarsPanel .newVar .validation {
  padding-top: 7px;
  font-size: 14px;
  color: #be2f2b;
}

.EnvVarsPanel .btnHide {
  margin-top: 20px;
  display: inline-block;
  font-size: 14px;
}
