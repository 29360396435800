.ConfirmPromoteChangesPanel {
}
.ConfirmPromoteChangesPanel .SectionHeader:first-child {
  margin-bottom: 20px;
}
.ConfirmPromoteChangesPanel .service {
  margin-bottom: 20px;
}
.ConfirmPromoteChangesPanel .service:last-child {
  margin-bottom: 10px;
}
.ConfirmPromoteChangesPanel .service h5 {
  font-size: 16px;
  color: #908C99;
}
.ConfirmPromoteChangesPanel .service > .TextButton {
  margin-top: 10px;
  margin-left: 10px;
  display: inline-block;
  font-size: 14px;
}
.ConfirmPromoteChangesPanel .service ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.ConfirmPromoteChangesPanel .service ul li {
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.ConfirmPromoteChangesPanel .service ul li > div:first-child {
  flex: 0 0 72px;
}
.ConfirmPromoteChangesPanel .service ul li > div:first-child span {
  padding: 5px 8px;
  font-family: "Exo 2", sans-serif;
  letter-spacing: 1px;
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 2px;
  width: 60px;
  display: inline-block;
  text-align: center;
  color: #C7C6CC;
  background-color: #EFEFF1;
}
.ConfirmPromoteChangesPanel .service ul li.remove > div:first-child span {
  color: rgba(217, 83, 79, 0.5);
  background-color: rgba(217, 83, 79, 0.2);
}
.ConfirmPromoteChangesPanel .service ul li.add > div:first-child span {
  color: #9BB0AF;
  background-color: #DDEBDF;
}
.ConfirmPromoteChangesPanel .service ul li.add > div:last-child span:first-child {
}
.ConfirmPromoteChangesPanel .service ul li > div:last-child {
  color: #908C99;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ConfirmPromoteChangesPanel .service ul li > div:last-child span:first-child {
  white-space: nowrap;
  font-size: 16px;
  color: #2E2D33;
}
.ConfirmPromoteChangesPanel .service ul li > div:last-child span:nth-child(2) {
  margin-left: 5px;
  white-space: nowrap;
}
.ConfirmPromoteChangesPanel .service ul li > div:last-child .TextButton:last-child {
  margin-left: 7px;
}
.ConfirmPromoteChangesPanel .service ul li > div:last-child .TextButton:last-child i {
  margin-right: 4px;
  font-size: 13px;
}

.ConfirmPromoteChangesPanel > .controls .TextButton {
  display: inline-block;
  font-size: 14px;
}
.ConfirmPromoteChangesPanel > .controls .separator {
  color: #C7C6CC;
  margin: 0 7px;
}
.ConfirmPromoteChangesPanel > .controls .btn-report {
  display: inline-block;
  text-align: left;
  padding: 0;
  border: 0;
  font-size: 14px;
}
.ConfirmPromoteChangesPanel > .controls .report-status {
  font-size: 14px;
  color: #FD9527;
}
