.IssuesSplashPanel {
  display: flex;
  align-items: center;
}

/**
 * Col1
 */
.IssuesSplashPanel .col1 {
  flex: 1 1 50%;
}
.IssuesSplashPanel .col1 h1 {
  margin-top: 0;
  margin-bottom: 3px;
}
.IssuesSplashPanel .col1 p.desc {
  margin-bottom: 20px;
  color: #908c99;
  font-size: 18px;
}
.IssuesSplashPanel .col1 ul {
  margin-bottom: 0;
}
.IssuesSplashPanel .col1 ul li {
  color: #5d5966;
  font-size: 15px;
  line-height: 1.55;
}
.IssuesSplashPanel .col1 ul li + li {
  margin-top: 10px;
}
.IssuesSplashPanel .col1 ul li i.fa {
  top: 3px;
  opacity: 0.85;
  color: #53437d;
}
.IssuesSplashPanel .col1 ul li code {
  padding: 0;
  margin: 0;
  background-color: transparent;
}

/**
 * Col2
 */
.IssuesSplashPanel .col2 {
  margin-left: 25px;
  padding: 25px 0 25px 25px;
  border-left: 1px solid #dbdade;
  flex: 1 1 45%;
  text-align: center;
}
.IssuesSplashPanel .col2 .LoaderButton.enable {
  color: white;
  width: 250px;
  background-color: #ffaa50;
}
.IssuesSplashPanel .col2 .LoaderButton.enable:not(:disabled):hover {
  background-color: #fd9527;
}
.IssuesSplashPanel .col2 p.waitlist-copy {
  margin-bottom: 0;
  margin-top: 15px;
  font-size: 14px;
  color: #aeacb5;
  line-height: 1.6;
}
.IssuesSplashPanel .col2 hr {
  margin: 22px 0 25px;
}
.IssuesSplashPanel .col2 .LoaderButton.tweet {
  color: white;
  background-color: #1da1f2;
  font-size: 13px;
}
.IssuesSplashPanel .col2 .LoaderButton.tweet:hover {
  background-color: #0c7abf;
}
.IssuesSplashPanel .col2 .LoaderButton.tweet i.fa {
  margin-right: 4px;
  font-size: 15px;
  vertical-align: -1px;
}
.IssuesSplashPanel .col2 .tweet-copy {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 13px;
  color: #aeacb5;
}
