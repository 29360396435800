.ServiceNameForm {
}

.ServiceNameForm .SectionInfo .form-container {
  display: flex;
}
.ServiceNameForm .SectionInfo .form-container .ServiceIcon {
  margin-top: 3px;
  margin-right: 15px;
}
.ServiceNameForm .SectionInfo .form-container form {
  flex-grow: 1;
}

.ServiceNameForm form .form-group {
  margin-bottom: 0;
}

.ServiceNameForm form.display input:not(:focus) {
  background-color: transparent;
}

.ServiceNameForm form.display .help-block {
  margin-bottom: 0;
}

.ServiceNameForm form .help-block {
  margin-top: 7px;
  font-size: 14px;
}
.ServiceNameForm p.status {
  margin: 7px 0 0;
  font-size: 14px;
  color: #FD9527;
}
.ServiceNameForm .controls {
  margin-top: 20px;
}
.ServiceNameForm .controls .TextButton {
  margin-left: 15px;
  font-size: 14px;
}
