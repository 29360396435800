.Apps {
  padding: 5px 20px 20px;
}

.Apps > .SeedAlert.iam-alert {
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}
.Apps > .SeedAlert.iam-alert a {
  color: #BE2F2B;
}
.Apps > .SeedAlert.iam-alert a:hover {
  color: #8E1514;
}

.Apps > .cols {
  display: flex;
  align-items: flex-start;
}

/**
 * Two column layout
 */
.Apps > .cols .col-1 {
  margin-top: 15px;
  margin-right: 15px;
  flex: 1 1 66%;
  min-width: 0;
}
.Apps > .cols .col-2 {
  margin-top: 15px;
  margin-left: 0;
  flex: 1 1 34%;
  min-width: 280px;
  max-width: 400px;
}
.Apps > .cell,
.Apps > .cols .cell {
  padding: 10px;
  background-color: #EFEFF1;
  border-radius: 3px;
}

.Apps .col-1 .cell.resources {
}
.Apps .col-1 .cell.services {
}
.Apps .col-1 .cell.stages {
  margin-top: 15px;
  padding-bottom: 0;
}
.Apps .col-2 .cell.search {
  min-width: 280px;
}
.Apps .col-2 .cell.issues {
  margin-top: 15px;
}
.Apps .col-2 .cell.report {
  margin-top: 15px;
}
.Apps .col-2 .cell.activity {
  margin-top: 15px;
}

/**
 * One main + second row with two columns
 */
@media all and (max-width: 1050px) {
  .Apps > .cols {
    display: block;
  }
  .Apps > .cols .col-1 {
    margin-right: 0;
  }
  .Apps > .cols .col-2 {
    margin-left: 0;
    display: flex;
    max-width: none;
  }
  .Apps > .cols .col-2 .col-3 {
    flex: 1 1 50%;
    min-width: 0;
  }
  .Apps > .cols .col-2 .col-4 {
    margin-left: 15px;
    flex: 1 1 auto;
    min-width: 0;
  }
  .Apps .col-2 .cell.activity {
    margin-top: 0;
  }
}

/**
 * One column mobile layout
 */
@media all and (max-width: 700px) {
  .Apps > .cols .col-2 {
    display: block;
  }
  .Apps > .cols .col-2 .col-3 {
    margin-right: 0;
  }
  .Apps > .cols .col-2 .col-4 {
    margin-top: 15px;
    margin-left: 0;
  }
  .DashboardMetricsPanel > .body {
    overflow: auto;
  }
  .DashboardMetricsPanel .metric {
    box-shadow: none;
  }
}
