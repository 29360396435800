.IssuesList {
  position: relative;
  border-radius: 4px;
  background-color: #efeff1;
}

.IssuesList table {
  margin: 0;
}

/**
 * Header
 */
.IssuesList .flex-table .header {
  padding: 0 15px;
  display: flex;
  color: #908c99;
  align-items: center;
}
.IssuesList .flex-table .header > .SectionHeader {
  margin: 0;
  line-height: 54px;
}
.IssuesList .flex-table .header .issue-select {
  margin-right: 13px;
  flex: 0 0 0px;
  text-align: center;
}
.IssuesList .flex-table .header .issue-select .checkbox {
  margin: 0;
}
.IssuesList .flex-table .header .issue-select .checkbox label {
  display: block;
}
.IssuesList .flex-table .header .issue-select .checkbox label input {
  cursor: pointer;
}
.IssuesList .flex-table .header .issue-select .checkbox label input[disabled] {
  cursor: not-allowed;
}
.IssuesList .flex-table .header .issue-controls {
  flex: 1 1 auto;
  line-height: 54px;
}
.IssuesList .flex-table .header .issue-controls .SectionHeader {
  margin: 0 15px 0 0;
  display: inline;
}
.IssuesList .flex-table .header .SectionHeader:nth-child(3) {
  margin-left: 30px;
  flex: 0 0 170px;
  text-align: center;
}
.IssuesList .flex-table .header .SectionHeader:nth-child(4) {
  margin-left: 30px;
  flex: 0 0 65px;
  text-align: right;
}
.IssuesList .flex-table .header .SectionHeader:nth-child(5) {
  margin-left: 30px;
  flex: 0 0 230px;
  text-align: right;
}

/**
 * Rows
 */
.IssuesList .flex-table .item {
  border-top: 1px solid #e2e2e5;
  padding: 15px;
  color: #2e2d33;
  display: flex;
  align-items: center;
}
.IssuesList .flex-table .item > div {
}
.IssuesList .flex-table .item > div + div {
  margin-left: 30px;
}
.IssuesList .flex-table .item > div.select {
  margin: 0;
}
.IssuesList .flex-table .item > div.select.not-selected {
  opacity: 0.5;
}
.IssuesList .flex-table .item > div.select label {
  display: block;
}
.IssuesList .flex-table .item > div.select label input {
  cursor: pointer;
}
.IssuesList .flex-table .item > div.select label input[disabled] {
  cursor: not-allowed;
}
.IssuesList .flex-table .item > div.error {
  margin-left: 13px;
}

/**
 * Error
 */
.IssuesList .flex-table .item > .error {
  flex: 1 1 auto;
  min-width: 0;
}
.IssuesList .flex-table .item > .error .name {
  margin-bottom: 3px;
  white-space: nowrap;
}
.IssuesList .flex-table .item > .error .name > * {
  vertical-align: middle;
}
.IssuesList .flex-table .item > .error .name a {
  max-width: 100%;
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.IssuesList .flex-table .item > .error .name i.fa {
  margin-right: 6px;
}
.IssuesList .flex-table .item > .error .name i.fa-check {
  color: #9bb0af;
  font-size: 15px;
}
.IssuesList .flex-table .item > .error .name i.fa-bell-slash-o {
  color: #d9534f;
  font-size: 13px;
  opacity: 0.85;
}
.IssuesList .flex-table .item > .error .message {
  margin-bottom: 1px;
  color: #5d5966;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
}
.IssuesList .flex-table .item > .error .lambda {
  margin-bottom: 0;
  font-size: 13px;
  color: #908c99;
}
/**
 * History
 */
.IssuesList .flex-table .item > .history {
  flex: 0 0 170px;
}
/**
 * Count
 */
.IssuesList .flex-table .item > .count {
  flex: 0 0 65px;
  text-align: right;
}
.IssuesList .flex-table .item > .count span {
  line-height: 1;
  font-family: "Source Code Pro", monospace;
}
/**
 * Time
 */
.IssuesList .flex-table .item > .time {
  flex: 0 0 230px;
  text-align: right;
  color: #908c99;
  font-size: 13px;
}
.IssuesList .flex-table .item > .time .separator {
  color: #aeacb5;
  padding: 0 3px;
}

/**
 * Foter
 */
.IssuesList .footer {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dadee2;
}
.IssuesList .footer .LoaderButton {
  border-width: 1px;
}
.IssuesList .footer .LoaderButton i.fa {
  opacity: 0.9;
  font-size: 14px;
}
.IssuesList .footer .LoaderButton i.fa-angle-left {
  margin-right: 5px;
}
.IssuesList .footer .LoaderButton i.fa-angle-right {
  margin-left: 5px;
}

/**
 * Empty Sign
 */
.IssuesList .empty {
  border-top: 1px solid #dadee2;
}
.IssuesList .empty p {
  margin-bottom: 0;
  padding: 140px 0 150px;
  text-align: center;
  color: #908c99;
  font-size: 20px;
}

/**
 * Loading Sign
 */
.IssuesList .loading {
  border-top: 1px solid #dadee2;
}
.IssuesList .LoadingSpinner .spinner {
  margin: 0 auto;
  padding: 143px 0 153px;
}
