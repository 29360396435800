.PermissionErrorModal {
}

.PermissionErrorModal .modal-header h4 {
  color: #BE2F2B;
}

.PermissionErrorModal .modal-body {
  padding: 20px;
}
.PermissionErrorModal .modal-body > p {
  line-height: 1.5;
  color: #5D5966;
}
.PermissionErrorModal .modal-body > p:first-child {
  color: #2E2D33;
}
.PermissionErrorModal .modal-body > p:last-child {
  margin-top: 22px;
  margin-bottom: 0;
}

.PermissionErrorModal .modal-body .error-info {
}
.PermissionErrorModal .modal-body .error-info .fields {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.PermissionErrorModal .modal-body .error-info .field {
  flex: 1 1 40%;
  position: relative;
}
.PermissionErrorModal .modal-body .error-info .field:last-child {
  margin-left: 10px;
  flex: 1 1 60%;
}
.PermissionErrorModal .modal-body .error-info .field .header {
  padding: 0 1px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.PermissionErrorModal .modal-body .error-info .field .StyledControlLabel {
  margin-bottom: 8px;
}
.PermissionErrorModal .modal-body .error-info .field .status {
  margin: 0;
  font-size: 12px;
  color: #FD9527;
  line-height: 1;
}
.PermissionErrorModal .modal-body .error-info .field input {
  background-color: #EFEFF1;
  border-color: #E2E2E5;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-radius: 3px;
  padding: 7px 29px 7px 9px;
  font-size: 13px;
  line-height: 1.4;
  width: 100%;
  font-family: "Source Code Pro", monospace;
}
.PermissionErrorModal .modal-body .error-info .field .LoaderButton {
  border-radius: 0 3px 3px 0;
  padding: 6px 8px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.PermissionErrorModal .modal-body .error-info .field .LoaderButton i.fa {
  font-size: 11px;
}
.PermissionErrorModal .modal-body .error-info a.iam {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
}

.PermissionErrorModal .modal-body > p.reference {
  font-size: 16px;
  color: #908C99;
}
