.PipelineTableBuildCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PipelineTableBuildCell p.empty {
  margin-bottom: 0;
  color: #C7C6CC;
  line-height: 22px;
}

.PipelineTableBuildCell .status {
}
.PipelineTableBuildCell .status a {
  display: inline-block;
  line-height: 1;
}
.PipelineTableBuildCell .status i.fa {
  margin-right: 6px;
  color: #74649A;
  font-size: 22px;
  vertical-align: -3px;
}
.PipelineTableBuildCell.inactive .status i.fa {
  color: #C0BEC5;
}
.PipelineTableBuildCell .status i.fa-times-circle {
  color: #A35502;
}
.PipelineTableBuildCell .status i.fa-minus-circle {
  color: #908C99;
}
.PipelineTableBuildCell .status .id {
  color: #5D5966;
  font-size: 14px;
}
.PipelineTableBuildCell .status .commit {
  margin-left: 6px;
  font-size: 12px;
  vertical-align: 1px;
  color: #908C99;
  font-family: "Source Code Pro", monospace;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  padding: 2px 4px;
  line-height: 1;
  display: inline-block;
}

/** Dropdown **/
.PipelineTableBuildCell .PipelineDropdownButton button {
  padding: 3px 6px;
  font-size: 8px;
  color: #908C99;
  border: none;
  background: #E2E2E5;
  border-radius: 3px;
}
