.TwoFactorValidateForm {
}

.TwoFactorValidateForm .format {
  display: flex;
}
.TwoFactorValidateForm .qr {
  margin-right: 15px;
  flex: 0 0 132px;
}
.TwoFactorValidateForm .qr .mfa-code  {
  margin-top: 5px;
  margin-bottom: 0;
  font-family: "Source Code Pro", monospace;
  font-size: 12px;
  word-break: break-all;
}
.TwoFactorValidateForm .code {
}
.TwoFactorValidateForm .code .SectionHeader {
  margin-bottom: 5px;
}
.TwoFactorValidateForm .code p.copy {
  margin-bottom: 7px;
  font-size: 14px;
  color: #5D5966
}
.TwoFactorValidateForm .code .form-group {
  margin-top: 20px;
  margin-bottom: 0;
}

.TwoFactorValidateForm .help-block {
  margin-top: 7px;
  font-size: 14px;
}

.TwoFactorValidateForm .controls {
  margin-top: 30px;
  text-align: left;
}
.TwoFactorValidateForm .controls .TextButton {
  margin-left: 25px;
  font-size: 14px;
}
