.BrandNavbar {
  margin-bottom: 0;
  box-shadow: inset 0 1px 1px #7669BD, 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #53437D;
  border-color: #23134C;
  border-radius: 0;
  z-index: 10;
}
.BrandNavbar .container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}
.BrandNavbar .navbar-brand {
  padding-right: 12px;
  font-family: Rubik, sans-serif;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 700;
  color: white;
}
.BrandNavbar .navbar-brand:hover,
.BrandNavbar .navbar-brand:active,
.BrandNavbar .navbar-brand:visited {
  color: white;
}

.BrandNavbar .navbar-text {
  margin-right: 5px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  color: white;
}

.BrandNavbar .nav > li > a,
.BrandNavbar .navbar-text a,
.BrandNavbar .nav > li.active > a,
.BrandNavbar .nav > li.active a:hover {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  color: white;
}
.BrandNavbar .nav > li > a:hover,
.BrandNavbar .navbar-text a:hover {
  color: #C7C6CC;
}
.BrandNavbar .nav > li.active > a,
.BrandNavbar .nav > li.active > a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/**
 * App Links
 */
.BrandNavbar ul.app-links {
  display: flex;
  width: calc(100% - 150px);
}
.BrandNavbar .nav.app-links > li {
  min-width: 0;
}
.BrandNavbar .nav.app-links > li > a,
.BrandNavbar .nav.app-links > li > a:hover {
  padding-left: 0;
  padding-right: 6px;
  font-family: Rubik, sans-serif;
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.BrandNavbar .nav.app-links > li.active > a,
.BrandNavbar .nav.app-links > li.active > a:hover {
  background-color: transparent;
}
.BrandNavbar .nav.app-links > li > a:hover,
.BrandNavbar .nav.app-links > li.active > a:hover {
  color: #C7C6CC;
}
.BrandNavbar .nav.app-links > li:last-child > a {
}
.BrandNavbar .nav.app-links > .navbar-text {
  margin: 17px 8px 0 0;
  line-height: 1;
  opacity: 0.5;
}
.BrandNavbar .nav.app-links > .navbar-text:first-child {
  margin-right: 12px;
}

.BrandNavbar li.dropdown {
}
/** Dropdown Button **/
.BrandNavbar li.dropdown a.dropdown-toggle {
  position: relative;
  padding: 14px 0px 14px 15px;
  line-height: 1;
}
.BrandNavbar .navbar-nav > .open > a.dropdown-toggle,
.BrandNavbar .navbar-nav > .open > a.dropdown-toggle:hover {
  background-color: transparent;
}

/** Dropdown Menu **/
.BrandNavbar li.dropdown ul.dropdown-menu {
  padding: 0;
  right: -14px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  max-width: 200px;
}
.BrandNavbar li.dropdown ul.dropdown-menu li a {
  padding: 10px 15px;
  color: #2E2D33;
}
.BrandNavbar li.dropdown ul.dropdown-menu li a:focus {
  outline: none;
}
.BrandNavbar li.dropdown ul.dropdown-menu li.active a {
  background-color: transparent;
  color: #2E2D33;
}
.BrandNavbar li.dropdown ul.dropdown-menu li.active a:hover {
  background-color: #F5F5F5;
}

/** Org Menu Item **/
.BrandNavbar li.dropdown ul.dropdown-menu li.org a {
  display: flex;
  align-items: center;
}
.BrandNavbar li.dropdown ul.dropdown-menu li.org a .UserImage {
  flex: 0 0 auto;
}
.BrandNavbar li.dropdown ul.dropdown-menu li.org a > span {
  overflow: hidden;
  margin-left: 8px;
}
.BrandNavbar li.dropdown ul.dropdown-menu li.org a > span > span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.BrandNavbar li.dropdown ul.dropdown-menu li.org a > span > span:last-child {
  margin-top: 1px;
  color: #A9A6B0;
  font-size: 12px;
}

/** Dropdown Header **/
.BrandNavbar li.dropdown ul.dropdown-menu li.main-header {
  padding: 11px 15px 10px;
  color: #908C99;
  font-size: 13px;
  line-height: 1.5;
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.BrandNavbar li.dropdown ul.dropdown-menu li.main-header b {
  font-weight: 500;
}

.BrandNavbar li.dropdown ul.dropdown-menu li.divider {
  margin: 0;
}

@media all and (max-width: 768px) {
  .BrandNavbar ul.nav.app-links {
    margin-bottom: 0;
    display: block;
    width: auto;
  }
  .BrandNavbar ul.nav.app-links .navbar-text {
    display: none;
  }
  .BrandNavbar ul.nav.app-links > li > a,
  .BrandNavbar ul.nav.app-links > li > a:hover {
    padding: 10px 15px;
  }
  .BrandNavbar li.dropdown {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  .BrandNavbar li.dropdown ul.dropdown-menu {
    right: auto;
    max-width: none;
    box-shadow: none;
  }
  .BrandNavbar li.dropdown ul.dropdown-menu li.main-header {
    padding: 0 15px 5px;
    color: rgba(255, 255, 255, 0.5);
  }
  .BrandNavbar .navbar-nav li.dropdown ul.dropdown-menu > li > a {
    padding: 10px 15px 10px 25px;
    color: white;
  }
  .BrandNavbar .navbar-nav .open ul.dropdown-menu > .active > a,
  .BrandNavbar .navbar-nav .open ul.dropdown-menu > .active:hover > a {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .BrandNavbar li.dropdown ul.dropdown-menu li.org a > span > span:last-child,
  .BrandNavbar li.dropdown ul.dropdown-menu li.org.active a:hover > span > span:last-child {
    color: white;
  }
  .BrandNavbar .navbar-nav li.dropdown ul.dropdown-menu > li > a:hover,
  .BrandNavbar li.dropdown ul.dropdown-menu li.org a:hover > span > span:last-child {
    color: #C7C6CC;
  }
  .BrandNavbar li.dropdown ul.dropdown-menu li.divider {
    display: none;
  }
}
