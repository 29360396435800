.VerifyEmailForm {
}

.VerifyEmailForm .help-block {
  margin-top: 7px;
  font-size: 14px;
  color: #908C99;
}

.VerifyEmailForm .send-code-copy {
  color: #FD9527;
}

.VerifyEmailForm form .StyledControlLabel {
}

.VerifyEmailForm .controls {
  margin-top: 20px;
}
.VerifyEmailForm .controls button:first-child {
  margin-left: 0;
}
.VerifyEmailForm .controls button:last-child {
}
