.LoaderToggle {
  display: inline-block;
}

.LoaderToggle.disabled {
  opacity: 0.5;
}

/* The switch - the box around the slider */
.LoaderToggle label {
  margin: 0;
  position: relative;
  display: inline-block;
  width: 60px;
  line-height: 34px;
  transition: 0.3s;
}
.LoaderToggle.loading label {
  opacity: 0.5;
}

/* Hide default HTML checkbox */
.LoaderToggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.LoaderToggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #C7C6CC;
  transition: .4s;
  -webkit-transition: .4s;
  border-radius: 34px;
}

.LoaderToggle .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  -webkit-transition: .4s;
  border-radius: 50%;
}

.LoaderToggle.loading .slider:before {
  background-color: #EFEFF1;
}

.LoaderToggle input:checked + .slider {
  background-color: #53437D;
}

.LoaderToggle input:disabled + .slider {
  cursor: not-allowed;
}

.LoaderToggle input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/** Loading Sign **/
.LoaderToggle span.loading {
  position: absolute;
  left: 5px;
  opacity: 0;
  top: 50%;
  margin-top: -9px;
  width: 23px;
  height: 18px;

  animation: fadeIn ease-in 1;
  animation-fill-mode:forwards;
  animation-duration: 0.2s;

  animation-delay: 0.4s; /* Wait before showing the spinner */
}
.LoaderToggle span.loading i.fa {
  display: block;
  font-size: 18px;
  opacity: 0.3;
}
.LoaderToggle input ~ span.loading {
}
.LoaderToggle input:checked ~ span.loading {
  left: auto;
  right: 5px;
}

.LoaderToggle > .copy {
  margin-left: 8px;
  color: #5D5966;
  display: inline-block;
}
.LoaderToggle > .copy b {
  font-weight: 500;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
