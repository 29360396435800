.PipelineTableStageStatusCell {
  position: relative;
  display: flex;
  align-items: center;
}
.PipelineTableStageStatusCell > div {
  min-width: 0;
  flex-grow: 1;
}
.PipelineTableStageStatusCell > div p {
  margin: 0;
}
.PipelineTableStageStatusCell > div p+p {
  margin-top: 1px;
}
.PipelineTableStageStatusCell > a {
  margin-right: 8px;
}
.PipelineTableStageStatusCell > a i.fa {
  display: block;
  font-size: 36px;
  color: #74649A;
}
.PipelineTableStageStatusCell.mini > a i.fa {
  font-size: 30px;
}
.PipelineTableStageStatusCell > a i.fa-times-circle {
  color: #A35502;
}
.PipelineTableStageStatusCell > a i.fa-minus-circle {
  color: #908C99;
}
.PipelineTableStageStatusCell .id {
  color: #5D5966;
}
.PipelineTableStageStatusCell .date {
  margin-left: 7px;
  font-size: 12px;
  color: #AEACB5;
}
.PipelineTableStageStatusCell .line-2 {
  display: flex;
  align-items: center;
}
.PipelineTableStageStatusCell.mini .line-2 {
  margin-top: 2px;
}
.PipelineTableStageStatusCell .branch {
  margin-right: 5px;
  font-size: 12px;
  color: #908C99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 1 auto;
}
.PipelineTableStageStatusCell .branch i.fa,
.PipelineTableStageStatusCell .multiple i.fa {
  margin-right: 3px;
  opacity: 0.9;
}
.PipelineTableStageStatusCell .commit {
  flex: 0 0 auto;
  font-size: 12px;
  color: #908C99;
  font-family: "Source Code Pro", monospace;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  padding: 2px 4px;
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
}
.PipelineTableStageStatusCell .multiple {
  font-size: 12px;
  color: #908C99;
  line-height: 18px;
}

.PipelineTableStageStatusCell p.empty {
  margin-bottom: 0;
  color: #C7C6CC;
  line-height: 39px;
}
.PipelineTableStageStatusCell.mini p.empty {
  line-height: 42px;
}
