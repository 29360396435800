.GitProviderSelect {
}

.GitProviderSelect .LoaderButton {
  color: #5D5966;
}

.GitProviderSelect .LoaderButton span:last-child {
  margin-left: 8px;
}

.GitProviderSelect .dropdown {
  margin-right: 15px;
}
.GitProviderSelect .dropdown button {
  padding: 9px 15px;
  border-radius: 3px;
  border-width: 2px;
  background-color: transparent;
  border-color: #CCC;
  color: #5D5966;
  transition: background-color .25s ease-out, border .25s ease-out;
}
.GitProviderSelect .dropdown button:hover {
  background-color: #E6E6E6;
  border-color: #ADADAD;
}
.GitProviderSelect .dropdown button.btn {
  border-width: 2px 1px 2px 2px;
}
.GitProviderSelect .dropdown button.btn .fa {
  margin-right: 8px;
}
.GitProviderSelect .dropdown button.dropdown-toggle {
  padding-left: 10px;
  padding-right: 10px;
  border-width: 2px 2px 2px 1px;
}

/**
 * Dropdown
 */
.GitProviderSelect .dropdown .dropdown-menu {
  padding: 0;
  overflow: hidden;
}
.GitProviderSelect .dropdown .dropdown-menu a {
  color: #5D5966;
  padding-top: 8px;
  padding-bottom: 8px;
}
.GitProviderSelect .dropdown .dropdown-menu a:focus {
  outline: none;
}
