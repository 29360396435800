.BuildInfoPanel {
  display: flex;
  padding: 10px 10px 11px;
  border: 1px solid #DDD;
  background-color: #F5F5F5;
  border-radius: 3px;
}
.BuildInfoPanel.failed {
  border-color: #D43F3A;
}

.BuildInfoPanel .icon {
}
.BuildInfoPanel .icon .fa {
  font-size: 28px;
  color: #908C99;
  display: block;
}
.BuildInfoPanel .icon .fa-times-circle {
  color: #A35502;
}
.BuildInfoPanel .icon .fa-cog,
.BuildInfoPanel .icon .fa-check-circle {
  color: #74649A;
}
.BuildInfoPanel.completed_skipped .icon .fa-check-circle {
  color: #C0BEC5;
}
.BuildInfoPanel.queued .icon .fa-cog {
  color: #908C99;
}

.BuildInfoPanel .content {
  margin-left: 8px;
  flex-grow: 1;
}

.BuildInfoPanel .content .status {
  margin-bottom: 0;
  color: #5D5966;
  line-height: 28px;
}
.BuildInfoPanel.failed .content .status {
  color: #A35502;
}
.BuildInfoPanel .content .sls-version {
  margin-top: 3px;
}
.BuildInfoPanel .content .sls-version p {
  margin-bottom: 0;
  font-size: 14px;
  color: #908C99;
}
.BuildInfoPanel .content .sls-version a {
  font-size: 14px;
  opacity: 0.8;
  display: inline-block;
}

.BuildInfoPanel .content .message {
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 0;
  border-top: 1px solid #E2E2E5;
}
.BuildInfoPanel.failed .content .message {
  border-color: #D43F3A;
}
