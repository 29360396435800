.BetaSignupForm {
}

.BetaSignupForm .help-block {
  margin-top: 7px;
  font-size: 14px;
}

.BetaSignupForm .controls {
  margin-top: 15px;
}

.BetaSignupForm .controls p.legal {
  color: #908C99;
  font-size: 14px;
}

.BetaSignupForm .controls {
  margin-top: 30px;
}
