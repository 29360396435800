.UsernameSettings {
  padding: 20px;
}

.UsernameSettings .ScreenHeader {
  margin-bottom: 15px;
}

.UsernameSettings .current {
  margin-bottom: 30px;
}
.UsernameSettings .current b {
  font-weight: 500;
}

.UsernameSettings .UsernameForm {
  margin-bottom: 60px;
  max-width: 400px;
}
