.ServiceResourceList {
}

.ServiceResourceList .resource {
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
              0 1px 2px rgba(0, 0, 0, 0.05),
              0 2px 3px rgba(0, 0, 0, 0.05);
}
.ServiceResourceList .resource:last-child {
  margin-bottom: 0;
}
.ServiceResourceList .resource.deleting {
  opacity: 0.5;
}

.ServiceResourceList .resource .card {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ServiceResourceList .resource .card .col1 {
  margin-right: 30px;
  padding: 1px 0; /* Match height of right col */
  display: flex;
  align-items: center;
  min-width: 0; /* For text truncate in child */
}

.ServiceResourceList .resource .card .col1 .ServiceIcon {
  margin: 0 8px 0 0;
}
.ServiceResourceList .resource .card .col1 div {
  min-width: 0; /* For text truncate in child */
}
.ServiceResourceList .resource .card .col1 div a {
  margin-top: 1px;
  line-height: 1.1;
  display: block;
  font-size: 18px;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ServiceResourceList .resource .card .col1 div a .glyphicon {
  margin-right: 5px;
  vertical-align: 1px;
  font-size: 12px;
  animation: spin 1s infinite linear;
}
.ServiceResourceList .resource .card .col1 div a i.fa {
  margin-right: 5px;
  vertical-align: 1px;
  font-size: 13px;
}
.ServiceResourceList .resource .card .col1 div p {
  margin: 4px 0 0;
  line-height: 1.1;
  font-size: 14px;
  color: #908C99;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ServiceResourceList .resource .card .col1 div p.disabled {
  color: #C7C6CC;
}

.ServiceResourceList .resource .card .col2 {
  display: flex;
  align-items: center;
}

.ServiceResourceList .resource .card .col2 .fields {
  text-align: right;
  margin-right: 12px;
}
.ServiceResourceList .resource .card .col2 .fields .SectionHeader {
  margin-bottom: 0;
  line-height: 1.5;
  color: #C7C6CC;
  white-space: nowrap;
}
.ServiceResourceList .resource .card .col2 .fields p a {
  color: #908C99;
}
.ServiceResourceList .resource .card .col2 .fields p a:hover {
  color: #330033;
}
.ServiceResourceList .resource .card .col2 .LoaderButton {
  padding: 14px 16px 13px;
  height: 45px;
  display: block;
}
.ServiceResourceList .resource .card .col2 .TextButton {
  font-size: 14px;
}
