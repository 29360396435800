.StageReportPanel {
}

/**
 * API
 */
.StageReportPanel div.header-apis {
  padding: 0 15px 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.StageReportPanel div.header-apis h4 {
  margin: 0;
}
.StageReportPanel div.header-apis .SectionHeader  {
  margin-bottom: 0;
  display: inline-block;
  text-align: right;
  width: 270px;
}
.StageReportPanel div.header-apis .SectionHeader:first-child {
  margin-right: 10px;
  padding-right: 71px;
}
.StageReportPanel div.header-apis .SectionHeader:last-child {
  padding-right: 45px;
}
.StageReportPanel div.header-apis .SectionHeader .separator {
  margin: 0 5px;
  color: #C7C6CC;
  font-size: 10px;
  vertical-align: 1px;
}
.StageReportPanel .apis {
  margin-bottom: 20px;
}
.StageReportPanel .apis .api {
  margin-bottom: 5px;
  background-color: #EFEFF1;
  border-color: 3px;
  padding: 15px 15px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.StageReportPanel .apis .api:last-child {
  margin-bottom: 0;
}
.StageReportPanel .apis .api .col1 {
  min-width: 0;
}
.StageReportPanel .apis .api .col1 p.name {
  margin-bottom: 0;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.StageReportPanel .apis .api .col1 p.endpoint {
  margin-top: 3px;
  margin-bottom: 0;
  font-size: 13px;
  color: #908C99;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.StageReportPanel .apis .api .col2 {
  margin-left: 60px;
  white-space: nowrap;
}

/**
 * API Metric
 */
.StageReportPanel .apis .api .metric {
  overflow: hidden;
  display: inline-block;
  text-align: right;
  width: 270px;
}
.StageReportPanel .apis .api .metric.latency {
  margin-right: 10px;
}
.StageReportPanel .apis .api .metric .current {
  font-size: 16px;
  font-family: "Source Code Pro", monospace;
}
.StageReportPanel .apis .api .metric .current.empty {
  margin-right: 99px;
}
.StageReportPanel .apis .api .metric .current .separator {
  margin: 0 4px;
  font-size: 14px;
  vertical-align: 1px;
  color: #AEACB5;
}
.StageReportPanel .apis .api .metric i.fa {
  margin-left: 8px;
  vertical-align: 1px;
  font-size: 11px;
  color: #AEACB5;
}
.StageReportPanel .apis .api .metric i.fa-arrow-up {
  color: #DF7B77;
}
.StageReportPanel .apis .api .metric i.fa-arrow-down {
  color: #8DA59F;
}
.StageReportPanel .apis .api .metric i.fa-minus {
  color: #C7C6CC;
  font-size: 9px;
  width: 11px;
  text-align: center;
}
.StageReportPanel .apis .api .metric i.fa-minus.empty {
  visibility: hidden;
}
.StageReportPanel .apis .api .metric .previous {
  margin-left: 7px;
  padding-bottom: 2px;
  font-size: 12px;
  color: #AEACB5;
  font-family: "Source Code Pro", monospace;
  display: inline-block;
  text-align: left;
  width: 100px;
}
.StageReportPanel .apis .api .metric .previous.empty {
  font-size: 16px;
  vertical-align: 1px;
}
.StageReportPanel .apis .api .metric .previous .separator {
  margin: 0 2px;
  font-size: 10px;
  vertical-align: 1px;
  color: #C7C6CC;
}

/**
 * Lambdas
 */
.StageReportPanel h4.header-lambdas {
  margin-top: 0;
}
.StageReportPanel .lambda-reports {
  display: flex;
  align-items: flex-start;
  margin-right: 5px;
}
.StageReportPanel .lambda-reports .lambdas {
  min-width: 0;
  flex: 0 1 50%;
  background-color: #EFEFF1;
  border-color: 3px;
  padding: 15px 15px 13px;
  margin-right: 5px;
}
.StageReportPanel .lambda-reports .lambdas > .header {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E2E2E5;
}
.StageReportPanel .lambda-reports .lambdas > .header .SectionHeader {
  font-size: 14px;
}
.StageReportPanel .lambda-reports .lambdas > .header .SectionHeader .separator {
  margin: 0 5px;
  color: #C7C6CC;
  vertical-align: 1px;
  font-size: 12px;
}

/**
 * Lambda
 */
.StageReportPanel .lambda-reports .lambdas .lambda {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.StageReportPanel .lambda-reports .lambdas .lambda:last-child {
  margin-bottom: 0;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col1 {
  margin-right: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 {
  flex: 0 0 auto;
  white-space: nowrap;
}

/**
 * Lambda Metrics
 */
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .current {
  font-size: 14px;
  font-family: "Source Code Pro", monospace;
  display: inline-block;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .current.empty {
  margin-right: 95px;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .current .separator {
  margin: 0 3px;
  vertical-align: 1px;
  font-size: 12px;
  color: #AEACB5;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa {
  margin-left: 6px;
  vertical-align: 1px;
  font-size: 10px;
  color: #AEACB5;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa-arrow-up {
  color: #DF7B77;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa-arrow-up.level-0  { color: #CCA5A8; }
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa-arrow-up.level-1  { color: #CF9596; }
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa-arrow-up.level-2  { color: #D18485; }
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa-arrow-up.level-3  { color: #D47473; }
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa-arrow-up.level-4  { color: #D66361; }
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa-arrow-up.level-5  { color: #D9534F; }
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa-arrow-down {
  color: #C7C6CC;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa-minus {
  color: #C7C6CC;
  font-size: 8px;
  width: 9px;
  text-align: center;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 > i.fa-minus.empty {
  visibility: hidden;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .previous {
  margin-left: 5px;
  padding-bottom: 2px;
  font-size: 11px;
  color: #AEACB5;
  font-family: "Source Code Pro", monospace;
  display: inline-block;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .previous.empty {
  font-size: 16px;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .previous .separator {
  margin: 0 2px;
  font-size: 10px;
  color: #C7C6CC;
  vertical-align: 1px;
}
.StageReportPanel .lambda-reports .lambdas.duration .lambda .col2 .previous {
  min-width: 75px;
}
.StageReportPanel .lambda-reports .lambdas.error .lambda .col2 .previous {
  min-width: 70px;
}

/**
 * Lambda Hide Dropdown
 */
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .dropdown {
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .dropdown button {
  background: transparent;
  border: none;
  padding: 1px 8px;
  border-radius: 3px;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .dropdown i.fa {
  vertical-align: -2px;
  color: #AEACB5;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .dropdown.open i.fa,
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .dropdown button:hover i.fa {
  color: #6A5CA2;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .dropdown-menu {
  padding: 0;
  overflow: hidden;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .dropdown-menu a {
  color: #5D5966;
  padding-top: 8px;
  padding-bottom: 8px;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .dropdown-menu a:focus {
  outline: none;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .LoaderButton {
  padding: 1px 8px;
  border: none;
  width: 27px;
}
.StageReportPanel .lambda-reports .lambdas .lambda .col2 .LoaderButton .glyphicon {
  margin-right: 0;
}

/**
 * No Errors
 */
.StageReportPanel .lambda-reports .lambdas > .no-errors {
  padding: 30px 0;
  color: #908C99;
  text-align: center;
}
.StageReportPanel .lambda-reports .lambdas > .no-errors i.fa {
  margin-right: 8px;
  opacity: 0.9;
}

/**
 * Hidden Lambdas
 */
.StageReportPanel .lambda-reports .lambdas > .muted .SectionHeader {
  margin-top: 6px;
  margin-bottom: 18px;
  font-size: 11px;
  position: relative;
}
.StageReportPanel .lambda-reports .lambdas > .muted .SectionHeader span {
  position: relative;
  top: 6px;
  background-color: #EFEFF1;
  padding-right: 2px;
  color: #C7C6CC;
}
.StageReportPanel .lambda-reports .lambdas > .muted .SectionHeader hr {
  margin: 0;
}
.StageReportPanel .lambda-reports .lambdas .muted .lambda .col1,
.StageReportPanel .lambda-reports .lambdas .muted .lambda .col2 > .current,
.StageReportPanel .lambda-reports .lambdas .muted .lambda .col2 > i.fa,
.StageReportPanel .lambda-reports .lambdas .muted .lambda .col2 .previous {
  opacity: 0.25;
}
.StageReportPanel .lambda-reports .lambdas .muted .lambda .col2 .dropdown i.fa {
  opacity: 0.5;
}
