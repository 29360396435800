.SettingsLinksList {
  width: 250px;
}
.SettingsLinksList h5 {
  margin-top: 3px;
  font-size: 15px;
}
.SettingsLinksList div.list {
  max-height: 450px;
  overflow-y: scroll;
}
.SettingsLinksList div.list a {
  padding: 10px;
  font-size: 15px;
  background-color: #EFEFF1;
  border-top: 1px solid #E2E2E5;
  transition: background 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SettingsLinksList div.list a > span {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
.SettingsLinksList div.list a > span i.fa-exclamation-triangle {
  margin-right: 4px;
  font-size: 12px;
}
.SettingsLinksList div.list a > span .glyphicon {
  margin-right: 4px;
  font-size: 11px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
.SettingsLinksList div.list a i.fa-angle-right {
  margin-left: 2px;
  font-size: 14px;
  opacity: 0;
  transition: 0.3s;
}
.SettingsLinksList div.list a:hover,
.SettingsLinksList div.list .TextButton:hover {
  color: #6A5CA2;
  background-color: #E6E6E8;
}
.SettingsLinksList div.list a:hover i.fa-angle-right {
  opacity: 1;
}
.SettingsLinksList div.list a:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top: 0;
}
.SettingsLinksList div.list a:last-child,
.SettingsLinksList div.list .TextButton:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.SettingsLinksList div.list .TextButton {
  font-size: 12px;
  padding: 8px 10px;
  display: block;
  background-color: #EFEFF1;
  border-top: 1px solid #E2E2E5;
  transition: background 0.3s;
}
.SettingsLinksList div.list .TextButton i.fa {
  margin-left: 7px;
  vertical-align: 0;
}

