.EnableIssuesPanel {
}

/**
 * Updating Status
 */
.EnableIssuesPanel .status {
  margin-left: 10px;
}
.EnableIssuesPanel .status span {
  font-size: 15px;
  color: #FD9527;
}
.EnableIssuesPanel .status .glyphicon {
  margin-top: 2px;
  margin-right: 5px;
  vertical-align: -1px;
  font-size: 13px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

/**
 * Enable Errors
 */
.EnableIssuesPanel .errors {
  margin-top: 15px;
  padding: 13px 15px 15px;
  background-color: #EFEFF1;
  border-radius: 3px;
}
.EnableIssuesPanel .errors .header {
 margin-bottom: 0;
 color: #D9534F;
}
.EnableIssuesPanel .errors .header i.fa {
 margin-right: 5px;
 font-size: 14px;
 color: #BE2F2B;
}

/**
 * Enable Log
 */
.EnableIssuesPanel .errors .logs {
  margin-top: 12px;
  background-color: #5D5966;
  padding: 15px 15px 10px 8px;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 3px;
}
.EnableIssuesPanel .errors .logs ul {
  color: white;
  font-size: 14px;
}
.EnableIssuesPanel .errors .logs ul li.success p,
.EnableIssuesPanel .errors .logs ul li.success i.fa {
  opacity: 0.4;
}
.EnableIssuesPanel .errors .logs ul > li > p {
  margin-bottom: 5px;
}
.EnableIssuesPanel .errors .logs ul ul {
  margin-left: 24px;
}

/**
 * Log Group Status
 */
.EnableIssuesPanel .errors .logs ul li.service {
  margin-bottom: 10px;
}
.EnableIssuesPanel .errors .logs ul li.service > p.name {
  margin-bottom: 0;
}
.EnableIssuesPanel .errors .logs ul li.service > p.message {
  margin: 5px 0 0 0;
  font-size: 13px;
  color: #CA5552;
}
.EnableIssuesPanel .errors .logs ul li.service ul {
  margin-top: 7px;
}

/**
 * Log Group Status
 */
.EnableIssuesPanel .errors .logs ul li.log-group {
  margin-bottom: 8px;
}
.EnableIssuesPanel .errors .logs ul li.log-group p.name {
  margin-bottom: 0;
}
.EnableIssuesPanel .errors .logs ul li.log-group p.message {
  margin: 5px 0 0 1px;
  font-size: 13px;
}
.EnableIssuesPanel .errors .logs ul li.log-group.fail p.message {
  color: #CA5552;
}

/**
 * Retry Form
 */
.EnableIssuesPanel .errors hr {
  margin-top: 27px;
  border-color: #DBDADE;
}
.EnableIssuesPanel .errors .action .iam {
  margin-bottom: 15px;
}
.EnableIssuesPanel .errors .action .iam p {
  margin-bottom: 0;
}
.EnableIssuesPanel .errors .action .iam p .TextButton {
  font-weight: 500;
}
.EnableIssuesPanel .errors .action .checkbox {
  margin-bottom: 7px;
  font-size: 14px;
}
.EnableIssuesPanel .errors .action .form-group.retry-stage {
  margin-bottom: 25px;
  margin-left: 20px;
}
.EnableIssuesPanel .errors .action .form-group.retry-stage select {
  max-width: 380px;
}
.EnableIssuesPanel .errors .form-group .help-block {
  margin-top: 7px;
  color: #908C99;
  margin-bottom: 0;
  font-size: 14px;
}
