.ScrollShadowContainer {
  position: relative;
}

.ScrollShadowContainer > .scroll-content {
  overflow-x: auto;
}

.ScrollShadowContainer > .scroll-content > .scroll-shadow {
  width: 20px;
  height: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  transition: opacity .15s linear;
  pointer-events: none;
}
.ScrollShadowContainer > .scroll-content > .scroll-shadow.left {
  left: 0;
  background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.075));
}
.ScrollShadowContainer > .scroll-content > .scroll-shadow.right {
  right: 0;
  background-image: linear-gradient(to right ,transparent ,rgba(0, 0, 0, 0.075));
}
