.MiniActivityList {
}

.MiniActivityList > h4 {
  margin: 0;
  padding-top: 1px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #DADEE2;
}
.MiniActivityList > h4 span {
  font-size: 16px;
  font-weight: 500;
}
.MiniActivityList > h4 a {
  font-size: 13px;
  font-weight: normal;
}
.MiniActivityList .MiniActivityItem {
  margin-top: 15px;
}

.MiniActivityList .empty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #AEACB5;
  height: 200px;
  padding: 0 30px;
  text-align: center;
  line-height: 1.6;
}
