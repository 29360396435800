.MiniPipelineStageCell {
  width: 100%;
  margin-right: 30px;
  padding: 9px 10px;
  background-color: white;
  border-radius: 4px;
  flex: 0 0 auto;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
              0 2px 3px rgba(0, 0, 0, 0.03),
              0 3px 4px rgba(0, 0, 0, 0.02);
}

/**
 * Title
 */
.MiniPipelineStageCell div.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E2E2E5;
  padding: 0 0 7px 0;
  margin-bottom: 9px;
}
.MiniPipelineStageCell.empty div.title {
  margin-bottom: 0;
}
.MiniPipelineStageCell div.title > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}
.MiniPipelineStageCell .PipelineDropdownButton button {
  margin-bottom: 1px;
  padding: 3px 6px;
  font-size: 8px;
  color: #AEACB5;
  border: none;
  background: #EFEFF1;
  border-radius: 3px;
}
.MiniPipelineStageCell .PipelineDropdownButton .dropdown-menu .cancel a {
  color: #D9534F;
}
.MiniPipelineStageCell .PipelineDropdownButton a .glyphicon {
  font-size: 11px;
  opacity: 0.9;
  margin-right: 5px;
  animation: spin 1s infinite linear;
}
.MiniPipelineStageCell .PipelineDropdownButton .dropdown-menu .disabled.cancel a {
  color: #D9534F;
  opacity: 0.8;
}

.MiniPipelineStageCell.deleting {
  opacity: 0.5;
}
.MiniPipelineStageCell.deleting div.title .icon {
  margin-right: 6px;
  font-size: 13px;
  vertical-align: -2px;
  animation: spin 1s infinite linear;
}
.MiniPipelineStageCell.delete_failed div.title i.fa {
  margin-right: 5px;
  font-size: 14px;
  vertical-align: -1px;
}

.MiniPipelineStageCell .PipelineTableStageStatusCell {
  padding: 0 0 2px 2px;
}

.MiniPipelineStageCell > .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  height: 120px;
  text-align: center;
}
.MiniPipelineStageCell > .empty p {
  font-size: 14px;
  color: #908C99;
  margin-bottom: 0;
}
.MiniPipelineStageCell > .empty p.linked {
  margin-bottom: 2px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}
.MiniPipelineStageCell > .empty a.branch {
  margin-left: 2px;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #E2E2E5;
  font-family: "Source Code Pro", monospace;
  font-size: 12px;
  padding: 2px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.MiniPipelineStageCell > .empty a.branch i.fa {
  margin-right: 4px;
  font-size: 11px;
  opacity: 0.9;
}
.MiniPipelineStageCell > .empty .separator {
  margin: 15px auto 20px;
  border-top: 1px solid #E2E2E5;
  text-align: center;
  height: 1px;
  width: 100%;
  max-width: 66px;
}
.MiniPipelineStageCell > .empty .separator span {
  padding: 0 6px;
  position: relative;
  top: -13px;
  font-size: 11px;
  color: #C7C6CC;
  background-color: white;
}
.MiniPipelineStageCell > .empty .LoaderButton {
  align-self: center;
  padding: 7px 16px 7px;
  font-size: 13px;
}
