.AppPipelineEditPanel {
  border-radius: 3px;
  background-color: #E2E2E5;
}

.AppPipelineEditPanel .pipelineedit-content {
  display: flex;
}

/**
 * Col1
 */
.AppPipelineEditPanel .pipelineedit-content > .col1 {
  border-right: 1px solid #C7C6CC;
  flex: 0 0 210px;
  max-width: 210px;
}
.AppPipelineEditPanel .pipelineedit-content > .col1 .header {
  padding: 15px 15px 0;
  margin-top: 6px;
  margin-bottom: 17px;
}
.AppPipelineEditPanel .pipelineedit-content > .col1 .header .SectionHeader {
  font-size: 14px;
  color: #AEACB5;
}
.AppPipelineEditPanel .pipelineedit-content > .col1 .body {
  padding: 0 0 0 15px;
}
.AppPipelineEditPanel .pipelineedit-content > .col1 .body .LoaderButton {
  text-align: center;
  padding: 9px 11px;
  border: 1px solid #C7C6CC;
  width: 180px;
  background-color: white;
}
.AppPipelineEditPanel .pipelineedit-content > .col1 .body .LoaderButton:hover {
  background-color: #EFEFF1;
}
.AppPipelineEditPanel .pipelineedit-content > .col1 .body .LoaderButton i.fa {
  margin-right: 7px;
  opacity: 0.8;
  font-size: 16px;
}

.AppPipelineEditPanel .pipelineedit-content > .col2 {
  padding: 20px 30px 30px;
  display: flex;
  flex-grow: 1;
  overflow-x: auto;
}
.AppPipelineEditPanel .pipelineedit-content > .col2 > .stage-group {
  margin-right: 30px;
  flex: 0 0 250px;
}

/**
 * Stage Container
 */
.AppPipelineEditPanel .stage-container {
  width: 250px;
}
.AppPipelineEditPanel .stage-container+.stage-container {
  margin-top: 20px;
}
.AppPipelineEditPanel .stage-container.more {
}
.AppPipelineEditPanel .stage-container:nth-last-child(2) {
  margin-right: 0;
}
.AppPipelineEditPanel .stage-container > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}
.AppPipelineEditPanel .stage-container > .header .SectionHeader {
  margin-bottom: 0;
  line-height: 18px;
  font-size: 16px;
}
.AppPipelineEditPanel .stage-container > .header .glyphicon {
  margin-right: 2px;
  color: #C7C6CC;
  line-height: 1;
  font-size: 18px;
}

/**
 * Stage
 */
.AppPipelineEditPanel .stage-container .stage {
  background-color: #EFEFF1;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1), 0 6px 6px rgba(0,0,0,0.06), 0 10px 20px rgba(0,0,0,0.08);
}
/**
 * Placeholder Stage
 */
.AppPipelineEditPanel .stage-container .placeholder {
  height: 246px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 2px dashed #C7C6CC;
  cursor: pointer;
}
.AppPipelineEditPanel .stage-container .placeholder .body {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.AppPipelineEditPanel .stage-container .placeholder .body {
  color: #6A5CA2;
}
.AppPipelineEditPanel .stage-container .placeholder .body:hover {
  color: #330033;
}
.AppPipelineEditPanel .stage-container .placeholder .body i.fa {
  font-size: 18px;
  margin-right: 7px;
  opacity: 0.8;
  vertical-align: -1px;
}
.AppPipelineEditPanel .stage-container .placeholder .body span {
  font-size: 16px;
}

/**
 * Stage Header
 */
.AppPipelineEditPanel .stage-container .stage .header {
  background-color: white;
  padding: 3px;
  border-radius: 4px 4px 0 0;
}

/**
 * Stage Body
 */
.AppPipelineEditPanel .stage-container .stage .body {
  height: 180px;
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.AppPipelineEditPanel .stage-container .stage:last-child .body {
  border-right: 0 none;
}
.AppPipelineEditPanel .stage-container .stage .body p {
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  color: #AEACB5;
  font-size: 14px;
}

/**
 * Stage Body Controls
 */
.AppPipelineEditPanel .stage-container .stage .body .controls {
  text-align: center;
  flex-grow: 1;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #C7C6CC;
}
.AppPipelineEditPanel .stage-container .stage .body .controls .LoaderButton {
  border-width: 1px;
  width: 100%;
  padding: 8px 11px 7px;
}
.AppPipelineEditPanel .stage-container .stage .body .controls hr {
  margin: 0;
  border-color: #E2E2E5;
}

/**
 * Spacer Column
 */
.AppPipelineEditPanel .spacer-col {
  flex: 0 0 1px;
}
