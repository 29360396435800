.EnforceTwoFactorModal {
}

.EnforceTwoFactorModal .modal-header h4 {
  color: #BE2F2B;
}

.EnforceTwoFactorModal .modal-body {
  padding: 30px 30px 32px;
  display: flex;
  align-items: center;
}
.EnforceTwoFactorModal .modal-body > i.fa {
  margin-top: 5px;
  color: #BE2F2B;
  font-size: 48px;
  opacity: 0.9;
}
.EnforceTwoFactorModal .modal-body > p {
  margin-left: 20px;
  margin-bottom: 0;
  line-height: 1.6;
  color: #5D5966;
  font-size: 18px;
}
.EnforceTwoFactorModal .modal-body > p b {
  color: #2E2D33;
}
