.AppActivityWorkflowBuild {
}
.AppActivityWorkflowBuild.removed {
  opacity: 0.5;
}
.AppActivityWorkflowBuild div.build-status {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 8px 0 8px 10px;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(0, 0, 0, 0.1);
}
.AppActivityWorkflowBuild div.build-status .icon {
  font-size: 32px;
  color: #908C99;
}
.AppActivityWorkflowBuild div.build-status i.fa-times-circle.active {
  color: #A35502;
}
.AppActivityWorkflowBuild div.build-status i.fa-cog.active,
.AppActivityWorkflowBuild div.build-status i.fa-minus-circle.active,
.AppActivityWorkflowBuild div.build-status i.fa-check-circle.active {
  color: #74649A;
}
.AppActivityWorkflowBuild.completed_skipped div.build-status i.fa-check-circle {
  color: #C0BEC5;
}

.AppActivityWorkflowBuild div.build-status div.content {
  line-height: 24px;
  width: 100%;
  /* Since flexbox cannot be smaller than contents add min width.
   * This ensures that contents fit to parent and don't overflow.
   * https://stackoverflow.com/a/38224283
   */
  min-width: 0;
}

/**
 * Top Line
 */
.AppActivityWorkflowBuild div.build-status div.content div.name {
  display: flex;
  padding-left: 6px;
  padding-right: 10px;
  white-space: nowrap;
}
.AppActivityWorkflowBuild div.build-status div.content .ServiceIcon {
  margin-right: 6px;
}
.AppActivityWorkflowBuild div.build-status .id {
  line-height: 24px;
  color: #5D5966;
  overflow: hidden;
}
.AppActivityWorkflowBuild div.build-status div.content .duration {
  margin-left: 6px;
  font-size: 12px;
  color: #C7C6CC;
  line-height: 24px;
}
.AppActivityWorkflowBuild div.build-status div.content .duration i.fa {
  margin-right: 3px;
  font-size: 13px;
  opacity: 0.8;
}

.AppActivityWorkflowBuild div.build-status hr {
  margin: 6px 0 6px 1px;
  border-color: #EFEFF1;
}

/**
 * Bottom Line
 */
.AppActivityWorkflowBuild div.build-status div.info {
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #C7C6CC;
}
.AppActivityWorkflowBuild div.build-status .commit {
  margin-left: 6px;
  margin-right: 7px;
  font-size: 12px;
  vertical-align: 1px;
  color: #908C99;
  font-family: "Source Code Pro", monospace;
  border: 1px solid #E2E2E5;
  border-radius: 3px;
  padding: 1px 4px;
  line-height: 1;
}
.AppActivityWorkflowBuild div.build-status .message,
.AppActivityWorkflowBuild div.build-status .view-logs {
  font-size: 14px;
  color: #908C99;
  white-space: nowrap;
  vertical-align: 1px;
}
.AppActivityWorkflowBuild div.build-status .message:first-child,
.AppActivityWorkflowBuild div.build-status .view-logs:first-child {
  margin-left: 6px;
}
.AppActivityWorkflowBuild div.build-status.failure .view-logs {
  color: #A35502;
}
