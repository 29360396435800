.MemberPanel {
  padding: 20px 0 23px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MemberPanel .info {
  margin-right: 15px;
  flex-grow: 1;
}
.MemberPanel .info > p {
  margin-bottom: 0;
}
.MemberPanel .info > .RolesPanel .ButtonCheckbox {
  margin-top: 7px;
}
.MemberPanel .info > .MemberRoleForm {
  margin-top: 10px;
}

.MemberPanel > .controls {
  white-space: nowrap;
}
.MemberPanel > .controls .separator {
  font-size: 12px;
  color: #E2E2E5;
}
.MemberPanel > .controls button:last-child {
  padding-right: 10px;
}
