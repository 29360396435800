.CollapsableLogTextarea {
  min-height: 400px;
  border-radius: 4px;
  padding: 12px 0 14px;
  background-color: #5D5966;
  position: relative;
}
.CollapsableLogTextarea .LoaderButton {
  width: 140px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -70px;
  margin-top: -18px;
}
.CollapsableLogTextarea .LoaderButton,
.CollapsableLogTextarea .LoaderButton:hover,
.CollapsableLogTextarea .LoaderButton:active,
.CollapsableLogTextarea .LoaderButton:disabled,
.CollapsableLogTextarea .LoaderButton:active:hover,
.CollapsableLogTextarea .LoaderButton:disabled:hover {
  background-color: transparent;
  color: white;
}
.CollapsableLogTextarea > .left-margin {
  top: 0;
  left: 25px;
  position: absolute;
  width: 1px;
  background-color: white;
  opacity: 0.2;
  height: 100%;
  pointer-events: none;
}

.CollapsableLogTextarea .CollapsableLogEntry .pointer,
.CollapsableLogTextarea .CollapsableLogEntry pre:not(.error) {
  color: white;
  color: white;
}
