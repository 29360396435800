.RequestLogLine {
  padding: 7px 0;
  display: flex;
  align-items: flex-start;
}
.RequestLogLine pre {
  white-space: normal;
}
.RequestLogLine pre.formatted {
  margin-top: 7px;
  white-space: pre-wrap;
}
.RequestLogLine pre.formatted:first-child {
  margin-top: 0;
}
.RequestLogLine p {
  margin-top: 1px;
  margin-bottom: 0;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Source Code Pro", monospace;
  font-size: 12px;
  flex: 0 0 167px;
}
.RequestLogLine.error p {
  color: #D9534F;
}
.RequestLogLine pre {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0 none;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
}
.RequestLogLine .logs .divider {
  margin-left: 167px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.RequestLogLine .logs .divider:last-child {
  display: none;
}
