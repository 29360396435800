.OrgList {
}
.OrgList .org {
  margin-top: 20px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #EFEFF1;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.075),
              0 1px 1px rgba(255, 255, 255, 0.8);
}
.OrgList .org:first-child {
  margin-top: 0;
}

/**
 * Header
 */
.OrgList .org .header {
  margin: 1px;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.03),
              0 1px 2px rgba(0, 0, 0, 0.03);
  border-radius: 3px 3px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 13px 15px 15px;
}
.OrgList .org .header .info {
  margin-right: 15px;
  flex-grow: 1;
  overflow: hidden;
}
.OrgList .org .header h4 {
  margin: 0;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #908C99;
}
.OrgList .org .header h4 a {
}
.OrgList .org .header .info div {
  margin-top: 11px;
}
.OrgList .org .header .info div > a {
  padding: 4px 8px;
  display: inline-block;
  border-radius: 3px;
  background-color: #EFEFF1;
  font-size: 12px;
  color: #908C99;
}
.OrgList .org .header .info div > a:hover {
  color: #6A5CA2;
}
.OrgList .org .header .info div > a i.fa {
  margin-right: 4px;
  opacity: 0.8;
  font-size: 11px;
}
.OrgList .org .header .info div a.settings {
  margin-left: 7px;
}
.OrgList .org .header .LoaderButton {
  white-space: nowrap;
  border-width: 1px;
}
.OrgList .org .header .LoaderButton i.fa {
  margin-right: 7px;
  vertical-align: -1px;
  font-size: 16px;
  opacity: 0.9;
}

.OrgList .org ul {
  margin: 0;
  padding: 15px;
  list-style: none;
}

/**
 * New Project
 */
.OrgList .newProject {
  list-style-type: none;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.OrgList .newProject:last-child {
  margin-bottom: 0;
}
.OrgList .newProject a {
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 64px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 3px dashed #E2E2E5;
}
.OrgList .newProject a:hover {
  border-color: #C7C6CC;
}
.OrgList .newProject a .plus {
  margin-left: 6px;
}
.OrgList .newProject a .copy {
  margin-left: 7px;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/**
 * Project
 */
.OrgList .project {
  margin-bottom: 15px;
}
.OrgList .project:last-child {
  margin-bottom: 0;
}
