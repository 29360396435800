.IssuesStageSettingsPanel {
}

.IssuesStageSettingsPanel .SectionHeader {
  margin-bottom: 7px;
}
.IssuesStageSettingsPanel p.info {
  margin-bottom: 0;
  font-size: 14px;
  color: #908C99;
}

.IssuesStageSettingsPanel form {
  margin-top: 12px;
}
.IssuesStageSettingsPanel form .form-group {
  margin-bottom: 0;
}

.IssuesStageSettingsPanel p.status {
  margin: 7px 0 -1px;
  font-size: 14px;
  color: #FD9527;
}

.IssuesStageSettingsPanel .controls {
  margin-top: 20px;
}
.IssuesStageSettingsPanel .controls .TextButton {
  margin-left: 15px;
  font-size: 14px;
}
