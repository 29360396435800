.StageRegionPanel {
}

.StageRegionPanel .SectionDescriptionLabel {
  color: #5D5966;
}
.StageRegionPanel .SectionDescriptionLabel b {
  font-weight: 500;
}

.StageRegionPanel form {
}
.StageRegionPanel form .form-group {
  margin-bottom: 0;
}

.StageRegionPanel p.status {
  margin: 7px 0 -1px;
  font-size: 14px;
  color: #FD9527;
}

.StageRegionPanel .controls {
  margin-top: 20px;
}
.StageRegionPanel .controls .TextButton {
  margin-left: 15px;
  font-size: 14px;
}
