.StageIamPanel {}

.StageIamPanel .SectionDescriptionLabel.editing {}

.StageIamPanel .SectionDescriptionLabel.disabled {}

.StageIamPanel form .label-container {
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.StageIamPanel form .label-container .StyledControlLabel {
  margin-bottom: 0;
}

.StageIamPanel form .label-container .btnShowIamRolePanel {
  padding-right: 1px;
}

.StageIamPanel .btnUpdate {}

.StageIamPanel .btnDefault {
  margin-left: 10px;
}

.StageIamPanel form {}

.StageIamPanel form .help-block {
  margin-top: 7px;
  font-size: 14px;
}

.StageIamPanel form .controls {
  margin-top: 20px;
}