.OrgGitPanel {
}

.OrgGitPanel p.unavailable {
  font-size: 14px;
  color: #5D5966;
}

.OrgGitPanel span.edit {
  display: inline-block;
  line-height: 34px;
}
