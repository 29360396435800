.Login {
  padding: 20px;
}

.Login > .message-banner {
  text-align: center;
}
.Login > .message-banner .SeedAlert {
  margin-bottom: 0;
  display: inline-block;
}
@media all and (max-width: 600px) {
  .Login > .message-banner .SeedAlert {
    margin-bottom: 30px;
  }
}

.Login .LoginForm {
  margin: 60px auto;
}

@media all and (max-width: 600px) {
  .Login .LoginForm {
    margin: 0;
  }
}

.Login .SsoLoginPanel,
.Login .TwoFactorLoginForm {
  margin: 60px auto;
  max-width: 400px;
}
