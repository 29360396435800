.IssueActivityList {
}
.IssueActivityList .SectionHeader {
  margin-bottom: 15px;
  font-size: 13px;
}

.IssueActivityList ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.IssueActivityList ul li.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.IssueActivityList ul li.item .col1 {
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
}
.IssueActivityList ul li.item .UserImage {
  margin-right: 6px;
  vertical-align: 1px;
}
.IssueActivityList ul li.item span.seed {
  margin-right: 6px;
  border-radius: 3px;
  border: 1px solid #AEACB5;
  display: inline-block;
  height: 22px;
  width: 22px;
  text-align: center;
}
.IssueActivityList ul li.item span.seed i.fa {
  font-size: 11px;
  vertical-align: -1px;
  color: #AEACB5;
}
.IssueActivityList ul li.item .col1 .copy {
  margin-top: 2px;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.IssueActivityList ul li.item > span.time {
  margin-top: 1px;
  margin-left: 10px;
  color: #908C99;
  font-size: 13px;
  white-space: nowrap;
}
.IssueActivityList ul li+li.item {
  margin-top: 7px;
}

.IssueActivityList ul li.more {
  margin-top: 9px;
  margin-left: 10px;
  color: #C7C6CC;
}
