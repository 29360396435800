.NetworkStatusAlert.alert {
  margin: 0;
  color: #D9534F;
  background-color: #FDF6F6;
  border-width: 0;
  border-radius: 4px;
  font-size: 13px;
  animation: show 0.4s ease;
  padding: 0 0 0 10px;
  text-align: center;
  height: 36px;
  line-height: 36px;
  cursor: default;
  user-select: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08),
              0 2px 4px rgba(0, 0, 0, 0.09),
              0 4px 8px rgba(0, 0, 0, 0.10),
              0 16px 32px rgba(0, 0, 0, 0.11),
              0 32px 64px rgba(0, 0, 0, 0.12);
}
.NetworkStatusAlert.alert button {
  top: 0px;
  right: 0px;
  height: 36px;
  color: #D9534F;
  padding: 0 10px;
  font-size: 18px;
}
