.ItemDeletePanel {
}

.ItemDeletePanel .SectionInfo .col-info .SectionHeader {
  color: #D9534F;
}

.ItemDeletePanel .SectionInfo .col-info p.desc {
  color: #D9534F;
}

.ItemDeletePanel .SectionDescriptionLabel {
  color: #D9534F;
}

.ItemDeletePanel .btnDelete {
}

.ItemDeletePanel .helperCopy {
  font-size: 14px;
  color: #D9534F;
}

.ItemDeletePanel .checkbox {
  color: #D9534F;
  font-size: 14px;
}

.ItemDeletePanel form {
  padding-top: 5px;
}

.ItemDeletePanel .controls {
  margin-top: 20px;
}
.ItemDeletePanel .controls .TextButton {
  margin-left: 20px;
  font-size: 14px;
}

.ItemDeletePanel p.stage-busy-error {
  margin-bottom: 0;
  margin-top: 10px;
  color: #BE2F2B;
  font-size: 14px;
  animation: show 0.4s ease;
}
