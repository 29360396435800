.PolicyDisplayPanel {
  border-radius: 3px;
  border: 1px solid #C7C6CC;
  background-color: #EFEFF1;
}
.PolicyDisplayPanel .code {
  padding: 5px;
}
.PolicyDisplayPanel pre,
.PolicyDisplayPanel textarea {
  margin: 0;
  border: none;
  padding: 0;
  background: transparent;
  word-break: normal;
  word-wrap: normal;
  font-family: "Source Code Pro", monospace;
  font-size: 13px;
  width: 100%;
  height: 100%;
  resize: none;
  outline: none;
}
.PolicyDisplayPanel.collapsed pre {
  height: 150px;
}
.PolicyDisplayPanel.collapsed textarea {
  height: 150px;
}

.PolicyDisplayPanel .controls {
  border-top: 1px solid #C7C6CC;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PolicyDisplayPanel .controls .TextButton {
  font-size: 14px;
  padding: 10px 10px 9px;
  display: inline-block;
}
.PolicyDisplayPanel .controls .TextButton i.fa {
  margin-right: 6px;
  font-size: 13px;
  opacity: 0.95;
}
.PolicyDisplayPanel .controls .status {
  margin-left: 5px;
  font-size: 14px;
  color: #FD9527;
}
.PolicyDisplayPanel .controls .separator {
  margin: 0 3px;
  color: #908C99;
}
.PolicyDisplayPanel .controls .status.copied {
  margin-right: 5px;
}
