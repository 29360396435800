.OrgsUpgradeList {
  margin: 0;
  padding: 0;
  border-radius: 3px;
  overflow: hidden;
  list-style: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
              0 1px 2px rgba(0, 0, 0, 0.05),
              0 2px 3px rgba(0, 0, 0, 0.05);
}

.OrgsUpgradeList a {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}
.OrgsUpgradeList a:hover {
  background-color: #EFEFF1;
}
.OrgsUpgradeList li+li a {
  border-top: 1px solid #EFEFF1;
}

/**
 * Left Col
 */
.OrgsUpgradeList a > span:first-child {
  margin-right: 30px;
  overflow: hidden;
}
.OrgsUpgradeList a > span:first-child .name {
  display: block;
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.OrgsUpgradeList a > span:first-child .cost {
  padding-top: 5px;
  display: block;
  color: #908C99;
}

/**
 * Right Col
 */
.OrgsUpgradeList a > span:last-child {
  color: #AEACB5;
  font-size: 14px;
  white-space: nowrap;
}
.OrgsUpgradeList a > span:last-child i.fa {
  margin-left: 7px;
}
