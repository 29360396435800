.MiniActivityItem {
  display: flex;
  align-items: center;
}

.MiniActivityItem .actor {
  height: 22px;
}
.MiniActivityItem .UserImage {
  vertical-align: 1px;
}

/**
 * Body
 */
.MiniActivityItem > .body {
  flex-grow: 1;
  margin: 1px 0 0 5px;
  font-size: 14px;
  color: #5D5966;
  display: flex;
  align-items: baseline;
  min-width: 0;
}
.MiniActivityItem > .body span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.MiniActivityItem > .body b {
  font-weight: 500;
  color: #2E2D33;
}
.MiniActivityItem > .body a.commit {
  padding: 1px 5px;
  color: #908C99;
  border: 1px solid rgba(0,0,0,.07);
  border-radius: 3px;
  font-family: "Source Code Pro", monospace;
  font-size: 12px;
}
.MiniActivityItem > .body a.build {
  margin-left: 4px;
  font-size: 12px;
}
.MiniActivityItem > .body a.build i.fa {
  color: #74649A;
}
.MiniActivityItem > .body a.build i.fa-times {
  color: #A35502;
}
.MiniActivityItem > .body a.build i.fa-minus {
  color: #AEACB5;
}

/**
 * Status
 */
.MiniActivityItem > .status {
  margin-top: -1px;
  margin-left: 10px;
  white-space: nowrap;
}
.MiniActivityItem > .status span {
  color: #AEACB5;
  font-size: 12px;
}
.MiniActivityItem > .status a:hover span {
  color: #330033;
}
