.AppIamPanel {}

.AppIamPanel form .label-container {
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AppIamPanel form .label-container .StyledControlLabel {
  margin-bottom: 0;
}

.AppIamPanel form .label-container .btnShowIamRolePanel {
  padding-right: 1px;
}

.AppIamPanel form {}

.AppIamPanel form .help-block {
  margin-top: 7px;
  font-size: 14px;
}

.AppIamPanel form .controls {
  margin-top: 20px;
}

.AppIamPanel .btnShow {}