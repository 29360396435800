.NewIamRoleModal {}

.NewIamRoleModal .modal-body {
  padding: 20px;
}

.NewIamRoleModal .steps {
  margin-bottom: 30px;
  padding-left: 18px;
  font-size: 14px;
}

.NewIamRoleModal .steps:last-child {
  margin-bottom: 0;
}

.NewIamRoleModal .steps>li:not(:last-child) {
  margin-bottom: 25px;
}

.NewIamRoleModal .steps>li p {
  line-height: 1.5;
}

.NewIamRoleModal .steps>li:last-child p {
  margin-bottom: 0;
}

.NewIamRoleModal .steps>li p b {
  font-weight: 500;
}

.NewIamRoleModal .steps>li>.LoaderButton {
  margin: 4px 0 15px;
}

.NewIamRoleModal .PolicyComponent {}

.NewIamRoleModal .PolicyComponent.collapsed.serverless-policy pre {
  height: 190px;
}

.NewIamRoleModal .EditPolicyComponent {
  border-radius: 3px;
  border: 1px solid #C7C6CC;
  background-color: white;
}

.NewIamRoleModal .EditPolicyComponent.error {
  border-color: #A94442;
}

.NewIamRoleModal .EditPolicyComponent .form-group {
  margin-bottom: 0;
  height: 200px;
}

.NewIamRoleModal .EditPolicyComponent textarea {
  margin: 0;
  resize: none;
  border: none;
  padding: 5px;
  background: transparent;
  word-break: normal;
  word-wrap: normal;
  height: 190px;
  font-family: "Source Code Pro", monospace;
  font-size: 13px;
  line-height: 1.42857143;
}

.NewIamRoleModal .EditPolicyComponent .controls {
  border-top: 1px solid #C7C6CC;
  background-color: #EFEFF1;
  padding: 10px;
  border-radius: 0 0 3px 3px;
}

.NewIamRoleModal .EditPolicyComponent.error .controls {
  border-color: #A94442;
}

.NewIamRoleModal .EditPolicyComponent .help-block {
  margin-top: 0;
  margin-bottom: 8px;
  color: #A94442;
}

.NewIamRoleModal .EditPolicyComponent .controls .TextButton {
  margin-left: 10px;
}