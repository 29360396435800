.Logs {
  padding: 20px;
}

.Logs .PermissionErrorPanel {
  margin-bottom: 15px;
}

/**
 * Title Bar
 */
.Logs .title-bar {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  height: 46px;
}
.Logs .title-bar .ResourceSearchButton {
  margin-right: 10px;
  flex: 1 1 auto;
}
.Logs .title-bar .LogFilterSelect {
  margin-right: 10px;
}
.Logs .title-bar .LogDurationSelect {
}
.Logs .title-bar .single-request-info {
  flex: 0 0 auto;
  align-self: stretch;
}
.Logs .title-bar .single-request-info .LoaderButton {
  border: 1px solid #E2E2E5;
  padding: 6px 15px 5px 16px;
  height: 100%;
}
.Logs .title-bar .single-request-info .LoaderButton:hover {
  border-color: #CCCCCC;
}
.Logs .title-bar .single-request-info .LoaderButton i.fa {
  margin-left: 2px;
}

/**
 * Error Sign
 */
.Logs .error-invalid {
  margin: 0 auto;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.Logs .error-invalid p {
  margin-top: 15px;
  color: #908C99;
}
.Logs .error-invalid p a {
  font-weight: 500;
}
