.UserImage {
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  background: linear-gradient(350deg, #CFCED4 0%, #E2E2E5 100%);
  line-height: 1;
}
.UserImage.size-xsmall {
  border-radius: 3px;
}

.UserImage.initials {
}
.UserImage.initials div {
  text-align: center;
}
.UserImage.initials div span {
  text-transform: uppercase;
  font-weight: 500;
  color: #908C99;
  user-select: none;
  vertical-align: middle;
  position: relative;
}
.UserImage.src.initials:hover div span {
  color: #6A5CA2;
}
.UserImage div span.glyphicon {
  top: 0;
}

.UserImage img {
  display: inline-block;
}
.UserImage.src.inactive img {
  filter: grayscale(100%);
}
.UserImage.src.inactive:hover img {
  filter: none;
}

.UserImage.size-xsmall img,
.UserImage.size-xsmall div {
  width: 22px;
  height: 22px;
}
.UserImage.size-small img,
.UserImage.size-small div {
  width: 32px;
  height: 32px;
}
.UserImage.size-medium img,
.UserImage.size-medium div {
  width: 44px;
  height: 44px;
}
.UserImage.size-large img,
.UserImage.size-large div {
  width: 66px;
  height: 66px;
}

.UserImage.size-xsmall span {
  font-size: 10px;
  line-height: 22px;
}
.UserImage.size-small span {
  font-size: 15px;
  line-height: 32px;
}
.UserImage.size-medium span {
  font-size: 20px;
  line-height: 44px;
}
.UserImage.size-large span {
  font-size: 30px;
  line-height: 66px;
}
