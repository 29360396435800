.ServiceButtonGroup {
  display: inline-block;
}

.ServiceButtonGroup .service {
  font-size: 18px;
  display: inline-block;
}
.ServiceButtonGroup .service .ServiceIcon {
  margin-right: 7px;
}
