.PipelineTableStageCell {
  background-color: white;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PipelineTableStageCell div.title {
  margin-right: 10px;
  min-width: 0;
}
.PipelineTableStageCell div.title .name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.PipelineTableStageCell div.title .name i.fa-cog {
  margin-right: 5px;
  vertical-align: -1px;
  opacity: 0.9;
  font-size: 16px;
}
.PipelineTableStageCell .desc {
  margin-top: 1px;
  font-weight: normal;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #908C99;
}
.PipelineTableStageCell .desc a {
  color: #908C99;
}
.PipelineTableStageCell .desc span {
  color: #C7C6CC;
}
.PipelineTableStageCell .desc i.fa {
  margin-right: 3px;
  opacity: 0.9;
}


.PipelineTableStageCell.deleting .title {
  opacity: 0.5;
}

.PipelineTableStageCell.deleting .icon {
  margin-right: 6px;
  font-size: 13px;
  vertical-align: -1px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
.PipelineTableStageCell.delete_failed i.fa {
  margin-right: 4px;
  font-size: 15px;
}
.PipelineTableStageCell span.desc a {
  color: #908C99;
}
.PipelineTableStageCell span.desc a:hover {
  color: #5D5966;
}

.PipelineTableStageCell .btnCancel {
  border-width: 1px;
  padding: 5px 10px 4px;
}
