.PricingPlansPanel {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 3px;
}

.PricingPlansPanel li {
  padding: 12px 0;
  display: flex;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.PricingPlansPanel li.disabled {
  cursor: not-allowed;
}
.PricingPlansPanel li:last-child {
  border-bottom: 0 none;
}

.PricingPlansPanel li > div:first-child {
  margin-left: 16px;
  margin-right: 16px;
  width: 14px;
  height: 14px;
  background-color: #e2e2e5;
  border-radius: 7px;
  align-self: center;
}
.PricingPlansPanel li.disabled > div:first-child {
  background-color: #efeff1;
}
.PricingPlansPanel li.selected > div:first-child {
  background-color: #fd9527;
}

.PricingPlansPanel li > div:last-child p:first-child {
  margin-bottom: 5px;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.PricingPlansPanel li.disabled > div:last-child p:last-child,
.PricingPlansPanel li.disabled > div:last-child p:first-child .TextButton {
  color: #aeacb5;
}
.PricingPlansPanel li > div:last-child p:last-child {
  margin-bottom: 0;
  font-size: 14px;
  color: #908c99;
}
