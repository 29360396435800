.ErrorComponent {
  padding-top: 120px;
  text-align: center;
}
.ErrorComponent p {
  margin-top: 15px;
}
.ErrorComponent p:last-child {
  margin-top: 5px;
}
.ErrorComponent p a {
  font-weight: 500;
}

