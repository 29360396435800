.StagePostDeployModal {
}

.StagePostDeployModal form .form-group:last-child {
  margin-bottom: 0;
}

.StagePostDeployModal .modal-footer .TextButton {
  margin-right: 15px;
  padding: 10px;
  font-size: 14px;
}
