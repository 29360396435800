.CurrentUsagePanel {
  display: flex;
}

/**
 * Minutes
 */
.CurrentUsagePanel .minutes {
  flex: 0 0 calc(3 * 140px + 2 * 3px);
}
.CurrentUsagePanel .minutes .header {
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 20px;
}
.CurrentUsagePanel .minutes .header p {
  margin-bottom: 0;
  font-size: 14px;
  color: #908C99;
}
.CurrentUsagePanel .minutes .header .SectionHeader {
  margin-bottom: 0;
  margin-top: 3px;
}

.CurrentUsagePanel .minutes .tiles {
  display: flex;
}
.CurrentUsagePanel .minutes .tiles > div {
  margin-right: 3px;
  background-color: #EFEFF1;
  padding: 12px 12px 10px;
  flex: 0 0 140px;
  height: 72px;
}
.CurrentUsagePanel .minutes .tiles > div:first-child {
  border-radius: 3px 0 0 3px;
}
.CurrentUsagePanel .minutes .tiles > div .SectionHeader {
  margin-bottom: 12px;
}
.CurrentUsagePanel .minutes .tiles > div p {
  margin-bottom: 0;
  font-size: 18px;
}
.CurrentUsagePanel .minutes .tiles > div:last-child .SectionHeader {
  color: #C7C6CC;
}
.CurrentUsagePanel .minutes .tiles > div:last-child p {
  color: #908C99;
}
.CurrentUsagePanel .minutes .tiles > div.warning {
  background-color: #F4E7E7;
  color: #BE2F2B;
}
.CurrentUsagePanel .minutes .tiles > div.warning .SectionHeader {
  color: #D37876;
}

/**
 * Amount
 */
.CurrentUsagePanel .amount {
  margin-left: 3px;
  flex: 1 1 auto;
  text-align: right;
}
.CurrentUsagePanel .amount .SectionHeader {
  margin-top: 3px;
  margin-bottom: 3px;
  height: 17px;
}
.CurrentUsagePanel .amount div {
  border: 1px solid #DDD;
  border-radius: 0 3px 3px 0;
  height: 72px;
  padding: 0 12px;
}
.CurrentUsagePanel .amount div p {
  margin-bottom: 0;
  line-height: 72px;
  font-size: 32px;
  color: #5D5966;
}
.CurrentUsagePanel .amount div p span {
  margin-right: 2px;
  vertical-align: 6px;
  line-height: 1;
  font-size: 21px;
}
