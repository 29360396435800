.StagesPostDeployPanel {
  border: 1px solid #DBDADE;
  border-radius: 4px;
}
.StagesPostDeployPanel > .stage {
  padding: 30px 20px;
  display: flex;
  align-items: center;
}
.StagesPostDeployPanel > .stage + .stage {
  border-top: 1px solid #DBDADE;
}
.StagesPostDeployPanel > .stage .name {
  margin-right: 20px;
  flex: 0 0 335px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
