.RolesPanel {
}
.RolesPanel .ButtonCheckbox {
  margin-right: 5px;
}
.RolesPanel .ButtonCheckbox:last-child {
  margin-right: 0;
}
.RolesPanel .ButtonCheckbox.app {
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
  line-height: 22px;
  font-weight: 500;
  text-transform: none;
  font-family: Rubik, sans-serif;
}
