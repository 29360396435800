.StageBranchModal {
}

.StageBranchModal form .form-group {
  margin-bottom: 0;
}

.StageBranchModal .modal-footer .TextButton {
  margin-right: 15px;
  padding: 10px;
  font-size: 14px;
}
