.CustomDomainsList {
}
.CustomDomainsList .domain {
  margin-bottom: 10px;
  padding: 13px 15px 15px;
  border-radius: 3px;
  background-color: #EFEFF1;
}
.CustomDomainsList .domain:last-child {
  margin-bottom: 25px;
}

/**
 * Info
 */
.CustomDomainsList .domain .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CustomDomainsList .domain .info .col1 {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}
.CustomDomainsList .domain .info .col1 h4 {
  margin: 0 0 8px;
  color: #2E2D33;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.CustomDomainsList .domain .info .col1 h6 {
  margin: 0;
  color: #908C99;
  font-size: 14px;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.CustomDomainsList .domain .info .col1 h6 span {
  padding-right: 6px;
}
.CustomDomainsList .domain .info .col1 h6 span i.fa {
  margin-left: 2px;
  opacity: 0.7;
}
.CustomDomainsList .domain .info .col1 h6 a {
  color: #908C99;
}
.CustomDomainsList .domain .info .col1 h6 a:hover {
  color: #5D5966;
}
.CustomDomainsList .domain .info .col2 {
  margin-left: 15px;
}
.CustomDomainsList .domain .info .col2 .LoaderButton {
  padding-right: 0;
}

/**
 * Status
 */
.CustomDomainsList .domain .status {
  margin-top: 15px;
  padding-top: 25px;
  padding-bottom: 10px;
  border-top: 1px solid #E2E2E5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CustomDomainsList .domain .status .copy {
  font-size: 15px;
}
.CustomDomainsList .domain .status.pending .copy {
  color: #908C99;
  display: flex;
  align-items: flex-start;
}
.CustomDomainsList .domain .status .copy .glyphicon {
  margin-top: 2px;
  margin-right: 8px;
  vertical-align: -1px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
.CustomDomainsList .domain .status.failed .copy {
  color: #D9534F;
}
.CustomDomainsList .domain .status.failed .controls {
  margin-left: 15px;
}
.CustomDomainsList .domain .status.failed .controls .LoaderButton {
}
