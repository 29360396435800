.StageRemoveServiceTable {
  overflow-x: auto;
}

.StageRemoveServiceTable > .content {
  display: flex;
}

/**
 * Services
 */
.StageRemoveServiceTable .services {
  flex-basis: auto;
  flex-grow: 1;
  min-width: 200px;
  max-width: 400px;
}
.StageRemoveServiceTable .services .service {
  padding: 18px 8px 19px 18px;
  display: flex;
  border-style: solid;
  border-color: #DDD;
  border-width: 1px 0 0 1px;
}
.StageRemoveServiceTable .services .service:first-child {
  border-radius: 3px 0 0 0;
}
.StageRemoveServiceTable .services .service:last-child {
  border-bottom: 1px solid #DDD;
  border-radius: 0 0 0 3px;
}
.StageRemoveServiceTable .services .service .PipelineTableServiceCell {
  overflow: hidden;
  width: 100%;
}

/**
 * Errors
 */
.StageRemoveServiceTable .errors {
  flex: 1 0 auto;
  width: 400px;
}
.StageRemoveServiceTable .errors .error {
  border-style: solid;
  border-color: #DDD;
  border-width: 1px 1px 0 1px;
  display: flex;
  align-items: center;
  height: 78px;
  padding: 0 15px;
  font-size: 14px;
}
.StageRemoveServiceTable .errors .error > div {
  width: 100%;
}
.StageRemoveServiceTable .errors .error p.copy {
  margin-bottom: 3px;
  color: #BE2F2B;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.StageRemoveServiceTable .errors .error a i {
  font-size: 13px;
}
.StageRemoveServiceTable .errors .error:first-child {
  border-radius: 0 3px 0 0;
}
.StageRemoveServiceTable .errors .error:last-child {
  border-bottom: 1px solid #DDD;
  border-radius: 0 0 3px 0;
  height: 79px;
}
