.CaretIcon {
  display: block;
	border-style: solid;
	height: 0;
	width: 0;
}
.CaretIcon.down {
  border-color: #908C99 transparent;
	border-width: 5px 5px 0 5px;
}
.CaretIcon.up {
  border-color: #908C99 transparent;
	border-width: 0 5px 5px 5px;
}
.CaretIcon.left {
  border-color: transparent #908C99;
	border-width: 5px 5px 5px 0;
}
.CaretIcon.right {
  border-color: transparent #908C99;
	border-width: 5px 0 5px 5px;
}
