.OrgAddPanel {
}

.OrgAddPanel .newOrg {
  padding: 0 12px;
  display: inline-block;
  height: 40px;
  box-sizing: border-box;
  color: #6A5CA2;
  border: 1px solid #E2E2E5;
}
.OrgAddPanel .newOrg i.fa {
  font-size: 16px;
  vertical-align: 0;
}
.OrgAddPanel .newOrg .copy {
  margin-left: 8px;
  font-size: 15px;
  vertical-align: 1px;
}

.OrgAddPanel form {
  padding: 0;
  display: flex;
}

.OrgAddPanel .validation {
  padding-top: 7px;
  font-size: 14px;
  color: #FC6020;
}

.OrgAddPanel form .form-group {
  flex-grow: 1;
  margin-right: 5px;
}
.OrgAddPanel form .form-group input {
  height: 40px;
  font-size: 17px;
  padding: 0 11px;
  width: 100%;
}

.OrgAddPanel form .LoaderButton {
  padding: 0 15px;
  font-size: 16px;
  height: 40px;
  vertical-align: 1px;
}
.OrgAddPanel form .btnAdd {
  margin-left: 10px;
}

.OrgAddPanel form .btnCancel {
  margin-left: 10px;
}
