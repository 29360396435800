.KeyMetricsGraph {
  position: relative;
}

.KeyMetricsGraph svg {
  cursor: pointer;
}

.KeyMetricsGraph .tip {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.KeyMetricsGraph .tip .duration {
  margin: 1px 0 0 0;
  color: #C7C6CC;
  font-size: 10px;
  line-height: 1;
}
.KeyMetricsGraph .tip .duration span {
  white-space: nowrap;
}
.KeyMetricsGraph .tip .duration span.hour {
  margin-left: 4px;
}
.KeyMetricsGraph .tip .value {
  margin: 0;
  font-family: "Source Code Pro", monospace;
  font-size: 11px;
  color: #908C99;
  vertical-align: baseline;
  line-height: 1;
}
.KeyMetricsGraph .tip .value .unit {
  margin-left: 2px;
}
