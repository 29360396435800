.StyledButtonGroup {
}

/**
 * Links
 */
.StyledButtonGroup a,
.StyledButtonGroup button {
  border-color: #AEACB5;
  background-color: transparent;
  color: #5D5966;
}
.StyledButtonGroup a:hover,
.StyledButtonGroup button:hover {
  border-color: #AEACB5;
  color: #2E2D33;
}
.StyledButtonGroup a:first-child,
.StyledButtonGroup button:first-child {
  border-radius: 3px 0 0 3px;
}
.StyledButtonGroup a:last-child,
.StyledButtonGroup button:last-child {
  border-radius: 0 3px 3px 0;
}
.StyledButtonGroup a.active,
.StyledButtonGroup button.active {
  background-color: #DBDADE;
  box-shadow: none;
  border-color: #AEACB5;
  color: #2E2D33;
}
.StyledButtonGroup a.active:hover,
.StyledButtonGroup button.active:hover {
  border-color: #AEACB5;
  background-color: #DBDADE;
}

/**
 * LoaderButton
 */
.StyledButtonGroup .LoaderButton {
  border-width: 1px;
}
