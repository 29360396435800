.AppIssues {
  padding: 20px;
}


.AppIssues .title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AppIssues .title .col2 {
  margin-left: 30px;
  flex: 0 0 auto;
}
.AppIssues .title .LoaderButton.help-link {
  margin-right: 10px;
  line-height: 34px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  color: #908C99;
}
.AppIssues .title .LoaderButton.help-link:hover {
  color: #6A5CA2;
}
.AppIssues .title .LoaderButton.help-link i.fa {
  margin: 0 4px 0 0;
  min-width: 13px;
  opacity: 0.9;
}
