.StyledControlLabel {
  margin-bottom: 10px;
  display: block;
  font-family: "Exo 2", sans-serif;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #908C99;
  line-height: 1.1;
}
