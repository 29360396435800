.BillingHistoryTable {
}

.BillingHistoryTable thead > tr > th {
  font-family: "Exo 2", sans-serif;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #908C99;
}

.BillingHistoryTable > thead:first-child > tr:first-child > th {
  border-width: 0;
  padding: 0 0 5px;
}
.BillingHistoryTable > tbody > tr:first-child > td {
  border-width: 0;
}
.BillingHistoryTable > tbody > tr > td {
  background-color: #EFEFF1;
  border-color: #F9F9FA;
  padding: 15px 12px;
  color: #2E2D33;
}
.BillingHistoryTable > tbody > tr:first-child > td:first-child {
  border-radius: 3px 0 0;
}
.BillingHistoryTable > tbody > tr:first-child > td:last-child {
  border-radius: 0 3px 0 0;
}
.BillingHistoryTable > tbody > tr:last-child > td:first-child {
  border-radius: 0 0 0 3px;
}
.BillingHistoryTable > tbody > tr:last-child > td:last-child {
  border-radius: 0 0 3px 0;
}
.BillingHistoryTable > tbody > tr > td:first-child {
  font-size: 14px;
  color: #908C99;
}

.BillingHistoryTable tr th:last-child,
.BillingHistoryTable tr td:last-child {
  text-align: right;
}
.BillingHistoryTable tr td:nth-child(2) {
  font-size: 14px;
}
.BillingHistoryTable tr td:last-child {
  font-size: 16px;
}
.BillingHistoryTable tr td:last-child span {
  margin-right: 2px;
  font-size: 12px;
  vertical-align: 2px;
}
