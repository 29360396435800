.AppPipeline {
  padding: 20px;
}

.AppPipeline .AppPipelineInfoPanel {
  margin-bottom: 15px;
}

/**
 * Cell Header
 */
.AppPipeline .info-panels .cell h4 {
  margin: 0 0 10px;
  padding-top: 1px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #DADEE2;
}
.AppPipeline .info-panels .cell h4 span {
  font-size: 16px;
  font-weight: 500;
}
.AppPipeline .info-panels .cell h4 a {
  font-size: 13px;
  font-weight: normal;
}

.AppPipeline .info-panels .cell.git .repo {
  margin-bottom: 15px;
}
.AppPipeline .info-panels .cell.git .repo i.fa {
  margin-right: 7px;
  color: #908C99;
  font-size: 22px;
  vertical-align: -4px;
}
.AppPipeline .info-panels .cell.git .setting {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AppPipeline .info-panels .cell.git .setting:last-child {
  margin-bottom: 0;
}
.AppPipeline .info-panels .cell.git .setting p {
  margin: 0;
  font-size: 14px;
  color: #5D5966;
}
.AppPipeline .info-panels .cell.git .setting .SectionHeader {
  margin: 0;
}
.AppPipeline .info-panels .cell.git .setting .SectionHeader.on {
  color: #FD9527;
}

/**
 * Deploy Phases
 */
.AppPipeline .info-panels .cell.deploy-phases > p {
  margin: 0;
  color: #5D5966;
  font-size: 14px;
}
.AppPipeline .info-panels .cell.deploy-phases > p b {
  color: #2E2D33;
  font-weight: 500;
}
