.MemberRoleModal {
}

.MemberRoleModal .modal-body {
  padding: 0;
}

/**
 * Role Panel
 */
.MemberRoleModal .role-panel {
  border-radius: 3px;
}


.MemberRoleModal .role-panel .roles {
  padding-top: 13px;
  border-radius: 0 0 3px 3px;
}

.MemberRoleModal .role-panel .roles .SectionHeader {
  margin: 0;
  padding: 0 15px 12px;
}

.MemberRoleModal .role-panel .role {
  margin-bottom: 15px;
  padding: 0 15px 15px;
  border-bottom: 1px solid #E2E2E5;
}
.MemberRoleModal .role-panel .role.admin,
.MemberRoleModal .role-panel .role.billing {
  display: flex;
  align-items: center;
}
.MemberRoleModal .role-panel .role.admin,
.MemberRoleModal .role-panel .role.prod {
  margin-bottom: 13px;
  border-color: #DBDADE;
}
.MemberRoleModal .role-panel .role.prod:last-child {
  border-bottom: none;
}
.MemberRoleModal .role-panel .role.billing {
  border-bottom: none;
}
.MemberRoleModal .role-panel .role.member .summary {
  display: flex;
  align-items: flex-start;
}
.MemberRoleModal .role-panel .role:last-child {
  margin-bottom: 0;
}

.MemberRoleModal .role-panel .role .check {
  flex: 0 0 210px;
}
.MemberRoleModal .role-panel .role p {
  margin-bottom: 0;
  font-size: 14px;
  color: #AEACB5;
}
.MemberRoleModal .role-panel .role p b {
  font-weight: 500;
}
.MemberRoleModal .role-panel .role.checked p {
  color: #2E2D33;
}
.MemberRoleModal .role-panel.disabled .role p {
  color: #AEACB5;
}

.MemberRoleModal .role-panel .role.member {
}
.MemberRoleModal .role-panel .role.member .details {
  margin: 5px 0 0 210px;
  border-radius: 3px;
  max-width: 450px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.MemberRoleModal .role-panel .role.member .details .check {
  margin-right: 10px;
  flex: 0 0 auto;
}
.MemberRoleModal .role-panel .role.member .details .check .ButtonCheckbox {
  border-width: 1px;
  font-size: 9px;
}

.MemberRoleModal .role-panel .restricted-copy {
  margin: 20px 15px 15px;
  font-size: 14px;
  color: #2E2D33;
}
.MemberRoleModal .role-panel .restricted {
  opacity: 0.5;
}

.MemberRoleModal .modal-footer .TextButton {
  margin-right: 15px;
  padding: 10px;
  font-size: 14px;
}

