.IssueInfoPanel {
}

.IssueInfoPanel .SectionHeader {
  font-size: 14px;
  color: #AEACB5;
}

/**
 * Status
 */
.IssueInfoPanel .status {
  margin-bottom: 25px;
  border-radius: 4px;
  padding: 12px 15px 11px;
}
.IssueInfoPanel .status.resolved {
  background-color: #DDEBDF;
}
.IssueInfoPanel .status.muted {
  background-color: rgba(217, 83, 79, 0.2);
}
.IssueInfoPanel .status div {
  font-size: 14px;
  color: #5D5966;
}
.IssueInfoPanel .status > div {
  display: flex;
}
.IssueInfoPanel .status > div .col2 {
  flex: 1 1 auto;
}
.IssueInfoPanel .status div i.fa-check-square {
  margin-top: 2px;
  margin-right: 7px;
  color: #9BB0AF;
}
.IssueInfoPanel .status div i.fa-bell-slash-o {
  margin-top: 3px;
  margin-right: 7px;
  color: #D9534F;
  opacity: 0.9;
  font-size: 13px;
}
.IssueInfoPanel .status.muted p.report {
  margin-top: 7px;
  margin-bottom: 0;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.IssueInfoPanel .status.muted p.report span {
  opacity: 0.5;
}
.IssueInfoPanel .status.muted .LoaderButton {
  margin-right: 7px;
  border-width: 1px;
  padding: 2px 6px 1px;
  font-size: 11px;
}
.IssueInfoPanel .status.muted .LoaderButton i.fa {
  margin-right: 5px;
  font-size: 10px;
  opacity: 0.9;
}
.IssueInfoPanel .status.muted .LoaderButton.loading .glyphicon {
  margin-right: 6px;
  font-size: 9px;
  vertical-align: 1px;
}

/**
 * Message
 */
.IssueInfoPanel .message {
  margin-bottom: 25px;
}
.IssueInfoPanel .message p {
  margin-bottom: 0;
  font-size: 20px;
  font-family: "Source Code Pro", monospace;
  word-wrap: break-word;
  line-height: 1.4;

  display: block; /** Fallback **/
  display: -webkit-box;
  max-height: 112px; /** Fallback $font-size * $line-height * $lines-to-show **/
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
}
.IssueInfoPanel .message.empty p {
  color: #AEACB5;
}
.IssueInfoPanel .message .LoaderButton {
  margin-top: 5px;
  padding: 0;
}
.IssueInfoPanel .message .LoaderButton .glyphicon {
  margin-right: 5px;
}
.IssueInfoPanel .message .LoaderButton i.fa {
  margin-right: 3px;
}

/**
 * Helper
 */
.IssueInfoPanel .helper {
  margin-bottom: 31px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 15px;
	background: #E2E2E5;
	border-radius: 4px;
}
.IssueInfoPanel .helper:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-bottom-color: #E2E2E5;
	border-top: 0;
	margin-left: -10px;
	margin-top: -10px;
}
.IssueInfoPanel .helper .SectionHeader {
  margin-bottom: 11px;
}
.IssueInfoPanel .helper .SectionHeader i.fa {
  margin-right: 5px;
}
.IssueInfoPanel .helper p {
  margin: 0;
  color: #5D5966;
  font-size: 15px;
  line-height: 1.6;
}
.IssueInfoPanel .helper p a i.fa-angle-right {
  margin-left: 1px;
  font-size: 14px;
}

/**
 * Tags
 */
.IssueInfoPanel .tags {
  margin-bottom: 31px;
}
.IssueInfoPanel .list {
  margin-left: -10px;
  margin-top: -10px;
}
.IssueInfoPanel .tag {
  margin-top: 10px;
  margin-left: 10px;
  padding: 1px;
  font-size: 13px;
  border: 1px solid #DBDADE;
  border-radius: 3px;
  display: inline-block;
}
.IssueInfoPanel .tag .key {
  padding: 0 8px;
  color: #908C99;
  display: inline-block;
}
.IssueInfoPanel .tag .value{
  padding: 0 9px;
  line-height: 28px;
  background: #EFEFF1;
  font-family: "Source Code Pro", monospace;
  display: inline-block;
}

/**
 * Stack Trace
 */
.IssueInfoPanel .stack-trace {
}
.IssueInfoPanel .stack-trace pre {
  margin: 0;
  padding: 13px 15px 15px;
  background-color: #EFEFF1;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  color: #2E2D33;
}
.IssueInfoPanel .stack-trace.empty pre {
  color: #AEACB5;
  font-size: 18px;
}
