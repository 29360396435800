.AppIssueDetails {
  padding: 20px 20px 0;
}

.AppIssueDetails .ScreenHeader {
  margin-bottom: 0;
}

.AppIssueDetails > .cols {
  display: flex;
}
.AppIssueDetails > .cols > .col-1 {
  flex: 1 1 auto;
  padding: 17px 25px 20px 0;
  min-width: 0;
}
.AppIssueDetails > .cols > .col-2 {
  padding: 17px 0 20px 25px;
  border-left: 1px solid #DBDADE;
  flex: 0 0 340px;
  width: 340px;
}

.AppIssueDetails .col-1 > .SectionHeader {
  font-size: 14px;
  color: #AEACB5;
}
.AppIssueDetails > .cols > .col-2 .IssueContextPanel {
  margin-bottom: 30px;
}
