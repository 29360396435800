.ItemRemoveStatusPanel {
}

.ItemRemoveStatusPanel .status {
  font-size: 20px;
  color: #5D5966;
}
.ItemRemoveStatusPanel .status .glyphicon-refresh {
  margin-right: 8px;
  vertical-align: -1px;
  font-size: 18px;
  animation: spin 1s infinite linear;
}
.ItemRemoveStatusPanel.deleting p.copy {
  margin-top: 5px;
  font-size: 14px;
  color: #908C99;
}

.ItemRemoveStatusPanel.delete_failed {
}
.ItemRemoveStatusPanel.idle .status,
.ItemRemoveStatusPanel.delete_failed .status {
  color: #BE2F2B;
}
.ItemRemoveStatusPanel.delete_failed .status {
  line-height: 30px;
}
.ItemRemoveStatusPanel.delete_failed .status i.fa {
  margin-right: 7px;
  font-size: 18px;
}
.ItemRemoveStatusPanel.delete_failed .status .LoaderButton {
  margin-left: 10px;
  padding: 4px 8px 3px;
  vertical-align: 3px;
  border-width: 1px;
}

.ItemRemoveStatusPanel.delete_failed .retry {
  margin-top: 8px;
}
.ItemRemoveStatusPanel.delete_failed .retry hr {
  margin-top: 12px;
  margin-bottom: 14px;
}
.ItemRemoveStatusPanel.delete_failed .retry p {
  font-size: 14px;
  line-height: 1.65;
  color: #5D5966;
}
.ItemRemoveStatusPanel.delete_failed .retry .controls {
}
.ItemRemoveStatusPanel.delete_failed .retry .controls .LoaderButton {
  margin-right: 10px;
}
.ItemRemoveStatusPanel.delete_failed .retry .controls .TextButton {
  margin-left: 5px;
  font-size: 14px;
}
