.OrgGitModal {
}

.OrgGitModal form .form-group:last-child {
  margin-bottom: 0;
}
.OrgGitModal form .form-group .help-block {
  font-size: 14px;
}

.OrgGitModal .modal-footer .TextButton {
  margin-right: 15px;
  padding: 10px;
  font-size: 14px;
}
