.LoginSsoCallback {
  padding: 20px;
}

.LoginSsoCallback .username-form {
  margin: 60px auto;
  max-width: 400px;
}

.LoginSsoCallback .ScreenHeader {
  margin-bottom: 15px;
}
.LoginSsoCallback > h4 {
  margin: 0 0 10px;
  font-weight: normal;
  color: #908C99;
}


.LoginSsoCallback .username-form p {
  margin-bottom: 30px;
}

.LoginSsoCallback .error {
  padding-top: 120px;
  text-align: center;
}
.LoginSsoCallback .error p {
  margin-top: 15px;
}
