.PipelineDropdownButton {
}
.PipelineDropdownButton .dropup button,
.PipelineDropdownButton .dropdown button {
}
.PipelineDropdownButton .dropup .dropdown-menu,
.PipelineDropdownButton .dropdown .dropdown-menu {
  overflow: hidden;
  padding: 0;
  max-width: 190px;
}
.PipelineDropdownButton .dropup .dropdown-menu li.divider,
.PipelineDropdownButton .dropdown .dropdown-menu li.divider {
  margin: 0;
}
.PipelineDropdownButton .dropup .dropdown-menu a,
.PipelineDropdownButton .dropdown .dropdown-menu a {
  color: #5D5966;
  padding-top: 8px;
  padding-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.PipelineDropdownButton .dropup .dropdown-menu a:focus,
.PipelineDropdownButton .dropdown .dropdown-menu a:focus {
  outline: none;
}
.PipelineDropdownButton .dropup .dropdown-menu .disabled a,
.PipelineDropdownButton .dropdown .dropdown-menu .disabled a {
  color: #908C99;
}
