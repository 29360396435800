.GitHubInstallCallback {
  padding: 20px;
}
.GitHubInstallCallback > .content {
  padding-top: 90px;
  padding-bottom: 75px;
  text-align: center;
}
.GitHubInstallCallback h2 {
  line-height: 1.5;
  font-size: 28px;
}
.GitHubInstallCallback p {
  margin-top: 15px;
}
