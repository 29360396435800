.GraphTooltip {
  display: inline-block;
  padding: 7px 0 6px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06),
              0 2px 2px rgba(0, 0, 0, 0.07),
              0 4px 4px rgba(0, 0, 0, 0.08);
}

.GraphTooltip hr {
  margin: 4px 0px 5px;
  border-color: #EFEFF1;
}
.GraphTooltip p.values {
  padding: 0 10px;
  margin-bottom: 3px;
}
.GraphTooltip p.values:last-child {
  margin-bottom: 0;
}
.GraphTooltip p.values .StyledControlLabel {
  margin-right: 6px;
  margin-bottom: 0;
  font-size: 12px;
  display: inline;
}
.GraphTooltip p.values span.value {
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  color: #2E2D33;
  vertical-align: baseline;
}
.GraphTooltip p.values span.unit {
  margin-left: 4px;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  color: #908C99;
  vertical-align: baseline;
}

.GraphTooltip div.footer {
  padding: 0 10px;
}
.GraphTooltip div.footer p:first-child {
  margin-bottom: 1px;
  color: #908C99;
  font-size: 12px;
}
.GraphTooltip div.footer p:last-child {
  margin-bottom: 0;
  font-size: 11px;
  color: #C7C6CC;
}
