.AppActivityList {
}

.AppActivityList .load-more {
  width: 100%;
  color: #908C99;
}
.AppActivityList .load-more:hover {
  color: #5D5966;
}
