.WebsiteResourceList {
}

.WebsiteResourceList > .website {
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
              0 1px 2px rgba(0, 0, 0, 0.04),
              0 2px 4px rgba(0, 0, 0, 0.03);
}
.WebsiteResourceList > .website:last-child {
  margin-bottom: 0;
}

.WebsiteResourceList > .website > div.header {
  padding: 15px 15px 0;
}
.WebsiteResourceList > .website > div.header h4 {
  margin: 0;
  font-size: 20px;
}

.WebsiteResourceList > .website ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.WebsiteResourceList > .website ul.endpoint .ResourceItem {
  padding-top: 10px;
}
