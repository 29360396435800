.UserSettings {
  padding: 20px 20px 40px;
}

.UserSettings .ScreenHeader {
  margin-bottom: 33px;
}

.UserSettings hr {
  margin: 45px 0 30px;
}

.UserSettings .VerifyEmailForm,
.UserSettings .UpdatePasswordForm,
.UserSettings .UpdateEmailForm,
.UserSettings .DeleteUserPanel form {
  max-width: 500px;
}

.UserSettings p.current.email {
  font-size: 14px;
  color: #5d5966;
}

.UserSettings p.current {
  font-size: 14px;
  color: #5d5966;
}
.UserSettings p.current.error {
  font-size: 14px;
  color: #d9534f;
}
.UserSettings p.current.updated {
  color: #fd9527;
}
.UserSettings p.current.error .TextButton {
  font-weight: 500;
  color: #d9534f;
}
.UserSettings p.current .TextButton:hover {
  opacity: 0.8;
}

.UserSettings .update-email .SectionHeader {
  margin-bottom: 15px;
}

.UserSettings .DeleteUserPanel {
}
