.OAuthCallback {
  padding: 20px;
}
.OAuthCallback > .content {
  padding-top: 90px;
  padding-bottom: 75px;
  text-align: center;
}
.OAuthCallback h2 {
  line-height: 1.5;
  font-size: 28px;
}
.OAuthCallback p {
  margin-top: 15px;
}
