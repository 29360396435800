.AuditLogList {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background-color: #EFEFF1;
  border: 1px solid #DADEE2;
}

.AuditLogList table {
  margin: 0;
}

/**
 * Header
 */
.AuditLogList .flex-table .header {
  display: flex;
  color: #908C99;
  padding: 15px;
}
.AuditLogList .flex-table .header .SectionHeader {
  margin: 0;
}
.AuditLogList .flex-table .header .SectionHeader:nth-child(1) {
  flex: 1 1 auto;
}
.AuditLogList .flex-table .header .SectionHeader:nth-child(2) {
  margin-left: 30px;
  flex: 0 0 200px;
  text-align: left;
}
.AuditLogList .flex-table .header .SectionHeader:nth-child(3) {
  margin-left: 30px;
  flex: 0 0 105px;
  text-align: center;
}
.AuditLogList .flex-table .header .SectionHeader:nth-child(4) {
  margin-left: 30px;
  flex: 0 0 130px;
  text-align: center;
}

/**
 * Rows
 */
.AuditLogList .flex-table .item {
  border-top: 1px solid #DADEE2;
  padding: 15px;
  color: #2E2D33;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.AuditLogList .flex-table .item > div {
}
.AuditLogList .flex-table .item > div+div {
  margin-left: 30px;
}

/**
 * Avatar
 */
.AuditLogList .flex-table .item .avatar {
  flex: 0 0 32px;
  cursor: default;
}
.AuditLogList .flex-table .item .git-icon {
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  line-height: 1;
  background: linear-gradient(350deg, #CFCED4 0%, #E2E2E5 100%);
  text-align: center;
  width: 32px;
  height: 32px;
}
.AuditLogList .flex-table .item .git-icon .fa {
  color: #5D5966;
  user-select: none;
  vertical-align: middle;
  position: relative;
  font-size: 20px;
  line-height: 32px;
}
/**
 * Description
 */
.AuditLogList .flex-table .item .description {
  margin-left: 15px;
  flex: 1 1 auto;
}
.AuditLogList .flex-table .item .description .email {
  margin-bottom: 3px;
  font-weight: 500;
  color: #2E2D33;
}
.AuditLogList .flex-table .item .description .copy {
  margin-bottom: 1px;
  color: #5D5966;
}
.AuditLogList .flex-table .item .description .params {
  margin-bottom: 0;
  font-size: 13px;
  color: #908C99;
}
/**
 * Action
 */
.AuditLogList .flex-table .item .action {
  flex: 0 0 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
/**
 * IP
 */
.AuditLogList .flex-table .item .ip {
  flex: 0 0 105px;
  text-align: center;
}
.AuditLogList .flex-table .item .ip.empty {
  color: #AEACB5;
}
/**
 * Time
 */
.AuditLogList .flex-table .item .time {
  flex: 0 0 140px;
  text-align: right;
}
.AuditLogList .flex-table .item .time {
  color: #908C99;
}

/**
 * Foter
 */
.AuditLogList .footer {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #DADEE2;
}
.AuditLogList .footer .LoaderButton {
  border-width: 1px;
}
.AuditLogList .footer .LoaderButton i.fa {
  opacity: 0.9;
  font-size: 14px;
}
.AuditLogList .footer .LoaderButton i.fa-angle-left {
  margin-right: 5px;
}
.AuditLogList .footer .LoaderButton i.fa-angle-right {
  margin-left: 5px;
}

/**
 * Loading Sign
 */
.AuditLogList .loading {
  border-top: 1px solid #DADEE2;
}
.AuditLogList .LoadingSpinner .spinner {
  margin: 109px auto;
}

/**
 * Empty Sign
 */
.AuditLogList > .empty {
  border-top: 1px solid #DADEE2;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AuditLogList > .empty p {
  color: #908C99;
  font-size: 20px;
}

/**
 * Upgrade Sign
 */
.AuditLogList .upgrade-sign {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  background: linear-gradient(0deg, #FFF 55%, rgba(255, 255, 255, 0));
}
.AuditLogList .upgrade-sign div {
  margin-bottom: 40px;
}
.AuditLogList .upgrade-sign p {
  margin-bottom: 20px;
  color: #5D5966;
  font-size: 16px;
}
