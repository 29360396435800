.SsoLoginPanel {
  text-align: center;
}

.SsoLoginPanel h4 {
  margin-bottom: 30px;
}

.SsoLoginPanel .LoaderButton:last-child {
  margin-top: 5px;
}
