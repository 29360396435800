.AppPrPanel {
}

.AppPrPanel span.edit {
  display: inline-block;
  line-height: 34px;
}
.AppPrPanel span.current {
  color: #5D5966;
  opacity: 0.5;
}
