.AppUnitTestPanel {
}
.AppUnitTestPanel .SectionHeader {
}

.AppUnitTestPanel p.current {
  font-size: 14px;
  color: #5D5966;
}
.AppUnitTestPanel p.current.disabled {
  opacity: 0.5;
}
