.AppPipelineTable {
  border-radius: 3px;
  background-color: #EFEFF1;
}

.AppPipelineTable.empty {
  text-align: center;
  padding: 80px 10px;
}
.AppPipelineTable.empty p {
  margin-bottom: 20px;
  color: #908C99;
  font-size: 20px;
}
.AppPipelineTable.empty .LoaderButton {
  text-align: right;
  padding: 9px 11px;
}
.AppPipelineTable.empty .LoaderButton:hover {
}
.AppPipelineTable.empty .LoaderButton i.fa {
  margin-right: 7px;
  opacity: 0.8;
  font-size: 16px;
}

/**
 * Header
 */
.AppPipelineTable > .header {
  padding: 10px 10px 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #DADEE2;
}
.AppPipelineTable > .header h4 {
  color: #5D5966;
}
.AppPipelineTable > .header .controls .LoaderButton:last-child {
  margin-left: 10px;
  border-width: 1px;
  padding: 7px 15px 6px;
  background-color: #E2E2E5;
}
.AppPipelineTable > .header .controls .LoaderButton:first-child {
  border-width: 1px;
  padding: 7px 15px 6px;
}

.AppPipelineTable .pipelinetable-content {
  display: flex;
}

/**
 * Column 1
 */
.AppPipelineTable .pipelinetable-content > .col1 {
  border-right: 1px solid #DADEE2;
  flex: 0 0 220px;
  max-width: 220px;
}
.AppPipelineTable .pipelinetable-content > .col1 .header {
  padding-top: 19px;
  padding-left: 15px;
  height: 177px;
}
.AppPipelineTable .pipelinetable-content > .col1 .header .SectionHeader {
  margin-bottom: 11px;
}

.AppPipelineTable .pipelinetable-content > .col1 .header a,
.AppPipelineTable .pipelinetable-content > .col1 .header .TextButton {
  font-size: 15px;
  display: block;
}
.AppPipelineTable .pipelinetable-content > .col1 .header .TextButton {
  margin-top: 15px;
  color: #FD9527;
}
.AppPipelineTable .pipelinetable-content > .col1 .header a i.fa,
.AppPipelineTable .pipelinetable-content > .col1 .header .TextButton i.fa {
  margin-right: 8px;
  opacity: 0.8;
  font-size: 14px;
}
.AppPipelineTable .pipelinetable-content > .col1 .header .TextButton i.fa {
  color: #FD9527;
  transition: .3s;
}
.AppPipelineTable .pipelinetable-content > .col1 .header .TextButton:hover,
.AppPipelineTable .pipelinetable-content > .col1 .header .TextButton:hover i.fa {
  color: #DE8222;
}
.AppPipelineTable .pipelinetable-content > .col1 .header a:last-child {
  margin-bottom: 0;
}
.AppPipelineTable .pipelinetable-content > .col1 hr {
  margin: 13px 15px 15px 0;
}
.AppPipelineTable .pipelinetable-content > .col1 .setting {
  margin-top: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AppPipelineTable .pipelinetable-content > .col1 .setting:nth-child(3) {
  margin-top: 20px;
}
.AppPipelineTable .pipelinetable-content > .col1 .setting.disabled {
  opacity: 0.5;
}
.AppPipelineTable .pipelinetable-content > .col1 .setting span:first-child {
  font-size: 14px;
  color: #908C99;
}
.AppPipelineTable .pipelinetable-content > .col1 .setting .SectionHeader {
  margin: 0;
  font-size: 12px;
  color: #AEACB5;
}
.AppPipelineTable .pipelinetable-content > .col1 .setting.on .SectionHeader {
  color: #5D5966;
}

/**
 * Services
 */
.AppPipelineTable .services {
}
.AppPipelineTable .services .SectionHeader {
  margin: 0 15px 10px;
  display: block;
  height: 13px;
  color: #AEACB5;
}
.AppPipelineTable .services .service {
  padding-top: 22px;
  padding-left: 15px;
  padding-right: 2px;
  border-top: 1px solid #DADEE2;
  height: 65px;
}
.AppPipelineTable .services .service:nth-child(1) {
  margin-top: 11px;
  padding-top: 25px;
  height: 68px;
}
.AppPipelineTable .services .service:last-child {
  border-bottom: 1px solid #DADEE2;
  height: 69px;
}
.AppPipelineTable .services .PipelineTableServiceCell {
}
.AppPipelineTable .services .PipelineTableServiceCell:after {
  background: linear-gradient(to right, rgba(226, 226, 229, 0), #EFEFF1 90%);
}

/**
 * Column 2
 */
.AppPipelineTable .pipelinetable-content > .ScrollShadowContainer {
  flex-grow: 1;
  min-width: 340px;
}
.AppPipelineTable .pipelinetable-content > .ScrollShadowContainer .col2 {
  padding: 20px 0 30px 30px;
  display: flex;
  flex-grow: 1;
}

/**
 * Stage Container
 */
.AppPipelineTable .stage-container {
  margin-right: 20px;
  flex-grow: 1;
  flex-basis: auto;
  min-width: 260px;
  max-width: 400px;
}
.AppPipelineTable .stage-container.more {
  margin-right: 30px;
}
.AppPipelineTable .stage-container:nth-last-child(2) {
  margin-right: 0;
}
.AppPipelineTable .stage-container > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}
.AppPipelineTable .stage-container > .header .SectionHeader {
  margin-bottom: 0;
  line-height: 18px;
  font-size: 16px;
  color: #AEACB5;
}
.AppPipelineTable .stage-container > .header .glyphicon {
  margin-right: 2px;
  color: #C7C6CC;
  line-height: 1;
  font-size: 18px;
}

/**
 * Spacer Column
 */
.AppPipelineTable .col2 .spacer-col {
  flex: 0 0 20px;
}

/**
 * Stage
 */
.AppPipelineTable .stage-container .stage {
  background-color: #F9F9FA;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.08),
              0 6px 6px rgba(0,0,0,0.05),
              0 10px 20px rgba(0,0,0,0.06);
}

/**
 * Stage Header
 */
.AppPipelineTable .stage-container .stage .header {
  background-color: white;
  padding: 3px;
  border-radius: 4px 4px 0 0;
}
.AppPipelineTable .stage-container .stage .header hr {
  margin: 1px 12px 3px;
  border-color: #E2E2E5;
  opacity: 0.9;
}
.AppPipelineTable .stage-container .stage .header .PipelineTableStageStatusCell {
  padding: 12px;
  box-sizing: border-box;
  height: 63px;
}

/**
 * Stage Service
 */
.AppPipelineTable .stage-container .body {
  padding: 15px 15px 15px 20px;
}
.AppPipelineTable .stage-container .stage-service {
  padding-bottom: 11px;
  margin-left: 10px;
  margin-bottom: 11px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  height: 54px;
}
.AppPipelineTable .stage-container .stage-service:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.AppPipelineTable .stage-container .stage-service .PipelineTableBuildCell {
  padding: 10px 0 10px 0;
  height: 42px;
  box-sizing: border-box;
}

/**
 * Stage Footer
 */
.AppPipelineTable .stage-container .stage .footer {
  text-align: center;
  padding: 0 5px 5px 5px;
}
.AppPipelineTable .stage-container.prod .stage .footer {
  padding: 0 0 1px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}
.AppPipelineTable .stage-container .stage .footer .LoaderButton {
  font-size: 14px;
  display: block;
  width: 100%;
  background-color: white;
  border-radius: 2px;
  border: none;
  color: #5D5966;
  padding: 14px 10px 12px;
}
.AppPipelineTable .stage-container .stage .footer .LoaderButton:not(:disabled):hover {
  color: #330033;
}
.AppPipelineTable .stage-container .stage .footer .PipelineTableProdStatusCell {
  margin: 0 auto;
  padding: 0 15px;
  line-height: 49px;
}
