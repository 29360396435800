.MetricsPanel {
}

.MetricsPanel .title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MetricsPanel .title .SectionHeader {
  margin-bottom: 0;
}
.MetricsPanel .title .legend {
  margin-left: 30px;
  flex: 0 0 auto;
}
.MetricsPanel .title .legend .TextButton {
  font-size: 12px;
  color: #5D5966;
}
.MetricsPanel .title .legend .TextButton+.TextButton {
  margin-left: 20px;
}
.MetricsPanel .title .legend .TextButton.deselected {
  opacity: 0.5;
  cursor: pointer;
}
.MetricsPanel .title .legend .TextButton.deselected:hover {
  opacity: 1;
}
.MetricsPanel .title .legend .TextButton span {
  margin-right: 5px;
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 3px;
  vertical-align: -1px;
  border-width: 1px;
  border-style: solid;
}
.MetricsPanel .title .legend .TextButton.deselected span {
  background-color: transparent !important;
}

.MetricsPanel .metrics .metric:not(:last-child) {
  margin-bottom: 15px;
}
