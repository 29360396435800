.OrgTokenPanel {
}

.OrgTokenPanel > .SectionHeader {
}

.OrgTokenPanel .SectionDescriptionLabel {
}

.OrgTokenPanel .token {
  padding: 15px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background-color: #efeff1;
}
.OrgTokenPanel .token input {
  margin-right: 15px;
  flex: 1 1 auto;
  min-width: 250px;
  text-align: left;
  padding: 0;
  display: block;
  border: none;
  background: transparent;
  color: #908c99;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OrgTokenPanel .token input:focus {
  outline: none;
}
.OrgTokenPanel .token > div:last-child span.copied-status {
  margin-right: 8px;
  font-size: 12px;
  color: #fd9527;
}

.OrgTokenPanel .controls {
  margin-top: 20px;
}
