.ContainerErrorPanel {
  padding: 75px 0 60px;
  text-align: center;
}
.ContainerErrorPanel p {
  margin-top: 15px;
}
.ContainerErrorPanel p:last-child {
  margin-top: 5px;
}
.ContainerErrorPanel p a {
  font-weight: 500;
}
