.NewAppErrorPanel {
  font-size: 14px;
  border: 2px solid #D43F3A;
  border-radius: 3px;
  padding: 15px 15px 18px 5px;
  display: flex;
}
.NewAppErrorPanel p {
  margin-bottom: 7px;
}
.NewAppErrorPanel ul {
  margin-bottom: 0;
  padding-left: 20px;
}
.NewAppErrorPanel ul li {
  margin-top: 5px;
}
.NewAppErrorPanel .icon {
  flex: 0 0 50px;
  text-align: center;
}
.NewAppErrorPanel .icon .fa {
  margin-top: 2px;
  font-size: 28px;
  color: #A35502;
}


