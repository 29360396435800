.AccessLogFieldsPanel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.AccessLogFieldsPanel .field {
  margin-top: 2px;
  display: flex;
  align-items: center;
  padding: 10px 12px 8px;
  flex: 0 0 50%;
  background-color: #F9F9FA;
  overflow: hidden;
}
.AccessLogFieldsPanel .field:nth-child(2n) {
  border-left: 2px solid white;
}
.AccessLogFieldsPanel .field:nth-child(1) {
  margin-top: 0;
  border-top-left-radius: 3px;
}
.AccessLogFieldsPanel .field:nth-child(2) {
  margin-top: 0;
  border-top-right-radius: 3px;
}
.AccessLogFieldsPanel .field:nth-last-child(2) {
  border-bottom-left-radius: 3px;
}
.AccessLogFieldsPanel .field:nth-last-child(1) {
  border-bottom-right-radius: 3px;
}
.AccessLogFieldsPanel .field:last-child:nth-child(odd) {
  flex: 0 0 100%;
  border-bottom-left-radius: 3px;
}
.AccessLogFieldsPanel .field:last-child:nth-child(odd):first-child {
  border-top-right-radius: 3px;
}

/**
 * Key Value
 */
.AccessLogFieldsPanel .field .SectionHeader {
  margin-bottom: 0;
  margin-right: 10px;
  flex: 0 0 175px;
  font-size: 12px;
  color: #908C99;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.AccessLogFieldsPanel .field .value {
  margin-bottom: 0;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
