.AppSettings {
  padding: 20px;
}

.AppSettings .issues.current {
  font-size: 14px;
  color: #5D5966;
}

.AppSettings > .cols {
  display: flex;
}
.AppSettings > .cols > .col-1 {
  flex: 1 1 auto;
}
.AppSettings > .cols > .col-2 {
  margin-left: 15px;
  flex: 0 0 250px;
}

/**
 * Settings Links
 */
.AppSettings > .cols > .col-2 .SettingsLinksList {
}
.AppSettings > .cols > .col-2 .SettingsLinksList+.SettingsLinksList {
  margin-top: 30px;
}

.AppSettings .AppNameForm .col-body {
  max-width: 500px;
}
.AppSettings .AppIamPanel .col-body,
.AppSettings .ItemDeletePanel .col-body,
.AppSettings .TransferAppPanel .col-body {
  max-width: 600px;
}

.AppSettings .SectionGroupPanel {
  margin-bottom: 45px;
}
.AppSettings .SectionGroupPanel:last-child {
  margin-bottom: 0;
}
.AppSettings .SectionGroupPanel hr {
  margin: 45px 0;
}
