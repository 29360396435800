.StripeCardForm {
}

.StripeCardForm > h4 {
  margin: 0 0 15px;
  color: #5d5966;
}

.StripeCardForm .help-block {
  margin-bottom: 20px;
  color: #a94442;
  font-size: 16px;
}

/**
 * Invoice Billing
 */
.StripeCardForm .invoice-details {
  padding: 15px;
  border-radius: 4px;
  background-color: #efeff1;
}
.StripeCardForm .invoice-details h5 {
  margin: 0 0 15px;
  font-size: 16px;
  color: #5d5966;
}
.StripeCardForm .invoice-details p {
  margin-bottom: 5px;
  font-size: 14px;
  color: #5d5966;
}
.StripeCardForm .invoice-details p b {
  font-weight: 500;
}
.StripeCardForm .invoice-details > a {
  font-size: 13px;
}

/**
 * Form Fields
 */
.StripeCardForm.paid form .fields {
  padding: 15px;
  border-radius: 4px;
  background-color: #efeff1;
}
.StripeCardForm .card-field {
  background-color: white;
  padding: 11px 0 11px 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.StripeCardForm .card-field.StripeElement--focus {
  border-color: #9e92ba;
}

/**
 * Controls
 */
.StripeCardForm .controls {
  margin-top: 30px;
}

.StripeCardForm .controls .btnHide {
  margin-left: 15px;
  font-size: 14px;
  display: inline-block;
}
