.CollapsableLogEntry {
}

.CollapsableLogEntry.secondary {
  opacity: 0.6;
}

.CollapsableLogEntry .log {
  padding-right: 12px;
  display: flex;
  align-items: flex-start;
}
.CollapsableLogEntry .log:not(.empty):hover {
  background-color: #6e6a77;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.CollapsableLogEntry .log:not(.empty) {
}

.CollapsableLogEntry .pointer {
  padding: 0 0 0 12px;
  flex: 0 0 34px;
  transition: opacity 0.3s ease-out;
  min-height: 20px;
  opacity: 0.5;
}
.CollapsableLogEntry .pointer:hover {
  opacity: 0.9;
}
.CollapsableLogEntry .log .pointer.expanded {
  padding-left: 10px;
}
.CollapsableLogEntry .log .pointer .fa {
  margin-top: 3px;
  font-size: 15px;
  line-height: 1;
  display: block;
}
.CollapsableLogEntry .log .pointer.expanded .fa {
  margin-top: 2px;
}

.CollapsableLogEntry .log .duration {
  margin-top: 1px;
  margin-left: 10px;
  line-height: 18px;
  padding: 0 6px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  font-family: "Source Code Pro", monospace;
  font-size: 10px;
  color: #c7c6cc;
}

.CollapsableLogEntry .log pre {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0 none;
  border-radius: 0;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  flex: 1;
}

.CollapsableLogEntry .log pre.command {
  padding-left: 17px;
  text-indent: -17px;
}
.CollapsableLogEntry .log pre.error {
  color: #d9534f;
  font-weight: bold;
}
