.ResetPwConfirmForm {
}

.ResetPwConfirmForm .help-block {
  margin-top: 7px;
  font-size: 14px;
}
.ResetPwConfirmForm .form-group:first-child .help-block {
  color: #908C99;
}

.ResetPwConfirmForm .controls {
  margin-top: 30px;
}
