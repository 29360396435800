.GitBranchForm {
}

/**
 * Form Control
 */
.GitBranchForm .form .form-group {
  margin-bottom: 5px;
}
.GitBranchForm .form:last-child .form-group {
  margin-bottom: 20px;
}

/**
 * List
 */
.GitBranchForm .recently-deployed {
  margin-bottom: 20px;
  padding-top: 7px;
}
.GitBranchForm .recently-deployed .list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-top: -7px;
}
.GitBranchForm .recently-deployed .list .SectionHeader {
  margin-left: 7px;
  margin-top: 7px;
  margin-bottom: 0;
  color: #aeacb5;
  font-size: 11px;
}
.GitBranchForm .recently-deployed .list .LoaderButton {
  margin-left: 7px;
  margin-top: 7px;
  border-radius: 15px;
  font-size: 13px;
  padding: 6px 15px;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
