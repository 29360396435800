.RequestDemo {
  padding: 20px;
}

.RequestDemo .RequestDemoForm {
  margin: 60px auto;
  max-width: 400px;
}

.RequestDemo .complete {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
.RequestDemo .complete p {
  font-size: 18px;
}
.RequestDemo .complete p:first-child {
  font-size: 50px;
  color: #FD9527;
}
