.DomainPathControl {
}

.DomainPathControl .error-message {
  margin-bottom: 5px;
  color: #D9534F;
  font-size: 14px;
}

/**
 * Field
 */
.DomainPathControl div.field {
  background-color: white;
  border: 1px solid #CCC;
  border-radius: 3px;
  color: #555;
  display: flex;
  align-items: center;
}
.DomainPathControl div.field div.form-group {
  display: block;
}

.DomainPathControl div.field div.form-group:first-child input {
  width: 140px;
  text-align: right;
}

.DomainPathControl div.field div.form-group.separator {
  color: #908C99;
  font-size: 16px;
}
.DomainPathControl div.field div.form-group.separator.period span {
  font-weight: 500;
  vertical-align: 2px;
}

.DomainPathControl div.field div.form-group.domain {
  flex-grow: 1;
}
.DomainPathControl div.field div.form-group.domain select {
  line-height: normal;
  width: 100%;
}
.DomainPathControl div.field div.form-group.domain select:disabled {
  color: #908C99;
}

.DomainPathControl div.field div.form-group:nth-child(3) {
}
.DomainPathControl div.field div.form-group:last-child input {
  width: 140px;
}
.DomainPathControl div.field input,
.DomainPathControl div.field select {
  border: 0 none;
  appearance: none;
  background: transparent;
}

/**
 * Controls
 */
.DomainPathControl div.controls {
  margin-top: 15px;
}
.DomainPathControl div.controls .btnDisable {
  margin-left: 10px;
}
.DomainPathControl div.controls .btnHide {
  margin-left: 15px;
  display: inline-block;
  font-size: 14px;
}
