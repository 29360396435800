.OrgSettings {
  padding: 20px;
}

.OrgSettings .ScreenHeader {
  margin-bottom: 33px;
}

.OrgSettings > hr {
  margin: 45px 0;
}

.OrgSettings .OrgNameForm .col-body {
  max-width: 500px;
}

.OrgSettings p.current.error {
  font-size: 14px;
  color: #D9534F;
}
.OrgSettings p.current.error a {
  font-weight: 500;
  color: #D9534F;
  cursor: pointer;
}
.OrgSettings p.current a:hover {
  opacity: 0.9;
}

.OrgSettings .SectionGroupPanel {
  margin-top: 45px;
}
