.NewApp {
  padding: 25px 20px 20px;
}

.NewApp .ScreenHeader {
  margin-bottom: 15px;
}

.NewApp > h4 {
  margin: 0 0 10px;
  font-weight: normal;
  color: #908C99;
}

.NewApp .forms-container {
  padding: 45px 0 10px 30px;
}
.NewApp .forms-container .SectionInfo.repo {
  margin-bottom: 52px;
}
.NewApp .forms-container .SectionInfo.service {
  margin-bottom: 45px;
}

.NewApp .SectionInfo.inactive .col-info {
  opacity: 0.35;
}
.NewApp .SectionInfo .col-info .SectionHeader {
  font-size: 18px;
  font-family: Rubik, sans-serif;
  color: #5D5966;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 500;
}
.NewApp .SectionInfo .col-info p.desc {
  margin-top: 7px;
  margin-left: 19px;
}

.NewApp .NewAppRepoForm,
.NewApp .NewAppConfigForm,
.NewApp .NewAppDetectServicePanel {
  max-width: 700px;
}
