.AppListPanel {
  display: flex;
  padding: 13px 15px;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
.AppListPanel div.icon img {
  padding: 2px 6px;
  background-color: black;
  border-radius: 3px;
  box-sizing: border-box;
}
.AppListPanel div.content {
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

/**
 * Content Col 1
 */
.AppListPanel div.content .col1 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 15px;
  overflow: hidden;
}
.AppListPanel div.content a:first-child,
.AppListPanel div.content span:first-child {
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.AppListPanel div.content a:last-child,
.AppListPanel div.content > .col1 > span:last-child {
  padding-top: 1px;
  font-size: 14px;
  color: #908C99;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.AppListPanel div.content a:last-child:hover {
  color: #5D5966;
}
.AppListPanel div.content a:last-child:active {
  color: #A29FAA;
}

.AppListPanel.deleting {
  opacity: 0.7;
  box-shadow: none;
}
.AppListPanel div.content > .col1 > span:last-child {
}
.AppListPanel div.content > .col1 > a:last-child i.fa,
.AppListPanel div.content > .col1 > a:last-child .glyphicon {
  margin-right: 5px;
  vertical-align: 0;
  font-size: 12px;
}
.AppListPanel div.content > .col1 > a:last-child .icon {
  animation: spin 1s infinite linear;
}
.AppListPanel.delete_failed div.content > .col1 > a:last-child {
  color: #BE2F2B;
}

/**
 * Content Col 1
 */
.AppListPanel div.content .col2 {
  text-align: right;
  flex-shrink: 0;
}
.AppListPanel div.content .col2 p {
  margin-bottom: 0;
  font-size: 14px;
  color: #908C99;
}
.AppListPanel div.content .col2 p:first-child {
  margin-bottom: 3px;
  line-height: 23px;
  color: #C7C6CC;
}
.AppListPanel div.content .col2 p.empty {
  color: #C7C6CC;
}

