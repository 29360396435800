.OrgMembersPanel {
}

.OrgMembersPanel .members {
  margin-bottom: 30px;
}

.OrgMembersPanel .members .members-table {
}
.OrgMembersPanel .members .members-table > .header {
  padding: 15px 10px;
  background-color: #EFEFF1;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  color: #908C99;
  letter-spacing: 1px;
  border-radius: 3px;
}
.OrgMembersPanel .members .members-table .MemberPanel {
  border-bottom: 1px solid #E2E2E5;
}
.OrgMembersPanel .members .members-table:first-child:not(:last-child) .MemberPanel:last-child {
  border-bottom: none;
}

.OrgMembersPanel > .info-copy {
  margin-bottom: 15px;
  font-size: 14px;
}
.OrgMembersPanel > .info-copy.warning {
  color: #BE2F2B;
}
.OrgMembersPanel > .info-copy.warning a {
  font-weight: 500;
}

.OrgMembersPanel .NewMemberForm {
}
