.MiniPipelineTable {
}
.MiniPipelineTable > h4 {
  margin: 0;
  padding-top: 1px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #DADEE2;
}
.MiniPipelineTable > h4 span {
  font-size: 16px;
  font-weight: 500;
}
.MiniPipelineTable > h4 > span a {
  margin-left: 7px;
  font-size: 13px;
  font-weight: normal;
}
.MiniPipelineTable > h4 > a {
  font-size: 13px;
  font-weight: normal;
}
.MiniPipelineTable .list {
  display: flex;
  padding-bottom: 10px;
}

/** Show scollbar if below a certain size **/
@media all and (max-width: 955px) {
  .MiniPipelineTable.count-2 .list {
    overflow: auto;
  }
}
@media all and (max-width: 1120px) {
  .MiniPipelineTable.count-3 .list {
    overflow: auto;
  }
}

.MiniPipelineTable .list .part {
  margin-top: 10px;
  margin-right: 10px;
  flex: 1 1 33%;
  max-width: 280px;
  min-width: 220px;
}
.MiniPipelineTable.count-2 .list .part {
  flex: 1 1 50%;
}
.MiniPipelineTable .list .part:last-child {
  margin-right: 0;
}
.MiniPipelineTable .list .part > .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3px 9px 1px;
}
.MiniPipelineTable .list .part > .title .SectionHeader {
  font-size: 12px;
  margin-bottom: 0;
  line-height: 18px;
  color: #AEACB5;
}
.MiniPipelineTable .list .part > .title .glyphicon {
  color: #C7C6CC;
  line-height: 1;
  font-size: 16px;
}
.MiniPipelineTable .list .part > div.body {
  display: flex;
  flex-direction: column;
}
.MiniPipelineTable .MiniPipelineStageCell {
  width: 100%;
}
.MiniPipelineTable .MiniPipelineStageCell+.MiniPipelineStageCell {
  margin-top: 10px;
}
