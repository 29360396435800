.NewIamRoleHelpButton.btn {
  padding: 0;
  border: 0;
  font-size: 14px;
}

.NewIamRoleHelpButton i.fa {
  margin-right: 5px;
}

.NewIamRoleHelpButton .glyphicon {
  margin-right: 5px;
  font-size: 12px;
}