.ResourceSearchButton {
  border-radius: 3px;
  border: 1px solid #E2E2E5;
  background-color: white;
  padding: 9px 12px 8px 9px;
  color: #908C99;
  cursor: pointer;
  user-select: none;
  position: relative;
}
.ResourceSearchButton.disabled {
  cursor: default;
  opacity: 0.5;
  background-color: white;
}
.ResourceSearchButton:focus {
  outline: none;
}
.ResourceSearchButton i.fa {
  margin-right: 5px;
  font-size: 14px;
  color: #AEACB5;
  vertical-align: 1px;
  pointer-events: none;
}

/**
 * Logs mode
 */
.ResourceSearchButton.logs {
  padding: 14px 12px 13px 12px;
  height: 46px;
  line-height: 1.1;
}
.ResourceSearchButton.logs span {
  color: #2E2D33;
}
.ResourceSearchButton.logs .CaretIcon {
  margin-bottom: 0;
  margin-top: -1px;
  right: 12px;
  top: 50%;
  position: absolute;
  pointer-events: none;
}
