.ManualDeployModal {
}

.ManualDeployModal .modal-title .separator {
  padding: 0 6px;
  color: #9BB0AF;
}

.ManualDeployModal .modal-body .form > .form-group.force-option {
  margin-bottom: 5px;
}

/**
 * Default Branch
 */
.ManualDeployModal .form .default-branch {
  margin-bottom: 15px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  border-radius: 3px;
  border: 1px solid #E2E2E5;
}
.ManualDeployModal .form .default-branch .branch {
  margin-right: 15px;
  display: flex;
  align-items: center;
  min-width: 0;
}
.ManualDeployModal .form .default-branch .branch p.name {
  margin: 0 5px 0 0;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ManualDeployModal .form .default-branch .branch p.name i.fa {
  margin-right: 6px;
  font-size: 17px;
  vertical-align: -1px;
  opacity: 0.9;
}
.ManualDeployModal .form .default-branch .branch p.copy {
  margin: 1px 0 0;
  font-size: 13px;
  color: #C7C6CC;
  white-space: nowrap;
}
.ManualDeployModal .form .default-branch .TextButton {
  font-size: 13px;
  white-space: nowrap;
}

/**
 * Branch Select
 */
.ManualDeployModal .modal-body .GitBranchSelect {
  margin-bottom: 15px;
}

.ManualDeployModal .form .form-group .checkbox {
  font-size: 14px;
  margin-bottom: 0;
}
.ManualDeployModal .form .form-group .help-block {
  margin-top: 4px;
  margin-left: 21px;
  color: #908C99;
  margin-bottom: 0;
  font-size: 14px;
}
.ManualDeployModal .form .form-group .help-block code {
  background-color: #EFEFF1;
  color: #908C99;
}

.ManualDeployModal .SectionDescriptionLabel b {
  font-weight: 500;
}

.ManualDeployModal .modal-footer .TextButton {
  margin-right: 15px;
  padding: 10px;
  font-size: 14px;
}
