.MemberRoleForm {
}

/**
 * Add Member Form
 */
.MemberRoleForm form.add-member {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
}
.MemberRoleForm form.add-member .form-group:first-child {
  margin-right: 10px;
  flex-grow: 1;
}
.MemberRoleForm form.add-member .form-group:first-child input {
  width: 100%;
}
.MemberRoleForm form.add-member .help-block {
  margin-top: 7px;
  margin-bottom: 0;
  font-size: 14px;
}
.MemberRoleForm form.add-member button {
  margin-left: 10px;
}

/**
 * Header
 */
.MemberRoleForm > .header {
  padding: 15px;
  border-radius: 3px 3px 0 0;
  background-color: #E2E2E5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MemberRoleForm .header .SectionHeader {
  margin-bottom: 0;
}
.MemberRoleForm .header a {
  font-size: 13px;
}

.MemberRoleForm > .roles {
  border-radius: 0 0 3px 3px;
  background-color: #EFEFF1;
}

/**
 * Org Role
 */
.MemberRoleForm .org-roles {
  padding: 15px;
  border-bottom: 1px solid #DBDADE;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MemberRoleForm .org-roles .info {
  margin-right: 15px;
  flex-grow: 1;
}
.MemberRoleForm .org-roles .SectionHeader.org-header {
  margin-bottom: 3px;
  font-size: 14px;
  color: #5D5966;
}
.MemberRoleForm .org-roles .info > .RolesPanel .ButtonCheckbox {
  margin-top: 7px;
}
.MemberRoleForm .org-roles .controls {
  white-space: nowrap;
}
.MemberRoleForm .org-roles .controls .separator {
  font-size: 12px;
  color: #DBDADE;
}
.MemberRoleForm .org-roles .controls button {
  padding-right: 0;
}


/**
 * App Role
 */
.MemberRoleForm .app-roles {
}
.MemberRoleForm .app-roles .restricted-copy {
  margin: 0;
  padding: 15px 15px 0 15px;
  font-size: 14px;
  color: #2E2D33;
}
.MemberRoleForm .SectionHeader.app-header {
  border-top: 1px solid #DBDADE;
  margin-bottom: 0;
  padding: 15px 15px 0;
  font-size: 14px;
}
.MemberRoleForm .app-role {
  border-bottom: 1px solid #DBDADE;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MemberRoleForm .app-role .info {
  margin-right: 15px;
  flex-grow: 1;
}
.MemberRoleForm .app-role .info > p {
  margin-bottom: 0;
  font-size: 14px;
}
.MemberRoleForm .app-role .info > .RolesPanel .ButtonCheckbox {
  margin-top: 7px;
}
.MemberRoleForm .app-role .controls {
  white-space: nowrap;
}
.MemberRoleForm .app-role .controls .separator {
  font-size: 12px;
  color: #DBDADE;
}
.MemberRoleForm .app-role .controls button:last-child {
  padding-right: 0;
}

/**
 * App Dropdown
 */
.MemberRoleForm .app-list {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MemberRoleForm .app-list.disabled {
  opacity: 0.5;
}
.MemberRoleForm .app-list .form-control {
  margin-right: 15px;
  flex-grow: 1;
}

/**
 * Update Member Controls
 */
.MemberRoleForm > .update-controls {
  margin-top: 20px;
}
.MemberRoleForm > .update-controls button:first-child {
  margin-right: 10px;
}
