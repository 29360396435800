.Footer {
  padding: 20px 5px;
  border-top: 1px solid #C7C6CC;
}
.Footer a {
  font-family: Rubik, sans-serif;
  font-size: 13px;
  color: #908C99;
}
.Footer a:hover {
  color: #5D5966;
  text-decoration: none;
}
.Footer a.brand {
  font-family: Rubik, sans-serif;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;
}
.Footer .col-right {
  margin-top: 5px;
}

@media (min-width: 768px) {
  .Footer .col-right {
    margin-top: 0;
    text-align: right;
  }
}
