.AppSettingDeployPhases {
  padding: 20px;
}

.AppSettingDeployPhases p.copy {
  font-size: 14px;
  color: #5D5966;
}

.AppSettingDeployPhases .AppSettingDeployWorkflowPanel {
  margin-bottom: 30px;
}

.AppSettingDeployPhases > h4 {
  margin-top: 30px;
}
