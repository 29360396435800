.DashboardServicesPanel {
}

/**
 * Title
 */
.DashboardServicesPanel > h4 {
  margin: 0;
  padding-top: 1px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.DashboardServicesPanel.empty > h4 {
  border-bottom: none;
}
.DashboardServicesPanel > h4 span {
  font-size: 16px;
  font-weight: 500;
}
.DashboardServicesPanel > h4 > a {
  font-size: 13px;
  font-weight: normal;
}

/**
 * Service
 */
.DashboardServicesPanel .service {
  background-color: white;
  border-radius: 6px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0,0,0,.05);
}
.DashboardServicesPanel .service+.service {
  margin-top: 10px;
}
.DashboardServicesPanel .service {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.DashboardServicesPanel .service > .info {
  min-width: 0;
  display: flex;
  align-items: center;
}
.DashboardServicesPanel .service > .info .ServiceIcon {
  margin-right: 8px;
}
.DashboardServicesPanel .service > .info div {
  min-width: 0;
}
.DashboardServicesPanel .service > .info h5 {
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DashboardServicesPanel .service > .info p {
  margin: 0;
  color: #908C99;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DashboardServicesPanel .service > .SectionHeader {
  margin: 0 0 0 30px;
  color: #908C99;
  line-height: 1.5;
  white-space: nowrap;
}

/**
 * Not Deployed
 */
.DashboardServicesPanel .service.empty {
}
.DashboardServicesPanel .service.empty .info p {
  color: #C7C6CC;
}
.DashboardServicesPanel .service.empty > .SectionHeader {
  color: #AEACB5;
}
