.EnforceTwoFactorSettingPanel {
  padding: 13px 15px 15px;
  border-radius: 3px;
  background-color: #EFEFF1;
}

.EnforceTwoFactorSettingPanel .SectionHeader {
  margin-bottom: 7px;
}
.EnforceTwoFactorSettingPanel p.info {
  margin-bottom: 0;
  font-size: 14px;
  color: #908C99;
}
.EnforceTwoFactorSettingPanel p.unavailable {
  margin: 10px 0 0;
  border-top: 1px solid #E2E2E5;
  padding-top: 8px;
  font-size: 14px;
  color: #908C99;
}
.EnforceTwoFactorSettingPanel .LoaderButton {
  margin-top: 12px;
}
