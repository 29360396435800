.EnvVarPanel {
}
.EnvVarPanel form {
  display: flex;
  align-items: center;
}
.EnvVarPanel .form-group {
  margin-left: 10px;
  margin-bottom: 0;
}
.EnvVarPanel .form-group:first-child {
  margin-left: 0;
  flex: 1;
}
.EnvVarPanel .form-group:nth-child(2) {
  flex: 0 0 280px;
}
.EnvVarPanel .form-group textarea {
  resize: none;
  white-space: nowrap;
}

.EnvVarPanel .controls {
  flex: 0 0 170px;
}

.EnvVarPanel button.btnShow,
.EnvVarPanel button.btnSave {
  margin-left: 15px;
}

.EnvVarPanel .separator {
  font-size: 12px;
  color: #e2e2e5;
}
