.PipelineTableServiceCell {
  overflow: hidden;
  position: relative;
}
.PipelineTableServiceCell.small,
.PipelineTableServiceCell.small-right {
  padding-right: 10px;
}
.PipelineTableServiceCell.small-right {
  text-align: right;
}
.PipelineTableServiceCell:after {
  content: '';
  width: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  background: linear-gradient(to right, rgba(249, 249, 250, 0), #F9F9FA 90%);
}
.PipelineTableServiceCell.small:after,
.PipelineTableServiceCell.small-right:after {
  width: 10px;
}

.PipelineTableServiceCell > a {
  white-space: nowrap;
  display: block;
}
.PipelineTableServiceCell > a > .ServiceIcon {
  margin-right: 10px;
}
.PipelineTableServiceCell.small > a > .ServiceIcon,
.PipelineTableServiceCell.small-right > a > .ServiceIcon {
  margin-right: 5px;
  vertical-align: -3px;
}

.PipelineTableServiceCell div > a {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
}
.PipelineTableServiceCell.small div > a,
.PipelineTableServiceCell.small-right div > a {
  font-size: 14px;
}

.PipelineTableServiceCell.deleting {
  opacity: 0.5;
}
.PipelineTableServiceCell.deleting .glyphicon {
  margin-right: 6px;
  vertical-align: -1px;
  font-size: 14px;
  animation: spin 1s infinite linear;
}
.PipelineTableServiceCell.small.deleting .glyphicon,
.PipelineTableServiceCell.small-right.deleting .glyphicon {
  margin-right: 3px;
  font-size: 10px;
  vertical-align: 0px;
}

.PipelineTableServiceCell.delete_failed {
}
.PipelineTableServiceCell.delete_failed i.fa {
  margin-right: 5px;
  font-size: 15px;
}
.PipelineTableServiceCell.small.delete_failed i.fa,
.PipelineTableServiceCell.small-right.delete_failed i.fa {
  margin-right: 3px;
  font-size: 10px;
}
