.AppActivityServices {
  padding: 20px;
}

.AppActivityServices .BuildInfoPanel {
  margin-bottom: 20px;
}

.AppActivityServices .build-log,
.AppActivityServices .build-sls-info {
  margin-bottom: 20px;
}

.AppActivityServices .BuildHelpPanel {
  margin-bottom: 30px;
}
