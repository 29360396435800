.AppRepoPanel {
}

.AppRepoPanel p.current {
  font-size: 14px;
  color: #5D5966;
}
.AppRepoPanel p.current.highlight {
  color: #FD9527;
}

.AppRepoPanel .col-body > .controls {
  margin-top: 20px;
}

.AppRepoPanel form {
  margin-top: 5px;
}

.AppRepoPanel form .help-block {
  margin-top: 7px;
  font-size: 14px;
}
