.AppBadgePanel {
}

.AppBadgePanel p.current {
  font-size: 14px;
  color: #5d5966;
}
.AppBadgePanel p.current.highlight {
  color: #fd9527;
}

.AppBadgePanel .snippet {
  padding: 15px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background-color: #efeff1;
  margin-top: 20px;
}
.AppBadgePanel .snippet input {
  margin-right: 15px;
  flex: 1 1 auto;
  min-width: 250px;
  text-align: left;
  padding: 0;
  display: block;
  border: none;
  background: transparent;
  color: #908c99;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Source Code Pro", monospace;
}
.AppBadgePanel .snippet input:focus {
  outline: none;
}
.AppBadgePanel .snippet > div:last-child span.copied-status {
  margin-right: 8px;
  font-size: 12px;
  color: #fd9527;
}
