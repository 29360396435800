.ScreenHeader {
  margin-bottom: 20px;
}
.ScreenHeader.border {
  padding-bottom: 20px;
  border-bottom: 1px solid #DBDADE;
}
.ScreenHeader .cols {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ScreenHeader h1 {
  margin: 0;
  flex: 1 1 auto;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.2;
}
.ScreenHeader.with-breadcrumb h1 {
  margin-top: 4px;
}
.ScreenHeader .column.action {
  margin-left: 30px;
  text-align: right;
  flex: 1 0 auto;
}

/**
 * Breadcrumbs
 */
.ScreenHeader .ScrollShadowContainer {
  overflow: hidden;
}
.ScreenHeader .ScrollShadowContainer > .scroll-content::-webkit-scrollbar {
  display: none;
}
.ScreenHeader .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  white-space: nowrap;
  background-color: transparent;
}
.ScreenHeader .breadcrumb > li:before {
  content: none;
}
.ScreenHeader .breadcrumb > li:after {
  content: "/\00a0";
  color: #C7C6CC;
  padding: 0 4px 0 6px;
  font-size: 10px;
}
.ScreenHeader .breadcrumb li a {
  font-size: 13px;
}
.ScreenHeader .breadcrumb li.active {
  color: #5D5966;
}
.ScreenHeader .breadcrumb span.spacer {
  /* Needed to handle right side scroll padding issue for
   * scroll shadow container
   */
  padding: 0 5px;
}
