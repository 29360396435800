.AppEditPipeline {
  padding: 20px;
}

.AppEditPipeline > div > .options {
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 20px 15px;
  background-color: #EFEFF1;
}
.AppEditPipeline > div > .options .SectionInfo p.desc {
  color: #AEACB5;
}
.AppEditPipeline > div > .options .AppBranchPanel {
  margin-bottom: 20px;
}

.AppEditPipeline .SectionGroupPanel {
  margin-top: 20px;
}
