.IssueStatusControl {
}

.IssueStatusControl .LoaderButton i.fa-bell-slash-o {
  margin-right: 6px;
  opacity: 0.85;
  font-size: 12px;
}
.IssueStatusControl .LoaderButton i.fa-check {
  margin-right: 5px;
  opacity: 0.85;
}
