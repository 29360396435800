.PipelineTablePromoteCell {
}

/**
 * Single downstream
 */
.PipelineTablePromoteCell .LoaderButton,
.PipelineTablePromoteCell .dropdown-toggle {
  font-size: 14px;
  display: block;
  width: 100%;
  background-color: white;
  border-radius: 2px;
  border: none;
  color: #5D5966;
  padding: 14px 10px 12px;
}
.PipelineTablePromoteCell .LoaderButton[disabled] {
  background-color: white;
}
.PipelineTablePromoteCell .LoaderButton:not(:disabled):hover {
  color: #330033;
}

/**
 * Multi downstream
 */
.PipelineTablePromoteCell .PipelineDropdownButton .btn-group {
  display: block;
}
.PipelineTablePromoteCell .PipelineDropdownButton .dropdown-toggle {
  float: none;
}
.PipelineTablePromoteCell .PipelineDropdownButton .dropdown-toggle .caret {
color: #908C99;
  margin-left: 2px;
  border: none;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/**
 * Prod
 */
.PipelineTablePromoteCell > .prod {
  margin: 0 auto;
  padding: 0 15px;
  line-height: 49px;
  font-size: 12px;
  color: #908C99;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PipelineTablePromoteCell > .prod span:first-child {
  font-family: "Exo 2", sans-serif;
  letter-spacing: 1px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
