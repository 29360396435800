.BuildReportPanel {
}

/** Header **/
.BuildReportPanel > h4 {
  margin-top: 0;
  font-size: 16px;
  margin-bottom: 13px;
  font-weight: normal;
  color: #2E2D33;
}
.BuildReportPanel > h4 .actor-image {
  margin-right: 7px;
  display: inline-block;
  vertical-align: middle;
}

/** Body **/
.BuildReportPanel > .body {
  border: 1px solid #DDD;
  border-radius: 3px;
  overflow: none;
}

.BuildReportPanel .body .section-row {
  border-bottom: 1px solid #DDD;
  display: flex;
  align-items: center;
}
.BuildReportPanel .body .section-row:last-child {
  border-bottom: 0 none;
}
.BuildReportPanel .body .section-row.workflow,
.BuildReportPanel .body .section-row.commit-info {
  display: block;
}
.BuildReportPanel .body .section-row.workflow .SectionInfo p.current {
  margin-bottom: 0;
  font-size: 14px;
  color: #908C99;
}

.BuildReportPanel .body .section-row .SectionInfo {
  margin-bottom: 0;
  flex-basis: 50%;
  padding: 12px 12px 10px;
}
.BuildReportPanel .body .section-row .SectionInfo:last-child {
  border-right: 0 none;
}
.BuildReportPanel .body .section-row .SectionInfo:first-child {
  border-right: 1px solid #DDD;
}
.BuildReportPanel .body .section-row .SectionInfo:first-child:last-child {
  flex-basis: 100%;
  border-right: 0 none;
}

.BuildReportPanel .body .section-row .SectionInfo .info-text,
.BuildReportPanel .body .section-row .SectionInfo .info-link,
.BuildReportPanel .body .section-row .SectionInfo .CommitInfoGroup,
.BuildReportPanel .body .section-row .SectionInfo .ServiceButtonGroup .service {
  font-size: 16px;
}
.BuildReportPanel .body .section-row .SectionInfo.date .info-text {
  white-space: nowrap;
}

.BuildReportPanel .body .section-row .SectionInfo .ServiceButtonGroup .service img {
  vertical-align: -5px;
}

.BuildReportPanel .body .section-row .SectionInfo.status {
}
.BuildReportPanel .body .section-row .SectionInfo.status i {
  margin-right: 6px;
  opacity: 0.9;
  color: #908C99;
  font-size: 20px;
  vertical-align: -1px;
}
.BuildReportPanel .body .section-row .SectionInfo.status .queued i,
.BuildReportPanel .body .section-row .SectionInfo.status .success i,
.BuildReportPanel .body .section-row .SectionInfo.status .deploying i {
  color: #74649A;
}
.BuildReportPanel .body .section-row .SectionInfo.status .failure i,
.BuildReportPanel .body .section-row .SectionInfo.status .failure span.copy {
  color: #A35502;
}
.BuildReportPanel .body .section-row .SectionInfo.status .LoaderButton {
  margin-left: 10px;
  padding: 4px 8px 3px;
  vertical-align: 1px;
  border-width: 1px;
}

.BuildReportPanel .body .section-row .SectionInfo.error {
}
.BuildReportPanel .body .section-row .SectionInfo.error .message {
  margin-bottom: 0;
}
.BuildReportPanel .body .section-row .SectionInfo.error .steps {
  padding-bottom: 2px;
}

/** Service Error **/
.BuildReportPanel .body .section-row .SectionInfo.error .steps .step {
  margin-top: 10px;
  margin-left: 10px;
  padding-top: 10px;
  padding-left: 2px;
  border-top: 1px solid #E2E2E5;
}
.BuildReportPanel .body .section-row .SectionInfo.error .steps .step:first-child {
  margin-top: 0;
  padding-top: 2px;
  border-top: 0 none;
}
.BuildReportPanel .body .section-row .SectionInfo.error .steps .step .ServiceIcon {
  margin-right: 6px;
}
.BuildReportPanel .body .section-row .SectionInfo.error .steps .step .message {
  margin-bottom: 0;
  margin-top: 5px;
}
.BuildReportPanel .body .section-row .SectionInfo.error .steps .step .message .copy {
  color: #A35502;
  max-width: 75ch;
  text-overflow: ellipsis;
  overflow: hidden;
}
.BuildReportPanel .body .section-row .SectionInfo.error .steps .step .message a {
  margin-left: 10px;
  font-size: 14px;
  white-space: nowrap;
}
.BuildReportPanel .body .section-row .SectionInfo.error .steps .step .message a i {
  font-size: 13px;
}

/** Post Deploy Error **/
.BuildReportPanel .body .section-row .SectionInfo.error .steps .skipped .message,
.BuildReportPanel .body .section-row .SectionInfo.error .steps .post-deploy .message {
  margin-bottom: 0;
}
.BuildReportPanel .body .section-row .SectionInfo.error .steps .skipped .message .copy,
.BuildReportPanel .body .section-row .SectionInfo.error .steps .post-deploy .message .copy {
  color: #A35502;
}
.BuildReportPanel .body .section-row .SectionInfo.error .steps .post-deploy .message a {
  margin-left: 10px;
  font-size: 14px;
  white-space: nowrap;
}
.BuildReportPanel .body .section-row .SectionInfo.error .steps .post-deploy .message a i {
  font-size: 13px;
}

/** Commit Changes **/
.BuildReportPanel .body .section-row.commit-changes {
  align-items: stretch;
}
.BuildReportPanel .body .section-row.commit-changes .ServiceButtonGroup.removed {
  opacity: 0.5;
}
.BuildReportPanel .body .section-row.commit-changes p {
  margin-bottom: 0;
}
.BuildReportPanel .body .section-row.commit-changes ul {
  margin-bottom: 0;
  padding-left: 30px;
  list-style: none;
}
.BuildReportPanel .body .section-row.commit-changes ul li::before {
  content: "\2022";
  color: #5D5966;
  font-weight: bold;
  display: inline-block;
  vertical-align: -1px;
  width: 1em;
  margin-left: -1em;
}
.BuildReportPanel .body .section-row.commit-changes ul li:not(:last-child) {
  margin-bottom: 2px;
}
.BuildReportPanel .body .section-row.commit-changes a span {
  font-family: "Source Code Pro", monospace;
}
