.AppSettingIssues {
  padding: 20px;
}

.AppSettingIssues > hr {
  margin: 45px 0;
}

.AppSettingIssues .LiveErrorNotificationsPanel {
  margin-bottom: 30px;
}

.AppSettingIssues .IssuesStageSettingsPanel .col-body {
  max-width: 500px;
}
