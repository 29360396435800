.StageSelectDropdown {
  position: relative;
  display: inline-block;
  z-index: 1;
}

/**
 * Selected
 */
.StageSelectDropdown .selected {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.StageSelectDropdown.loading .selected {
  cursor: default;
}
.StageSelectDropdown .selected p {
  margin: 0;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.StageSelectDropdown .selected i.fa {
  margin-top: 1px;
  margin-left: 8px;
  font-size: 20px;
  color: #AEACB5;
  opacity: 1;
}
.StageSelectDropdown.loading .selected i.fa {
  opacity: 0;
}

/**
 * Dropdown
 */
.StageSelectDropdown .stages {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 44px;
  left: -10px;
  max-height: 350px;
  max-width: 400px;
  min-width: 320px;
  list-style: none;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
              0 2px 3px rgba(0, 0, 0, 0.05),
              0 4px 6px rgba(0, 0, 0, 0.05);
}
.StageSelectDropdown .stages li {
  font-size: 20px;
}
.StageSelectDropdown .stages li a {
  padding: 12px;
  border-bottom: 1px solid #EFEFF1;
  line-height: 1.2;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #5D5966;
}
.StageSelectDropdown .stages li:last-child a {
  border-bottom: none;
}
.StageSelectDropdown .stages li a:hover {
  background-color: #EFEFF1;
}
