.SignupConfirmForm {
}

.SignupConfirmForm .help-block {
  margin-top: 7px;
  font-size: 14px;
  color: #908C99;
}

.SignupConfirmForm .controls {
  margin-top: 30px;
}
