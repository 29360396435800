.AppBranchPanel {
}

.AppBranchPanel span.edit {
  display: inline-block;
  line-height: 34px;
}
.AppBranchPanel span.current {
  color: #5D5966;
  opacity: 0.5;
}
