.ServiceRemove {
  padding: 20px;
}

.ServiceRemove .ScreenHeader img {
  margin-right: 5px;
  padding: 2px 6px;
  vertical-align: -8px;
  background-color: black;
  border-radius: 3px;
  box-sizing: border-box;
}
.ServiceRemove .ItemRemoveStatusPanel {
  margin-bottom: 25px;
}
