.TransferAppPanel {
}

.TransferAppPanel .SectionInfo .col-info .SectionHeader {
  color: #D9534F;
}

.TransferAppPanel .SectionInfo .col-info p.desc {
  color: #D9534F;
}

.TransferAppPanel .help-block {
  font-size: 14px;
  color: #D9534F;
}
.TransferAppPanel .help-block b {
  font-weight: 500;
}

.TransferAppPanel .controls {
  margin-top: 20px;
}
.TransferAppPanel .controls .TextButton {
  margin-left: 20px;
  font-size: 14px;
}
