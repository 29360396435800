.LoaderButton:not(.btn-link) {
  border-radius: 3px;
  border-width: 2px;
  transition: background-color .25s ease-out, border .25s ease-out;
}
.LoaderButton.btn {
  padding: 6px 11px 5px;
}
.LoaderButton.btn-lg {
  padding: 9px 15px;
}

.LoaderButton:active,
.LoaderButton:focus {
  outline: 0 !important;
}


.LoaderButton.btn-default,
.LoaderButton.btn-primary {
  background-color: transparent;
  border-color: #CCC;
  color: #5D5966;
}
.LoaderButton.btn-default:hover,
.LoaderButton.btn-primary:hover {
  background-color: #E6E6E6;
  border-color: #ADADAD;
}
.LoaderButton.btn-default:active:hover,
.LoaderButton.btn-primary:active:hover {
  color: #5D5966;
  border-color: #8C8C8C;
}
.LoaderButton.btn-default[disabled],
.LoaderButton.btn-primary[disabled],
.LoaderButton.btn-default[disabled]:active:hover,
.LoaderButton.btn-primary[disabled]:active:hover {
  background-color: transparent;
  border-color: #CCC;
  color: #908C99;
}

.LoaderButton.btn-primary {
  font-weight: 500;
}




.LoaderButton.btn-warning,
.LoaderButton.btn-warning[disabled]:hover {
  background-color: transparent;
  color: #DA7204;
  border-color: #FD9527;
}
.LoaderButton.btn-warning:hover {
  color: white;
  background-color: #FFAA50;
}
.LoaderButton.btn-warning:active {
  border-color: #AB5800;
}



.LoaderButton.btn-danger,
.LoaderButton.btn-danger[disabled]:hover {
  background-color: transparent;
  color: #D9534F;
  border-color: #D43F3A;
}
.LoaderButton.btn-danger:hover {
  color: white;
  background-color: #C9302C;
  border-color: #AC2925;
}
.LoaderButton.btn-danger:active {
  border-color: #761C19;
}



.LoaderButton.btn-success,
.LoaderButton.btn-success[disabled]:hover {
  background-color: transparent;
  color: #D9534F;
  border-color: #D43F3A;
}
.LoaderButton.btn-success:hover {
  color: white;
  background-color: #C9302C;
  border-color: #AC2925;
}
.LoaderButton.btn-success:active {
  border-color: #761C19;
}

/**
 * Block Style
 */
.LoaderButton.block {
  background-color: #EFEFF1;
  border: none;
  color: #5D5966;
}
.LoaderButton.block:hover {
  background-color: #E2E2E5;
  color: #2E2D33;
}
.LoaderButton.block[disabled] {
  background-color: #EFEFF1;
  color: #C7C6CC;
}



.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

/** Chevron **/
.LoaderButton.btn-default i:last-child {
  margin-left: 1px;
  font-size: 13px;
}
.LoaderButton.btn-lg i:last-child {
  margin-left: 1px;
  font-size: 17px;
}
.LoaderButton.btn-sm i:last-child {
  margin-left: 1px;
  font-size: 11px;
}

/** Red Link **/
.LoaderButton.btn-red-link {
  color: #BE2F2B;
}
.LoaderButton.btn-red-link:hover {
  color: #8E1514;
}
