.UpdateEmailForm {
}

.UpdateEmailForm .help-block {
  margin-top: 7px;
  font-size: 14px;
}

.UpdateEmailForm .controls {
  margin-top: 20px;
}
