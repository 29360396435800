.RequestDemoForm {
}

.RequestDemoForm .help-block {
  margin-top: 7px;
  font-size: 14px;
}

.RequestDemoForm .controls {
  margin-top: 30px;
  text-align: left;
}
