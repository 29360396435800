.RequestLogPanel {
}

.RequestLogPanel .span-control {
  background-color: #EFEFF1;
  border: 1px solid #E2E2E5;
  height: 36px;
}

/**
 * Span butons
 */
.RequestLogPanel .span-control.up,
.RequestLogPanel .span-control.down {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.RequestLogPanel .span-control.up {
  border-bottom: 1px solid #E2E2E5;
  border-radius: 3px 3px 0 0;
}
.RequestLogPanel .span-control.down {
  border-top: 0 none;
  border-radius: 0 0 3px 3px;
}
.RequestLogPanel .span-control .TextButton.time {
  padding-left: 7px;
  margin-right: 3px;
  color: #908C99;
  font-size: 13px;
}
.RequestLogPanel .span-control .LoaderButton {
  padding: 7px;
  text-align: left;
}
.RequestLogPanel .span-control .LoaderButton:hover {
  color: #2E2D33;
}
.RequestLogPanel .span-control .LoaderButton span.status {
  margin-left: 7px;
  color: #FD9527;
}
.RequestLogPanel .span-control .LoaderButton .glyphicon-refresh {
  margin-right: 5px;
  vertical-align: 1px;
  font-size: 10px;
  color: #908C99;
}

/**
 * Tailing sign
 */
.RequestLogPanel .span-control.tailing {
  border-top: 0 none;
  border-radius: 0 0 3px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.RequestLogPanel .span-control.tailing > span:first-child {
  color: #908C99;
  font-size: 13px;
  padding: 8px 8px 8px 6px;
  line-height: 1.5;
  display: inline-block;
}
.RequestLogPanel .span-control.tailing .glyphicon-flash {
  margin-right: 5px;
  vertical-align: -1px;
  animation: pulse 1.5s infinite linear;
}

/**
 * Single Request Sign
 */
.RequestLogPanel .span-control.single-request {
  border-radius: 3px 3px 0 0;
}
.RequestLogPanel .span-control.single-request > span {
  color: #908C99;
  font-size: 13px;
  padding: 8px 7px;
  line-height: 1.5;
  display: inline-block;
}

/**
 * Load more buttons
 */
.RequestLogPanel .load-more-control {
  background-color: #EFEFF1;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #E2E2E5;
}
.RequestLogPanel .load-more-control .LoaderButton {
  text-align: left;
  padding: 6px 8px 6px 5px;
  display: block;
  width: 100%;
}
.RequestLogPanel .load-more-control .LoaderButton i.fa {
  margin-left: 4px;
  margin-right: 8px;
  vertical-align: -1px;
  opacity: 0.8;
}
.RequestLogPanel .load-more-control .LoaderButton .glyphicon-refresh {
  margin-right: 5px;
  font-size: 10px;
  vertical-align: 1px;
}

/**
 * Loading spinner
 */
.RequestLogPanel .LoadingSpinner {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #E2E2E5;
  border-radius: 0 0 3px 3px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RequestLogPanel .LoadingSpinner .spinner {
  margin: 0 auto;
  padding: 0;
}

/**
 * Empty sign
 * Error sign
 */
.RequestLogPanel > .empty,
.RequestLogPanel > .error-sign {
  margin: 0 auto;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #E2E2E5;
  border-radius: 0 0 3px 3px;
  color: #908C99;
}
.RequestLogPanel > .empty.tailing {
}
.RequestLogPanel > .empty.tailing .glyphicon-flash {
  margin-right: 5px;
  vertical-align: -2px;
  animation: pulse 1.5s infinite linear;
}

/**
 * Missing logs
 */
.RequestLogPanel .span-control .missing {
  padding: 0 5px;
  color: #FD9527;
  font-size: 12px;
}
.RequestLogPanel .span-control .missing i.fa {
  margin-right: 5px;
  font-size: 11px;
  opacity: 0.9;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 0.3; }
  50% { transform: scale(1); opacity: 1; }
  100% { transform: scale(1); opacity: 0.3; }
}
