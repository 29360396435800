.SeedAlert.alert {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 13px;
  border-radius: 3px;
}
.SeedAlert.alert-warning {
  color: #79532F;
  border-color: #FEDFBF;
  background-color: #FFEFDF;
}
.SeedAlert.alert-danger {
  color: #BE2F2B;
  border-color: #F0B8B6;
  background-color: #F7DDDC;
}
.SeedAlert.alert-info {
  color: #463B62;
  border-color: #D0CCDC;
  background-color: #E5E3EC;
}
