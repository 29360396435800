.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App > .container-fluid {
  margin: 0;
  padding: 0;
  flex: 1 0 auto;
  position: relative;
}
@media all and (max-width: 600px) {
  .App > .container-fluid {
    padding: 0;
  }
}

.App .NetworkStatusAlert {
  width: 300px;
  margin-left: -150px;
  top: 8px;
  left: 50%;
  z-index: 1001;
  position: fixed;
}
