.ServiceIcon {
  background-color: black;
  box-sizing: border-box;
  border-radius: 3px;
}
.ServiceIcon.sst {
  background-color: #e27152;
}
.ServiceIcon.default {
  background-color: #e2e2e5;
}
.ServiceIcon.large {
  padding: 2px 6px;
}
.ServiceIcon.medium {
  padding: 1px 3px;
}
.ServiceIcon.small {
  padding: 1px 2px;
}
