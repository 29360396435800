.DashboardMetricsPanel {
}

/**
 * Title
 */
.DashboardMetricsPanel > h4 {
  margin: 0;
  padding-top: 1px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #DADEE2;
}
.DashboardMetricsPanel.empty > h4 {
  border-bottom: none;
}
.DashboardMetricsPanel > h4 span {
  font-size: 16px;
  font-weight: 500;
}
.DashboardMetricsPanel > h4 > span .TextButton {
  margin-left: 7px;
  font-size: 13px;
  font-weight: normal;
}

.DashboardMetricsPanel > .body {
}

/**
 * Header
 */
.DashboardMetricsPanel .header {
  padding: 8px 1px 5px;
  display: flex;
  align-items: center;
}
.DashboardMetricsPanel .header div.col1 {
  flex: 1 1 auto;
}
.DashboardMetricsPanel .header div.col1 .SectionHeader {
  margin-bottom: 0;
  color: #AEACB5;
}
.DashboardMetricsPanel .header div.col2 {
  margin: 0;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DashboardMetricsPanel .header div.col2 .SectionHeader {
  margin: 0;
  color: #AEACB5;
}
.DashboardMetricsPanel .header div.col2 .TextButton {
  font-size: 12px;
  font-family: "Exo 2", sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  color: #C7C6CC;
}
.DashboardMetricsPanel .header div.col2 .TextButton:hover {
  color: #330033;
}
.DashboardMetricsPanel .header div.col2 .TextButton.selected,
.DashboardMetricsPanel .header div.col2 .TextButton.selected:hover {
  color: #908C99;
}
.DashboardMetricsPanel .header div.col2 .TextButton:last-child {
  margin-left: 10px;
}
.DashboardMetricsPanel .header div.col3 .SectionHeader {
  margin: 0;
  padding-right: 1px;
  width: 135px;
  text-align: right;
  color: #AEACB5;
}
.DashboardMetricsPanel .header > h4 {
  margin: 0px 0 0;
  font-size: 16px;
  font-weight: 500;
}
.DashboardMetricsPanel .header > h4 a {
  margin-left: 5px;
  font-size: 13px;
  font-weight: normal;
}

/**
 * Metrics Permission Error
 */
.DashboardMetricsPanel .body .error {
  text-align: center;
  margin: 10px 0;
}
.DashboardMetricsPanel .body .error .LoaderButton {
  width: 100%;
  display: block;
  border: 1px solid #BE2F2B;
  border-radius: 3px;
  padding: 8px 11px 7px;
}
.DashboardMetricsPanel .body .error .LoaderButton i.fa {
  margin-right: 6px;
  font-size: 13px;
}

/**
 * Metric
 */
.DashboardMetricsPanel .metric {
  background-color: white;
  border-radius: 4px;
  min-width: 640px;
  padding: 13px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02),
              0 2px 4px rgba(0, 0, 0, 0.03);
}
.DashboardMetricsPanel .metric+.metric {
  margin-top: 10px;
}
.DashboardMetricsPanel .metric .name {
  min-width: 0;
}
.DashboardMetricsPanel .metric .name a {
  margin-bottom: 3px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DashboardMetricsPanel .metric .name a i.fa {
  margin: 0 6px 0 2px;
  color: #AEACB5;
}
.DashboardMetricsPanel .metric .name a > span {
  opacity: 0.7;
  font-size: 14px;
}
.DashboardMetricsPanel .metric .name > span {
  font-size: 13px;
  color: #C7C6CC;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DashboardMetricsPanel .metric .values {
  margin-left: 30px;
}
.DashboardMetricsPanel .metric .values > .value {
  display: flex;
  align-items: center;
}
.DashboardMetricsPanel .metric .values > .value .graph {
  margin-top: 1px;
  margin-right: 15px;
  flex: 0 0 190px;
  width: 190px;
}
.DashboardMetricsPanel .metric .values > .value .graph .SectionHeader {
  margin: 0;
  text-align: center;
  font-size: 11px;
  color: #DADEE2;
}
.DashboardMetricsPanel .metric .values > .value .number {
  text-align: right;
  font-family: "Source Code Pro", monospace;
  font-size: 20px;
  min-width: 105px;
}
.DashboardMetricsPanel .metric .values > .value .number .unit {
  margin-left: 5px;
  color: #908C99;
  font-size: 17px;
}

.DashboardMetricsPanel .metric .values > .empty {
  /** 190 + 15 + 105 **/
  width: 310px;
  text-align: right;
}
.DashboardMetricsPanel .metric .values > .empty > .SectionHeader {
  margin: 0 15px 0 0;
  width: 190px;
  text-align: center;
  font-size: 11px;
  color: #DADEE2;
  display: inline-block;
}
.DashboardMetricsPanel .metric .values > .empty .unit {
  color: #908C99;
  font-size: 17px;
  font-family: "Source Code Pro", monospace;
  font-size: 20px;
  min-width: 105px;
  display: inline-block;
}

/**
 * Loading Spinner
 */
.DashboardMetricsPanel .metric .values > .empty > span.spinner {
  margin-right: 13px;
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode:forwards;
  animation-duration: 0s;

  animation-delay: 0.5s; /* Wait before showing the spinner */
}
.DashboardMetricsPanel .metric .values > .empty > span.spinner span {
  width: 6px;
  height: 6px;
  background-color: #DADEE2;
  opacity: 0.5;
  margin-right: 2px;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.DashboardMetricsPanel .metric .values > .empty > span.spinner span:last-child {
  margin-right: 0;
}
.DashboardMetricsPanel .metric .values > .empty > span.spinner .bounce1 {
  animation-delay: -0.32s;
}
.DashboardMetricsPanel .metric .values > .empty > span.spinner .bounce2 {
  animation-delay: -0.16s;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}

/**
 * Empty
 */
.DashboardMetricsPanel > .empty {
  height: 140px;
  border-radius: 4px;
  border: 3px dashed #DADEE2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
}
.DashboardMetricsPanel > .empty .copy {
  color: #AEACB5;
}

/**
 * Resource Permission Error
 */
.DashboardMetricsPanel > .error {
  height: 140px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: 3px dashed #DADEE2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.DashboardMetricsPanel > .error i.fa {
  margin-bottom: 10px;
  font-size: 24px;
  opacity: 0.8;
  color: #D9534F;
}
.DashboardMetricsPanel > .error p {
  margin-bottom: 0;
  color: #D9534F;
  font-size: 15px;
}
.DashboardMetricsPanel > .error .TextButton {
  font-size: 14px;
  color: #BE2F2B;
}
.DashboardMetricsPanel > .error .TextButton:hover {
  color: #8E1514;
}
