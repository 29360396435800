.ResourceItem {
  padding: 15px 15px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ResourceItem > div:first-child {
  flex-grow: 1;
  margin-right: 30px;
}
.ResourceItem > div:first-child p.info {
  margin-bottom: 0;
  font-size: 14px;
  color: #908C99;
}

/**
 * Common Styles
 */
.ResourceItem > div:last-child {
  white-space: nowrap;
}
.ResourceItem > div:last-child a,
.ResourceItem > div:last-child .TextButton {
  font-size: 14px;
  vertical-align: 1px;
}
.ResourceItem > div:last-child .TextButton.disabled {
  color: #908C99;
}
.ResourceItem > div:last-child span.separator {
  padding: 0 11px;
  font-size: 12px;
  color: #E2E2E5;
  vertical-align: 1px;
}

/**
 * API
 */
.ResourceItem.api > div:first-child p.name {
  margin-bottom: 2px;
}
.ResourceItem.api > div:first-child p.name a {
  color: #2E2D33;
}
.ResourceItem.api > div:first-child div.controls {
}

/**
 * Website
 */
.ResourceItem.website > div:first-child p.name {
  margin-bottom: 0;
}

/**
 * Lambda, Output, & Other
 */
.ResourceItem.other > div:first-child,
.ResourceItem.lambda > div:first-child,
.ResourceItem.output > div:first-child {
  min-width: 0;
  display: flex;
}
.ResourceItem.other > div:first-child {
  margin-right: 0;
}
.ResourceItem.other > div:first-child p.name,
.ResourceItem.lambda > div:first-child p.name,
.ResourceItem.output > div:first-child p.name {
  margin: 0;
  flex: 1 1 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
}
.ResourceItem.lambda > div:first-child p.tag {
  flex: 0 0 60px;
  margin: 0 15px 0 0;
  height: 18px;
  text-align: center;
  background-color: #EFEFF1;
  padding: 2px 5px;
  border-radius: 3px;
  color: #908C99;
  font-size: 10px;
  font-family: "Source Code Pro", monospace;
}
.ResourceItem.other > div:first-child p.info,
.ResourceItem.lambda > div:first-child p.info {
  margin-left: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ResourceItem.other > div:first-child p.info {
}

.ResourceItem.output > div:first-child {
  min-width: 0;
  display: flex;
  align-items: center;
}
.ResourceItem.output > div:first-child input {
  flex: 1 1 auto;
  min-width: 250px;
  text-align: right;
  padding: 0;
  font-size: 14px;
  display: block;
  border: none;
  background: transparent;
  color: #908C99;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ResourceItem.output > div:first-child input:focus {
  outline: none;
}
.ResourceItem.output > div:last-child span.copied-status {
  margin-right: 8px;
  font-size: 12px;
  color: #FD9527;
}
