.StageNotificationsPanel {
}

.StageNotificationsPanel .table {
  margin-bottom: 30px;
  table-layout: fixed;
}
.StageNotificationsPanel thead > tr > th {
  font-family: "Exo 2", sans-serif;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #908C99;
}
.StageNotificationsPanel .table > thead > tr:first-child > th {
  border-width: 0;
  background-color: #EFEFF1;
  padding: 10px;
}
.StageNotificationsPanel tbody > tr:first-child > td {
  border-width: 0;
}
.StageNotificationsPanel tbody > tr:last-child > td {
  border-bottom: 1px solid #E2E2E5;
}
.StageNotificationsPanel tbody > tr > td {
  border-color: #E2E2E5;
  padding: 12px;
  vertical-align: middle;
}
.StageNotificationsPanel tbody > tr > td:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.StageNotificationsPanel tbody > tr > td:nth-child(2) {
  color: #908C99;
}
.StageNotificationsPanel tr td:last-child {
  text-align: right;
}

.StageNotificationsPanel form {
  max-width: 600px;
}
.StageNotificationsPanel form .help-block {
  font-size: 14px;
}
.StageNotificationsPanel form .controls {
  margin-top: 20px;
}
