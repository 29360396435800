.AccessLogSettingsPanel {
}

.AccessLogSettingsPanel span.current {
  font-size: 14px;
  color: #908C99;
}
.AccessLogSettingsPanel span.current.disabled {
}
.AccessLogSettingsPanel span.current.error {
  color: #D9534F;
}
.AccessLogSettingsPanel span.current.error a {
  color: #D9534F;
  font-weight: 500;
}

.AccessLogSettingsPanel .LoaderButton {
  padding: 0;
}
