.StageBranchPanel {
}

.StageBranchPanel .LoaderToggle > span > * {
  vertical-align: middle;
}
.StageBranchPanel .LoaderToggle b {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  vertical-align: top;
}

.StageBranchPanel span.edit {
  display: inline-block;
  line-height: 34px;
}
.StageBranchPanel span.edit .LoaderButton {
  padding: 0;
  font-size: 16px;
  vertical-align: 0;
  border: 0;
}
.StageBranchPanel span.edit .LoaderButton .glyphicon {
  margin-left: 1px;
  margin-right: 6px;
  font-size: 14px;
}

.StageBranchPanel p.current {
  font-size: 14px;
  color: #5D5966;
}
.StageBranchPanel p.current.highlight {
  color: #FD9527;
}
