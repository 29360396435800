.AppCiPanel {
}

.AppCiPanel p.current {
  font-size: 14px;
  color: #5D5966;
}
.AppCiPanel p.current.highlight {
  color: #FD9527;
}
