.IssueLogPanel {
}

/**
 * Controls
 */
.IssueLogPanel .controls {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.IssueLogPanel .controls .SectionHeader {
  margin: 0;
  line-height: 22px;
  font-size: 14px;
  color: #AEACB5;
}
.IssueLogPanel .controls a {
  font-size: 14px;
}
.IssueLogPanel .controls a i.fa {
  font-size: 11px;
  opacity: 0.95;
}
.IssueLogPanel .controls a i.fa {
  margin-left: 5px;
}

.IssueLogPanel > .content {
  background-color: #5D5966;
  padding: 7px 14px;
  border-radius: 3px;
}

/**
 * Loading spinner
 */
.IssueLogPanel .LoadingSpinner {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.IssueLogPanel .LoadingSpinner .spinner {
  margin: 0 auto;
  padding: 0;
}

/**
 * Empty sign
 * Error sign
 */
.IssueLogPanel > .content .empty,
.IssueLogPanel > .content .error-sign {
  margin: 0 auto;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #DBDADE;
}
.IssueLogPanel > .content .error-sign .TextButton {
  color: white;
  font-weight: 500;
}
.IssueLogPanel > .content .error-sign .TextButton:hover {
  color: #DBDADE;
}

/**
 * Tailing Sign
 */
.IssueLogPanel > .content .tailing {
  padding: 9px 0 6px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.IssueLogPanel > .content .tailing .glyphicon-flash {
  margin-right: 5px;
  vertical-align: -2px;
  animation: pulse 1.5s infinite linear;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 0.3; }
  50% { transform: scale(1); opacity: 1; }
  100% { transform: scale(1); opacity: 0.3; }
}

/**
 * Logs
 */
.IssueLogPanel .logs {
}
.IssueLogPanel .logs .divider {
  margin-left: 167px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.IssueLogPanel .logs .divider:last-child {
  display: none;
}
