.ConfirmPromoteModal {
}

/**
 * Header
 */
.ConfirmPromoteModal .modal-header {
  border: 0;
}
.ConfirmPromoteModal .modal-title .separator {
  padding: 0 6px;
  color: #9BB0AF;
}

.ConfirmPromoteModal .modal-body {
  padding-top: 0;
}
.ConfirmPromoteModal .summary {
  padding: 10px 12px 12px;
  display: flex;
  background-color: #EFEFF1;
  border-radius: 3px;
}
.ConfirmPromoteModal .summary > span {
  color: #5D5966;
}
.ConfirmPromoteModal .summary .ScrollShadowContainer {
  flex: 1;
  flex-grow: 1;
}
.ConfirmPromoteModal .summary .graphic {
  display: flex;
  align-items: center;
  max-width: 350px;
}
.ConfirmPromoteModal .summary .locations .SectionHeader {
  margin-bottom: 11px;
}
.ConfirmPromoteModal .summary .locations span {
  font-size: 20px;
  color: #5D5966;
  padding: 5px 12px 6px;
  border-radius: 3px;
  background-color: #D7D3E1;
  display: inline-block;
  white-space: nowrap;
}
.ConfirmPromoteModal .summary .glyphicon {
  margin: 21px 10px 0 12px;
  font-size: 24px;
  color: #C7C6CC;
}

/**
 * Commit Diff
 */
.ConfirmPromoteModal .commit-diff {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #C7C6CC;
}
.ConfirmPromoteModal .commit-diff .SectionHeader {
  margin-bottom: 10px;
}
.ConfirmPromoteModal .commit-diff p.copy {
  margin-bottom: 0;
  color: #908C99;
  opacity: 0.8;
  font-size: 13px;
}
.ConfirmPromoteModal .commit-diff p.copy i.fa {
  margin-right: 5px;
  opacity: 0.85;
}
.ConfirmPromoteModal .commit-diff p.commit {
  margin-bottom: 0;
}
.ConfirmPromoteModal .commit-diff a span {
  padding-right: 5px;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
}
.ConfirmPromoteModal .commit-diff a i.fa {
  padding-left: 1px;
  font-size: 12px;
  opacity: 0.9;
}
.ConfirmPromoteModal .commit-diff a span:nth-child(2) {
  padding-left: 5px;
}

/**
 * Changes
 */
.ConfirmPromoteModal .changes {
  padding-top: 22px;
  margin-bottom: 5px;
}
.ConfirmPromoteModal .changes .SectionHeader {
  margin-bottom: 15px;
}
.ConfirmPromoteModal .ConfirmPromoteChangesPanel {
}

/**
 * Error Message
 */
.ConfirmPromoteModal .empty,
.ConfirmPromoteModal .error {
  padding: 0 60px;
  text-align: center;
  border: 2px dashed #DADEE2;
  border-radius: 4px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.ConfirmPromoteModal .empty .fa.icon,
.ConfirmPromoteModal .error .fa.icon {
  margin-bottom: 15px;
  font-size: 36px;
  color: #908C99;
}
.ConfirmPromoteModal .empty p:nth-child(2),
.ConfirmPromoteModal .error p:nth-child(2) {
  line-height: 1.6;
  margin-bottom: 0;
}
.ConfirmPromoteModal .error p code {
  font-size: 15px;
  vertical-align: 1px;
}
.ConfirmPromoteModal .empty p.details {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 14px;
}

/**
 * Loading Request
 */
.ConfirmPromoteModal .changes > .loading {
  border: 2px dashed #DADEE2;
  border-radius: 4px;
  height: 250px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.ConfirmPromoteModal .changes > .loading.request {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ConfirmPromoteModal .changes > .loading .header {
  margin-bottom: 0;
  text-align: center;
  color: #5D5966;
}
.ConfirmPromoteModal .changes > .loading .header .glyphicon {
  margin-right: 7px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
.ConfirmPromoteModal .changes > .loading .status {
  margin-bottom: 0;
  margin-top: 7px;
  font-size: 14px;
}

/**
 * Loading Generate
 */
.ConfirmPromoteModal .changes > .loading .statuses {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.ConfirmPromoteModal .changes > .loading .statuses li {
  display: flex;
  padding: 8px 10px;
  border-bottom: 1px solid #E2E2E5;
}
.ConfirmPromoteModal .changes > .loading .statuses li:last-child {
  border-bottom: 0 none;
}
.ConfirmPromoteModal .changes > .loading .statuses li div:first-child {
  margin-right: 8px;
  margin-top: 2px;
}
.ConfirmPromoteModal .changes > .loading .statuses li div:first-child .fa {
  color: #908C99;
  opacity: 0.8;
}
.ConfirmPromoteModal .changes > .loading .statuses li.failure div:first-child .fa {
  color: #D9534F;
}
.ConfirmPromoteModal .changes > .loading .statuses li p:first-child {
  margin-bottom: 2px;
}
.ConfirmPromoteModal .changes > .loading .statuses li p:last-child {
  margin-bottom: 0;
  color: #908C99;
  font-size: 13px;
}

/**
 * Controls
 */
.ConfirmPromoteModal .modal-footer button:last-child {
  margin-left: 15px;
}
