.LogFilterSelect {
  position: relative;
  z-index: 1;
}

.LogFilterSelect > .LoaderButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  border-width: 1px;
  border-color: #E2E2E5;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 9px;
  font-size: 16px;
  background-color: white;
  box-sizing: border-box;
  max-width: 240px;
}
.LogFilterSelect > .LoaderButton.active {
  background-color: #E6E6E6;
}
.LogFilterSelect > .LoaderButton i.fa {
  margin-right: 7px;
  color: #908C99;
  font-size: 14px;
}
.LogFilterSelect > .LoaderButton span.copy {
  margin-right: 15px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.LogFilterSelect > .LoaderButton.empty span.copy {
  color: #908C99;
}
.LogFilterSelect > .LoaderButton .CaretIcon {
}

.LogFilterSelect .select {
  position: absolute;
  width: 370px;
  top: 46px;
  right: 0;
  padding: 10px;
  background: white;
  border-radius: 3px;
  overflow-y: scroll;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
              0 2px 3px rgba(0, 0, 0, 0.05),
              0 4px 6px rgba(0, 0, 0, 0.05);
}
.LogFilterSelect .select .SectionHeader {
  font-size: 11px;
}
.LogFilterSelect .select .form-group {
  margin-bottom: 15px;
}
.LogFilterSelect .select .form-group input {
  padding: 6px 10px 4px;
}
.LogFilterSelect .select .form-group .help-block {
  font-size: 14px;
}
.LogFilterSelect .select .options .LoaderButton {
  margin: 0 5px 5px 0;
  padding: 3px 6px;
  background-color: #EFEFF1;
  border: none;
  color: #5D5966;
}
.LogFilterSelect .select .options .LoaderButton:hover {
  background-color: #E2E2E5;
  color: #2E2D33;
}
.LogFilterSelect .select .tips {
  margin-top: 10px;
}
.LogFilterSelect .select .tips .SectionHeader {
  margin-bottom: 7px;
}
.LogFilterSelect .select .tips ul {
  padding-left: 20px;
}
.LogFilterSelect .select .tips ul li {
  color: #908C99;
  font-size: 13px;
  line-height: 1.7;
}
.LogFilterSelect .select hr {
  margin: 10px 0 15px;
}
.LogFilterSelect .select .controls {
  text-align: right;
}
.LogFilterSelect .select .controls .TextButton {
  margin-right: 15px;
  font-size: 12px;
}
.LogFilterSelect .select .controls .LoaderButton {
  border-width: 1px;
}
