.IssueContextPanel {
}

.IssueContextPanel .SectionHeader {
  margin-bottom: 15px;
  font-size: 13px;
}

/**
 * Date
 */
.IssueContextPanel > .date {
  margin-bottom: 13px;
  font-size: 18px;
  color: #908c99;
}

/**
 * Events
 */
.IssueContextPanel .events {
  margin-bottom: 30px;
}
.IssueContextPanel .events > span {
  margin-left: 15px;
  font-size: 14px;
  color: #5d5966;
}
.IssueContextPanel .events > span span {
  font-family: "Source Code Pro", monospace;
}

/**
 * Events Nav
 */
.IssueContextPanel .events-nav {
}
.IssueContextPanel .events-nav a {
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 12px;
  line-height: 1.2;
  outline: none;
}
.IssueContextPanel .events-nav a i.fa {
  font-size: 12px;
  opacity: 0.9;
}

/**
 * Trends
 */
.IssueContextPanel .trends {
  margin-bottom: 30px;
}

/**
 * Seen
 */
.IssueContextPanel .seen {
  margin-bottom: 30px;
}
.IssueContextPanel .seen .times,
.IssueContextPanel .seen .build {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.IssueContextPanel .seen .times span {
  font-size: 13px;
}
.IssueContextPanel .seen span:last-child {
  color: #908c99;
  font-size: 13px;
}
.IssueContextPanel .seen .build {
  margin-top: 7px;
  font-size: 14px;
}
.IssueContextPanel .seen .build a:last-child {
  font-size: 13px;
  color: #908c99;
}
.IssueContextPanel .seen .build a:last-child:hover {
  color: #5d5966;
}
.IssueContextPanel .seen .build a:last-child i.fa {
  margin-left: 1px;
}

/**
 * Lambdas
 */
.IssueContextPanel .lambdas {
}
.IssueContextPanel .lambdas .lambda {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.IssueContextPanel .lambdas .lambda + .lambda {
  margin-top: 7px;
}
.IssueContextPanel .lambdas .lambda span {
  white-space: nowrap;
  font-size: 14px;
}
.IssueContextPanel .lambdas .lambda span:first-child {
  text-overflow: ellipsis;
  overflow: hidden;
}
.IssueContextPanel .lambdas .lambda span:last-child {
  margin-left: 20px;
  color: #908c99;
  font-family: "Source Code Pro", monospace;
}
.IssueContextPanel .lambdas p.more {
  margin: 7px 0 0;
  font-size: 13px;
  color: #908c99;
}
