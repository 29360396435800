.AppList {
}
.AppList .org {
  margin-bottom: 30px;
}
.AppList .org:last-child {
  margin-bottom: 0;
}

/**
 * Header
 */
.AppList .org .header {
  margin: 0 0 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.AppList .org .header h4 {
  margin: 0 10px 0 0;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #908C99;
}
.AppList .org .header h4 a {
  color: #908C99;
}
.AppList .org .header h4 a:hover {
  color: #5D5966;
}
.AppList .org .header h4 a i.fa {
  font-size: 18px;
}
.AppList .org .header > a {
  padding-right: 2px;
  font-size: 15px;
  white-space: nowrap;
}
.AppList .org .header > a i.fa {
  margin-right: 5px;
  font-size: 14px;
  opacity: 0.9;
}

.AppList .org ul {
  padding: 15px;
  list-style: none;
  border-radius: 3px;
  background-color: #EFEFF1;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.075), 0 1px 1px rgba(255, 255, 255, 0.8);
}

/**
 * No Projects
 */
.AppList .noProjects {
  margin: 60px;
  padding: 40px 20px 60px;
  text-align: center;
  list-style-type: none;
  box-sizing: border-box;
  border-radius: 3px;
  border: 3px dashed #E2E2E5;
}
.AppList .noProjects h2 {
  line-height: 1;
  font-weight: 400;
  color: #908C99;
}
.AppList .noProjects a {
  margin-top: 20px;
  padding: 10px;
  display: inline-block;
  line-height: 1;
  border-radius: 3px;
  border: 2px solid #E2E2E5;
}
.AppList .noProjects a .plus {
  margin-left: 6px;
}
.AppList .noProjects a .copy {
  margin-left: 9px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/**
 * New Project
 */
.AppList .newProject {
  list-style-type: none;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.AppList .newProject:last-child {
  margin-bottom: 0;
}
.AppList .newProject a {
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 64px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 3px dashed #E2E2E5;
}
.AppList .newProject a:hover {
  border-color: #C7C6CC;
}
.AppList .newProject a .plus {
  margin-left: 6px;
}
.AppList .newProject a .copy {
  margin-left: 7px;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/**
 * Project
 */
.AppList .project {
  margin-bottom: 15px;
}
.AppList .project:last-child {
  margin-bottom: 0;
}
