.CustomDomainForm {
}

.CustomDomainForm .endpoints {
}

.CustomDomainForm .endpoints > .SectionHeader {
  margin-bottom: 5px;
}

.CustomDomainForm .endpoints .list {
  border-radius: 3px;
  overflow: hidden;
}

/**
 * Endpoint
 */
.CustomDomainForm .endpoints .endpoint {
  margin-bottom: 2px;
  background-color: #efeff1;
  padding: 13px 15px 15px;
}
.CustomDomainForm .endpoints .endpoint:last-child {
  margin: 0;
}
.CustomDomainForm .endpoints .endpoint .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CustomDomainForm .endpoints .endpoint .info .col1 {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}
.CustomDomainForm .endpoints .endpoint .col1 h4 {
  margin: 0 0 8px;
  color: #2e2d33;
  overflow: hidden;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.CustomDomainForm .endpoints .endpoint .col1 h6 {
  margin: 0;
  color: #908c99;
  font-size: 14px;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.CustomDomainForm .endpoints .endpoint .col1 h6 span {
  padding-right: 6px;
}
.CustomDomainForm .endpoints .endpoint .col1 h6 span i.fa {
  margin-left: 2px;
  opacity: 0.7;
}
.CustomDomainForm .endpoints .endpoint .col1 h6 a {
  color: #908c99;
}
.CustomDomainForm .endpoints .endpoint .col1 h6 a:hover {
  color: #5d5966;
}
.CustomDomainForm .endpoints .endpoint .col2 {
  margin-left: 15px;
}
.CustomDomainForm .endpoints .endpoint .col2 .LoaderButton {
  padding-right: 0;
}
.CustomDomainForm .endpoints .endpoint .form {
  margin-top: 15px;
}

/**
 * No endpoints
 */
.CustomDomainForm .endpoints .no-endpoints {
  border-radius: 3px;
  font-size: 14px;
  padding: 15px;
  background-color: #efeff1;
  color: #5d5966;
}

/**
 * Controls
 */
.CustomDomainForm > div.controls {
  margin-top: 15px;
}
.CustomDomainForm > div.controls .TextButton {
  display: inline-block;
  font-size: 14px;
}
