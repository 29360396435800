.ReportFilterSelect {
  position: relative;
  display: flex;
  align-items: center;
}
.ReportFilterSelect > .SectionHeader {
  margin: 0 10px 0 0;
  color: #AEACB5;
}

.ReportFilterSelect > a .LoaderButton.option {
  padding: 6px 15px;
  border-radius: 15px;
  font-weight: 500;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
}
.ReportFilterSelect > a .LoaderButton.option.active,
.ReportFilterSelect > a .LoaderButton.option.active:hover {
  color: #5D5966;
  background-color: #C7C6CC;
}
.ReportFilterSelect > a+a {
  margin-left: 10px;
}
