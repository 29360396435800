.OrgMembers {
  padding: 20px;
}

.OrgMembers .columns {
  display: flex;
  align-items: flex-start;
}

.OrgMembers .columns .OrgMembersPanel {
  flex: 1 1 67%;
  min-width: 450px;
}
.OrgMembers .columns .EnforceTwoFactorSettingPanel {
  margin-left: 15px;
  flex: 1 1 33%;
  min-width: 300px;
  max-width: 400px;
}

@media all and (max-width: 800px) {
  .OrgMembers .columns {
    flex-wrap: wrap;
  }
  .OrgMembers .columns .EnforceTwoFactorSettingPanel {
    margin-top: 30px;
    margin-left: 0;
  }
}
