.Stages {
  padding: 20px;
}

.Stages .PermissionErrorPanel {
  margin-bottom: 15px;
}

.Stages > .cols {
  display: flex;
}
.Stages > .cols > .col1 {
  margin-right: 20px;
  flex: 0 0 auto;
}
.Stages > .cols > .col2 {
  flex: 1 1 auto;
  min-width: 0;
}
.Stages > .cols > .col2 > h4 {
  margin-top: 1px;
  margin-bottom: 12px;
}

.Stages div.services-separator {
  padding: 30px 0;
  position: relative;
  text-align: center;
}
.Stages div.services-separator hr {
  margin: 0;
  border-width: 8px;
  border-color: #E2E2E5;
}
.Stages div.services-separator .SectionHeader {
  position: absolute;
  left: 50%;
  margin: -7px 0 0 -86px;
  top: 50%;
  background-color: #F9F9FA;
  padding: 0 7px;
  display: inline;
}

.Stages .AppActivityList {
  margin-top: 30px;
}
