.ResetPwForm {
}

.ResetPwForm p.desc {
  font-size: 15px;
  color: #5D5966;
  line-height: 1.6;
}

.ResetPwForm .help-block {
  margin-top: 7px;
  font-size: 14px;
}

.ResetPwForm .controls {
  margin-top: 30px;
}
.ResetPwForm .controls .TextButton {
  margin-left: 15px;
  font-size: 14px;
}
