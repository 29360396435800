.ResourcesPanel {
}

.ResourcesPanel .content-container {
  margin-top: 17px;
  border-top: 1px solid #E2E2E5;
  position: relative;
}
.ResourcesPanel .content-container .tab-controls {
  text-align: center;
  position: relative;
  top: -16px;
}
.ResourcesPanel .content-container .tab-content {
  margin-top: -16px;
}

.ResourcesPanel .content-container .btn-group .btn {
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  color: #5D5966;
  letter-spacing: 1px;
}
.ResourcesPanel .content-container .btn-group .btn:active,
.ResourcesPanel .content-container .btn-group .btn.active {
  background-color: #E6E6E6;
  box-shadow: none;
  border-color: #ADADAD;
}
.ResourcesPanel .content-container .btn-group .btn.active:hover {
  background-color: #E6E6E6;
  border-color: #ADADAD;
}
.ResourcesPanel .content-container .btn-group .btn:focus {
  outline: none;
}

.ResourcesPanel p.stack-name {
  margin-bottom: 0;
  padding: 10px 15px;
  font-size: 14px;
  color: #AEACB5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ResourcesPanel ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ResourcesPanel li.header {
  padding: 10px 15px 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #E2E2E5;
}
.ResourcesPanel .website-component li.header {
  padding-top: 15px;
}
.ResourcesPanel li.header span:first-child {
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  color: #908C99;
  letter-spacing: 1px;
}
.ResourcesPanel li.header .TextButton {
  font-size: 13px;
}
.ResourcesPanel .ResourceItem {
  border-bottom: 1px solid #EFEFF1;
}
.ResourcesPanel .ResourceItem:last-child {
  border-bottom: 0 none;
}
.ResourcesPanel .website-component .ResourceItem {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ResourcesPanel .empty {
  padding: 60px 15px;
  text-align: center;
  color: #908C99;
}
