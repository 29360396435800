.GraphPanel {
  position: relative;
}

.GraphPanel .xAxis line,
.GraphPanel .yAxis line {
  stroke: #908C99;
}
.GraphPanel .xAxis text,
.GraphPanel .yAxis text {
  fill: #908C99;
  font-size: 12px;
}

.GraphPanel .empty {
  margin-top: -24px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%,-50%);
  font-size: 14px;
  color: #908C99;
}
.GraphPanel .LoadingSpinner {
  top: 50%;
  left: 50%;
  margin-left: -35px;
  margin-top: -35px;
  position: absolute;
}
.GraphPanel .LoadingSpinner .spinner {
  margin-top: 0;
  margin-bottom: 0;
}
