.NewAppConfigForm {}

.NewAppConfigForm>p.copy {
  color: #5D5966;
  font-size: 14px;
}

.NewAppConfigForm.disabled>p.copy {
  opacity: 0.5;
}

.NewAppConfigForm>.controls {
  margin-top: 30px;
}

.NewAppConfigForm .form-container {
  margin-top: 30px;
  border: 2px solid #C7C6CC;
  border-radius: 3px;
  padding: 35px 20px 22px;
  position: relative;
}

.NewAppConfigForm.disabled .form-container {
  border-color: #E2E2E5;
}

.NewAppConfigForm .form-container .btn-group {
  position: absolute;
  top: -17px;
  left: 20px;
}

.NewAppConfigForm.disabled .form-container .btn-group {
  background-color: #E6E6E6;
  border-radius: 4px;
}

.NewAppConfigForm .form-container .btn-group .btn.active:hover {
  background-color: #E6E6E6;
  border-color: #ADADAD;
}

.NewAppConfigForm.disabled .form-container .btn-group .btn {
  color: #908C99;
}

.NewAppConfigForm .tab-inherit .form-group,
.NewAppConfigForm .tab-manual .form-group:last-child {
  margin-bottom: 0;
}

/**
 * Stage IAM Form
 */
.NewAppConfigForm .stage-iam-container {}

.NewAppConfigForm .stage-iam-container.dev {
  margin-bottom: 30px;
}

.NewAppConfigForm .stage-iam-container.disabled {
  opacity: 0.5;
}

.NewAppConfigForm .stage-iam-container .copy {
  font-size: 14px;
}

.NewAppConfigForm .stage-iam {
  padding: 18px 20px 20px;
  border-radius: 3px;
  background-color: #EFEFF1;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.NewAppConfigForm .stage-iam .StyledControlLabel.disabled {
  opacity: 0.5;
}

/** Form Fields **/
.NewAppConfigForm .stage-iam .form-group>.help-block {
  font-size: 14px;
}

.NewAppConfigForm .stage-iam .label-container {
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NewAppConfigForm .stage-iam .label-container .StyledControlLabel {
  margin-bottom: 0;
}

.NewAppConfigForm .stage-iam .label-container .btnShowIamRolePanel {
  padding-right: 1px;
}

.NewAppConfigForm .stage-iam .label-container .help-block {
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 1px;
  font-size: 14px;
  color: #908C99;
}

.NewAppConfigForm .stage-iam .label-container .help-block code {
  background-color: transparent;
  color: #5D5966;
  padding: 0 4px;
  font-size: 13px;
}

.NewAppConfigForm .stage-iam .checkbox {
  margin: 20px 0 0;
}

.NewAppConfigForm .stage-iam .checkbox label {
  color: #5D5966;
}

.NewAppConfigForm .stage-iam-container.disabled .stage-iam .checkbox label {
  color: #908C99;
}

.NewAppConfigForm .stage-iam .controls .NewAppErrorPanel {
  margin-top: 25px;
  animation: show 0.4s ease;
}

.NewAppConfigForm .stage-iam .controls {
  margin-top: 20px;
}

.NewAppConfigForm .stage-iam .controls .LoaderButton {
  padding: 12px 12px;
}

.NewAppConfigForm .form-container .inherit-copy {
  margin-top: 15px;
  font-size: 14px;
  color: #5D5966;
}

.NewAppConfigForm .form-container .inherit-copy p {
  margin-bottom: 6px;
}

.NewAppConfigForm .form-container .inherit-copy ul {
  margin-bottom: 0;
}

.NewAppConfigForm .form-container .inherit-copy ul li {
  margin-top: 4px;
  margin-bottom: 4px;
}

.NewAppConfigForm .form-container .inherit-copy ul li:last-child {
  margin-bottom: 0;
}

.NewAppConfigForm .form-container .inherit-copy ul li i {
  top: 3px;
  opacity: 0.8;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}