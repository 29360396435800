.DashboardIssuesPanel {
}
.DashboardIssuesPanel .title {
  margin: 0 0 10px;
  padding-top: 1px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #DADEE2;
}
.DashboardIssuesPanel .title > span {
  font-size: 16px;
  font-weight: 500;
}
.DashboardIssuesPanel .title > span span {
  margin-left: 7px;
  color: #AEACB5;
  font-weight: normal;
  font-size: 13px;
}
.DashboardIssuesPanel .title a {
  font-size: 13px;
  font-weight: normal;
}

.DashboardIssuesPanel .issue {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DashboardIssuesPanel .issue+.issue {
  margin-top: 10px;
}
.DashboardIssuesPanel .issue .col1 {
  min-width: 0;
  margin-right: 15px;
}
.DashboardIssuesPanel .issue a {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
}
.DashboardIssuesPanel .issue p {
  margin-bottom: 0;
  color: #908C99;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
}
.DashboardIssuesPanel .issue > a {
  flex: 0 0 auto;
}
.DashboardIssuesPanel .issue > a span {
  flex: 0 0 auto;
  font-size: 12px;
  color: #AEACB5;
}
.DashboardIssuesPanel .issue > a:hover span {
  color: #330033;
}

/**
 * Empty
 */
.DashboardIssuesPanel .list > .empty {
  padding: 30px;
  color: #908C99;
  text-align: center;
  font-size: 14px;
}
.DashboardIssuesPanel .list > .empty i.fa-thumbs-o-up {
  margin-right: 6px;
  opacity: 0.9;
}
.DashboardIssuesPanel .list > .empty p {
  margin-bottom: 0;
}
.DashboardIssuesPanel .list > .empty p:first-child {
  margin-bottom: 3px;
}
.DashboardIssuesPanel .list > .empty b {
  font-weight: 500;
}

/**
 * Footer
 */
.DashboardIssuesPanel .footer {
  padding-top: 15px;
}
.DashboardIssuesPanel .footer p {
  margin: 0;
  font-size: 12px;
  color: #AEACB5;
}
