.ButtonCheckbox {
  color: #6F6292;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #685A8D;
  letter-spacing: 1px;
  padding: 3px 8px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Exo 2", sans-serif;
}
.ButtonCheckbox:focus {
  outline: none;
}
.ButtonCheckbox:hover,
.ButtonCheckbox[disabled]:hover {
  color: #6F6292;
  border-color: #685A8D;
  background-color: transparent;
}
.ButtonCheckbox.checked,
.ButtonCheckbox[disabled].checked:hover {
  color: white;
  background-color: #685A8D;
}

.ButtonCheckbox[disabled] {
  cursor: default;
  opacity: 0.75;
}

.ButtonCheckbox[disabled]:hover {
  opacity: 0.75;
}
.ButtonCheckbox[disabled].checked:hover {
  opacity: 0.75;
}
