.ActivityFilterSelect {
  position: relative;
  display: flex;
  align-items: center;
}
.ActivityFilterSelect > .SectionHeader {
  margin: 0 10px 0 0;
}

/**
 * Buttons
 */
.ActivityFilterSelect > a .LoaderButton.option {
  padding: 7px 15px;
  border-radius: 16px;
  font-weight: 500;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
}
.ActivityFilterSelect > a+a {
  margin-left: 10px;
}

.ActivityFilterSelect > .LoaderButton.other {
  margin-left: 0;
  padding-left: 15px;
  padding-right: 0;
  display: flex;
  align-items: center;
  padding: 5px 11px;
  height: 30px;
}
.ActivityFilterSelect > .LoaderButton.other .CaretIcon {
  margin-left: 5px;
  border-color: #6A5CA2 transparent;
  border-width: 4px 4px 0 4px
}
.ActivityFilterSelect > .LoaderButton.other:hover .CaretIcon {
  border-color: #330033 transparent;
}
.ActivityFilterSelect > a .LoaderButton.all {
  padding: 7px 12px;
  border-radius: 3px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
}

/**
 * Dropdown
 */
.ActivityFilterSelect .select {
  position: absolute;
  z-index: 1;
  width: 310px;
  top: 38px;
  right: -4px;
  padding: 15px;
  background: white;
  border-radius: 3px;
  overflow-y: scroll;
  max-height: 235px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
              0 2px 3px rgba(0, 0, 0, 0.05),
              0 4px 6px rgba(0, 0, 0, 0.05);
}
.ActivityFilterSelect .select .SectionHeader {
  margin-bottom: 15px;
}
.ActivityFilterSelect .select .form-group {
  margin-bottom: 0;
}
.ActivityFilterSelect .select div.or {
  margin: 0 10px;
  position: relative;
  padding: 9px 0 11px;
  text-align: center;
}
.ActivityFilterSelect .select div.or span {
  background-color: white;
  position: relative;
  padding: 0 6px;
  font-size: 12px;
  color: #AEACB5;
}
.ActivityFilterSelect .select div.or hr {
  margin: 0;
  top: 50%;
  position: absolute;
  width: 100%;
}
