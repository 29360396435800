.AppPipelineInfoPanel {
  display: flex;
  align-items: center;
}
.AppPipelineInfoPanel > div {
  margin-right: 15px;
  height: 44px;
  box-sizing: border-box;
  background-color: #EFEFF1;
  border-radius: 3px;
  padding: 12px;
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.AppPipelineInfoPanel > .LoaderButton {
  height: 44px;
  box-sizing: border-box;
  border-width: 1px;
}
.AppPipelineInfoPanel > .LoaderButton i.fa {
  margin-right: 7px;
  vertical-align: -1px;
  font-size: 16px;
}

.AppPipelineInfoPanel > div p {
  margin: 0;
  font-size: 14px;
  color: #5D5966;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.AppPipelineInfoPanel > div p b {
  font-weight: 500;
}
.AppPipelineInfoPanel > div p i.fa {
  margin-right: 7px;
  font-size: 18px;
  vertical-align: -2px;
  color: #908C99;
}
.AppPipelineInfoPanel > div p span.empty {
  color: #D9534F;
}
.AppPipelineInfoPanel > div p span.empty i.fa {
  margin-right: 6px;
  vertical-align: -1px;
  color: #BE2F2B;
  font-size: 16px;
}

.AppPipelineInfoPanel a.settings {
  margin-left: 10px;
  font-size: 13px;
  white-space: nowrap;
}
.AppPipelineInfoPanel a.settings i.fa {
  margin-left: 2px;
}

