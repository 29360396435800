.AppSettingDeployWorkflowPanel {
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
              0 1px 2px rgba(0, 0, 0, 0.05),
              0 2px 3px rgba(0, 0, 0, 0.05);
}

.AppSettingDeployWorkflowPanel .scroll-content {
}
.AppSettingDeployWorkflowPanel .graphic {
  padding: 30px 45px 30px 0;
  display: flex;
  align-items: center;
}
.AppSettingDeployWorkflowPanel .graphic > .pointers {
  flex: 0 0 30px;
  position: relative;
}
.AppSettingDeployWorkflowPanel .graphic > .pointers hr {
  margin: 0;
  border-width: 4px;
  border-color: #C7C6CC;
}

.AppSettingDeployWorkflowPanel .graphic > .spacer {
  flex: 0 0 auto;
  width: 25px;
  height: 1px;
}
.AppSettingDeployWorkflowPanel .graphic > .tag.end span {
  left: auto;
  right: 0;
}
.AppSettingDeployWorkflowPanel .graphic > .tag.end span.copy {
  padding-right: 0;
}
.AppSettingDeployWorkflowPanel .graphic > .tag {
  position: relative;
  top: 18px;
}
.AppSettingDeployWorkflowPanel .graphic > .tag hr {
  margin: 0 0 0 50%;
  width: 50%;
  border-width: 4px;
  border-color: #C7C6CC;
}
.AppSettingDeployWorkflowPanel .graphic > .tag a,
.AppSettingDeployWorkflowPanel .graphic > .tag span.copy {
  padding: 14px 10px 10px;
  display: inline-block;
  font-family: "Source Code Pro", monospace;
  color: #908C99;
  line-height: 1;
  font-size: 12px;
}
.AppSettingDeployWorkflowPanel .graphic > .tag span.separator {
  margin-left: 50%;
  position: absolute;
  top: -3px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #C7C6CC;
  border-radius: 50%;
}
.AppSettingDeployWorkflowPanel .graphic > .tag.end hr {
  margin-left: 0;
}

.AppSettingDeployWorkflowPanel .phase {
  margin: 0;
  flex: 0 0 270px;
  width: 270px;
}
.AppSettingDeployWorkflowPanel .phase .header {
  display: flex;
  justify-content: space-between;
}
.AppSettingDeployWorkflowPanel .phase .header .StyledControlLabel {
  font-size: 14px;
}
.AppSettingDeployWorkflowPanel .phase .header .TextButton {
  font-size: 14px;
  padding-right: 2px;
}
.AppSettingDeployWorkflowPanel .phase .list {
  margin-bottom: 25px; /** Height of the header **/
  padding: 10px;
  background-color: #E2E2E5;
  border-radius: 6px;
}
.AppSettingDeployWorkflowPanel .phase .list .service {
  margin-bottom: 10px;
  font-size: 18px;
  padding: 8px 10px;
  background-color: #F9F9FA;
  border-radius: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.AppSettingDeployWorkflowPanel .phase .list .service .ServiceIcon {
  margin-right: 7px;
}
.AppSettingDeployWorkflowPanel .phase .list .service span {
}
.AppSettingDeployWorkflowPanel .phase .list hr {
  margin: 10px;
  border-color: #C7C6CC;
}
.AppSettingDeployWorkflowPanel .phase .list .form-group {
  margin-bottom: 0;
}

.AppSettingDeployWorkflowPanel .add .LoaderButton {
  padding: 12px 15px;
}
.AppSettingDeployWorkflowPanel .add .LoaderButton:hover {
}
.AppSettingDeployWorkflowPanel .add .LoaderButton i.fa {
  margin: 0 7px 0 0;
  opacity: 0.9;
  font-size: 20px;
  vertical-align: -1px;
}

.AppSettingDeployWorkflowPanel .post-deploy-phase {
  flex: 0 0 185px;
  width: 185px;
}
.AppSettingDeployWorkflowPanel .post-deploy-phase .StyledControlLabel {
  font-size: 14px;
}
.AppSettingDeployWorkflowPanel .post-deploy-phase .copy {
  margin-bottom: 25px; /** Height of the header **/
  border: 4px dotted #E2E2E5;
  border-radius: 4px;
  font-size: 13px;
  color: #908C99;
  padding: 10px;
}

.AppSettingDeployWorkflowPanel .AppActivityWorkflowBuild {
  margin-bottom: 10px;
}
.AppSettingDeployWorkflowPanel .AppActivityWorkflowBuild:last-child {
  margin-bottom: 0;
}

.AppSettingDeployWorkflowPanel .controls {
  padding: 15px;
  border-top: 1px solid #E2E2E5;
}

.AppSettingDeployWorkflowPanel .help-block {
  margin-left: 12px;
  display: inline;
  color: #FD9527;
  font-size: 14px;
  animation: show 0.4s ease;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
