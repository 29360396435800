.OrgNameForm {
}

.OrgNameForm form .form-group {
  margin-bottom: 0;
}

.OrgNameForm form.display input:not(:focus) {
  background-color: transparent;
}

.OrgNameForm form.display .help-block {
  margin-bottom: 0;
}

.OrgNameForm form .help-block {
  margin-top: 7px;
  font-size: 14px;
}
.OrgNameForm p.status {
  margin: 7px 0 0;
  font-size: 14px;
  color: #FD9527;
}
.OrgNameForm .controls {
  margin-top: 20px;
}
.OrgNameForm .controls .TextButton {
  margin-left: 15px;
  font-size: 14px;
}
