/**
 *
 * text         : #2E2D33;
 * secondary    : #5D5966;
 * tertiary     : #908C99;
 * quaternary   : #AEACB5;
 *
 * red          : #BE2F2B;
 *
 * brand        : #53437D;
 *
 * accent       : #FD9527;
 *
 * brand icon   : #74649A;
 * link         : #6A5CA2;
 *
 * headers      : #EFEFF1;
 *
 * background   : #F9F9FA;
 *
 * light div    : #E2E2E5;
 * lighter div  : #DBDADE;
 * divider      : #C7C6CC;
 *
 * red text     : #D9534F;
 * red darker   : #BE2F2B;
 *
 */
html, body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #2E2D33;
  background-color: #F9F9FA;
}

#root {
  height: 100%;
}

#email-support-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  color: white;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #52437D;
}

#email-support-button > i.fa {
  margin-right: 2px;
}

body .form-control,
body .form-control:focus,
.form-group .form-control,
.form-group .form-control:focus {
  box-shadow: none;
  outline: none;
  border-radius: 3px;
}
body .form-control:focus,
.form-group .form-control:focus {
  border-color: #9E92BA;
}

input[type=file] {
  width: 100%;
}

code, kbd, pre, samp {
  font-family: "Source Code Pro", monospace;
}

hr {
  border-color: #E2E2E5;
}

/**
 * Links
 */
a,
.btn-link,
.TextButton {
  color: #6A5CA2;
  transition: .3s;
}
a:hover,
a:focus,
.btn-link:hover,
.btn-link:focus,
.TextButton:not(.disabled):hover {
  color: #330033;
  text-decoration: none;
}
a:active,
.btn-link:active,
.TextButton:active {
  color: #5F5392;
}
a.btn-red-link {
  color: #BE2F2B;
}
a.btn-red-link:hover {
  color: #8E1514;
}

::selection {
  background: #FEC78C;
}

/**
 * Modals
 */

.modal-dialog .modal-content {
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.09), 
              0 2px 4px rgba(0,0,0,0.09), 
              0 4px 8px rgba(0,0,0,0.09), 
              0 8px 16px rgba(0,0,0,0.09),
              0 16px 32px rgba(0,0,0,0.09), 
              0 32px 64px rgba(0,0,0,0.09);
}
.modal-dialog .modal-header {
}
.modal-dialog .modal-header button {
  line-height: 25px;
}
.modal-dialog .modal-footer {
  border: 0;
  border-radius: 0 0 5px 5px;
  background-color: #F4F4F5;
  padding: 16px 15px 14px;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  /** Stagger with dialog animation **/
  transition: opacity 0.23s linear;
}
.modal-backdrop.in {
  opacity: 1;
}
.modal-backdrop.blur {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}
.modal.fade .modal-dialog {
  /** Stagger with backdrop animation **/
  transition-duration: 0.25s;
}
.modal.in .modal-dialog {
}

/*
 * If the page width is smaller than the dialog width,
 * then the help button in the bottom right covers the
 * action button of the dialog.
 */
@media all and (max-width: 768px) {
  .modal-dialog {
    margin-bottom: 100px;
  }
}
