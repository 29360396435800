.IssuesBarGraph {
  width: 170px;
  position: relative;
}
.IssuesBarGraph.wide {
  width: 315px;
}

/** Speech bubble styles **/
/** https://leaverou.github.io/bubbly/ **/
.IssuesBarGraph .tip {
  width: 120px;
  position: relative;
  box-sizing: border-box;
  padding: 8px 8px 5px;
	background: white;
	border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04),
              0 1px 2px rgba(0, 0, 0, 0.05),
              0 2px 2px rgba(0, 0, 0, 0.06);
}

.IssuesBarGraph .tip:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 7px solid transparent;
	border-bottom-color: #ffffff;
	border-top: 0;
	margin-left: -7px;
	margin-top: -7px;
}
.IssuesBarGraph.wide .tip:after {
  display: none;
}

.IssuesBarGraph .tip .duration {
  margin-bottom: 1px;
  padding-bottom: 5px;
  color: #908C99;
  font-size: 11px;
  border-bottom: 1px solid #EFEFF1;
}
.IssuesBarGraph .tip .duration p {
  margin: 0;
  white-space: nowrap;
  text-align: center;
}
.IssuesBarGraph .tip .duration p.hour {
  margin-top: 2px;
}
.IssuesBarGraph .tip .count {
  margin: 0;
  text-align: center;
}
.IssuesBarGraph .tip .count .StyledControlLabel {
  margin-right: 6px;
  margin-bottom: 0;
  font-size: 10px;
  display: inline;
  color: #AEACB5;
}
.IssuesBarGraph .tip .count span {
  font-family: "Source Code Pro", monospace;
  font-size: 12px;
  color: #2E2D33;
  vertical-align: baseline;
}
