.AppAddServiceModal {
}

.AppAddServiceModal .form-container:focus {
  outline: none;
}

.AppAddServiceModal .btnShow {
}

.AppAddServiceModal .SectionDescriptionLabel b {
  font-weight: 500;
}

.AppAddServiceModal form.search {
  display: flex;
  margin-bottom: 5px;
}

.AppAddServiceModal form.search input {
  margin-right: 15px;
  flex-grow: 1;
}

.AppAddServiceModal form.name {
  margin-top: 12px;
}
.AppAddServiceModal form.name .form-group {
  width: 55%;
  margin-bottom: 0;
}
.AppAddServiceModal form.name.single .form-group {
  width: 100%;
}
.AppAddServiceModal form.name .form-group.service-type {
  margin-right: 10px;
  width: calc(45% - 10px);
}
.AppAddServiceModal form.name .form-group > * {
  width: 100%;
}
.AppAddServiceModal form.name .form-group label {
  margin-bottom: 7px;
}
.AppAddServiceModal form.name .form-group select {
  font-size: 16px;
}
.AppAddServiceModal .help-block.invalid-name {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
  color: #A94442;
}

.AppAddServiceModal .controls {
  margin-top: 20px;
}
.AppAddServiceModal .btnCancel {
  font-size: 14px;
}

.AppAddServiceModal .status {
  display: flex;
  padding: 15px 15px 15px 5px;
  border: 1px solid #B1A9C5;
  border-radius: 3px;
  align-items: center;
}

.AppAddServiceModal .status .body {
  min-width: 0;
  flex-grow: 1;
}
.AppAddServiceModal .status .body > p {
  margin-bottom: 0;
  font-size: 14px;
  color: #5D5966;
  line-height: 1.65;
}
.AppAddServiceModal .status.search .body p {
  margin-top: 2px;
}
.AppAddServiceModal .body p b {
  font-weight: 500;
}
.AppAddServiceModal .status.detected .body p code,
.AppAddServiceModal .status.searching .body p code,
.AppAddServiceModal .status.confirmed .body p code {
  background-color: #EFEFF1;
  color: #2E2D33;
}

.AppAddServiceModal .status.detected {
  border-color: #FD9527;
}
.AppAddServiceModal .status.detected .icon {
  padding: 0 10px 0 10px;
}
.AppAddServiceModal .status.detected .body .service {
  min-width: 0;
}
.AppAddServiceModal .status.detected .body .service .name {
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.AppAddServiceModal .status.detected .body .service .path {
  margin-bottom: 0;
  font-size: 14px;
  color: #908C99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AppAddServiceModal .status.not-found {
  border-color: #D43F3A;
  padding-bottom: 20px;
  align-items: flex-start;
}
.AppAddServiceModal .status.not-found .icon {
  padding: 9px 15px 0 12px;
  flex: 0 0 auto;
}
.AppAddServiceModal .status.not-found .icon .fa {
  color: #A35502;
  font-size: 28px;
}

.AppAddServiceModal .controls .LoaderButton {
  margin-left: 10px;
}
.AppAddServiceModal .controls .LoaderButton:first-child,
.AppAddServiceModal .controls .LoaderButton:last-child {
  margin-left: 0;
}

.AppAddServiceModal .btnNewSearch {
  margin-top: 5px;
  padding-bottom: 0;
  padding-left: 0;
  text-align: left;
}

.AppAddServiceModal .modal-footer button:last-child {
  margin-left: 15px;
}
