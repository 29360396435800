.ServicePathForm {
}

.ServicePathForm form .form-group {
  margin-bottom: 0;
}

.ServicePathForm form.display input:not(:focus) {
  background-color: transparent;
}

.ServicePathForm form .help-block {
  margin-top: 7px;
  font-size: 14px;
}
.ServicePathForm p.status {
  margin: 7px 0 0;
  font-size: 14px;
  color: #FD9527;
}
.ServicePathForm .controls {
  margin-top: 20px;
}
.ServicePathForm .controls .TextButton {
  margin-left: 15px;
  font-size: 14px;
}
