.BuildHelpVoteButton {
}

.BuildHelpVoteButton .btn-group {
  margin-right: 10px;
}
.BuildHelpVoteButton button {
  font-size: 12px;
  padding: 4px 10px 3px;
  color: #5D5966;
  width: 34px;
}
.BuildHelpVoteButton button i.fa {
  opacity: 0.9;
}
.BuildHelpVoteButton .glyphicon {
  top: 2px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.BuildHelpVoteButton span.copy {
  font-size: 14px;
  color: #908C99;
}
.BuildHelpVoteButton span.copy.highlight {
  color: #FD9527;
}
