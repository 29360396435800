.Metrics {
  padding: 20px;
}

.Metrics .ScreenHeader {
}
.Metrics .ScreenHeader .action::after {
  content: "";
  clear: both;
  display: table;
}
.Metrics .ScreenHeader .LogDurationSelect {
  float: right;
}

.Metrics .PermissionErrorPanel {
  margin-bottom: 20px;
}

.Metrics .MetricsPanel {
  margin-bottom: 30px;
}

/**
 * Error Sign
 */
.Metrics .error-invalid {
  margin: 0 auto;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.Metrics .error-invalid p {
  margin-top: 15px;
  color: #908C99;
}
.Metrics .error-invalid p a {
  font-weight: 500;
}
