.SectionGroupPanel {
}

.SectionGroupPanel > h4 {
  margin-top: 0;
}

.SectionGroupPanel > .body {
  border: 1px solid #E2E2E5;
  border-radius: 3px;
  padding: 20px 18px 30px;
}
.SectionGroupPanel > .body > hr {
  margin: 30px 0;
}

.SectionGroupPanel.important > .body {
  border-color: #BE2F2B;
}
.SectionGroupPanel.important > .body > hr {
  opacity: 0.25;
  border-color: #BE2F2B;
}
