.GitRepoSelect {
}

.GitRepoSelect > .LoaderButton {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.GitRepoSelect > .LoaderButton.read-only {
  cursor: not-allowed;
}
.GitRepoSelect > .LoaderButton.read-only:hover {
  background-color: transparent;
  border-color: #CCC;
}
.GitRepoSelect > .LoaderButton.read-only:focus,
.GitRepoSelect > .LoaderButton.read-only:active {
  box-shadow: none;
}
.GitRepoSelect > .LoaderButton.loading {
  display: inline-block;
}

.GitRepoSelect > .LoaderButton span.text {
  text-overflow: ellipsis;
  overflow: hidden;
  flex-basis: 80%;
}
.GitRepoSelect > .LoaderButton .CaretIcon {
  border-color: #5D5966 transparent;
}

/**
 * List Container
 */
.GitRepoSelect .list-container {
  margin-top: 10px;
}

/**
 * Search Form
 */
.GitRepoSelect .list-container form .form-group {
  margin-bottom: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  z-index: 1;
  position: relative;
}
.GitRepoSelect .list-container form input,
.GitRepoSelect .list-container form input:focus {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #E2E2E5;
  padding-left: 35px;
  padding-right: 40px;
  padding-bottom: 9px;
}
.GitRepoSelect .list-container form .form-group label {
  margin-bottom: 0;
  margin-top: -9px;
  left: 12px;
  top: 50%;
  position: absolute;
}
.GitRepoSelect .list-container form .form-group label i.fa {
  display: block;
  font-size: 18px;
  opacity: 0.3;
}
.GitRepoSelect .list-container form .form-group .TextButton {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -22px;
  color: #BBB;
  width: 40px;
  height: 44px;
  text-align: center;
}
.GitRepoSelect .list-container form .form-group .TextButton:hover {
  color: #888;
}
.GitRepoSelect .list-container form .form-group .TextButton i.fa {
  display: block;
  font-size: 18px;
  line-height: 44px;
}

/**
 * List
 */
.GitRepoSelect .list {
  background: white;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #E2E2E5;
  border-radius: 0 0 3px 3px;
  overflow-y: scroll;
  max-height: 212px;
}
.GitRepoSelect .list .item {
  padding: 15px 13px;
  border-top: 1px solid #E2E2E5;
  display: block;
  cursor: pointer;
  color: #5D5966;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.GitRepoSelect .list .item.cursor {
  background-color: #B3AAC8;
  color: white;
}
.GitRepoSelect .list .item.current {
  background-color: #9A8EB6;
  color: white;
}
.GitRepoSelect .list .item:first-child {
  border-top: none;
}

.GitRepoSelect .list .empty {
  padding: 15px 13px;
  display: block;
  color: #C7C6CC;
}

/**
 * Controls
 */
.GitRepoSelect .controls {
  margin-top: 10px;
}
.GitRepoSelect .controls .LoaderButton {
  padding: 0;
  text-align: left;
}
.GitRepoSelect .controls .help-link {
  display: inline-block;
  font-size: 14px;
}
.GitRepoSelect .controls span.separator {
  padding: 0 11px;
  color: #C7C6CC;
}

/**
 * Org Dropdown
 */
.GitRepoSelect .controls .dropdown-copy {
  font-size: 14px;
  color: #5D5966;
}
.GitRepoSelect .controls .dropdown .dropdown-toggle {
  padding: 0;
  font-weight: 500;
}
.GitRepoSelect .controls .dropdown .dropdown-toggle .caret {
  margin-left: 1px;
}
.GitRepoSelect .controls .dropdown .dropdown-menu {
  padding: 0;
  overflow: scroll;
  max-height: 217px;
  width: 320px;
}
.GitRepoSelect .controls .dropdown .dropdown-menu li.divider {
  margin: 0;
}
.GitRepoSelect .controls .dropdown .dropdown-menu li.dropdown-header {
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: "Exo 2", sans-serif;
  letter-spacing: 1px;
  font-size: 11px;
  color: #908C99;
  font-weight: 600;
  text-transform: uppercase;
}
.GitRepoSelect .controls .dropdown .dropdown-menu a {
  overflow: hidden;
  padding-top: 8px;
  padding-bottom: 8px;
  text-overflow: ellipsis;
}
.GitRepoSelect .controls .dropdown .dropdown-menu li:first-child a {
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px 4px 0 0;
}
.GitRepoSelect .controls .dropdown .dropdown-menu li:last-child a {
  border-radius: 0 0 4px 4px;
}
.GitRepoSelect .controls .dropdown .dropdown-menu a:focus {
  outline: none;
}
.GitRepoSelect .controls .dropdown .dropdown-menu .disabled a {
  color: #908C99;
}
.GitRepoSelect .controls .dropdown-menu > .active {
  background-color: #9A8EB6;
}
.GitRepoSelect .controls .dropdown-menu > .active > a,
.GitRepoSelect .controls .dropdown-menu > .active > a:focus,
.GitRepoSelect .controls .dropdown-menu > .active > a:hover {
  background-color: transparent;
}
