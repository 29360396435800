.CommitInfoGroup {
  overflow: hidden;
}
.CommitInfoGroup .message {
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.CommitInfoGroup .message span {
}
.CommitInfoGroup.with-message .info  {
}
.CommitInfoGroup.with-message .info  {
  font-size: 14px;
  color: #5D5966;
}
.CommitInfoGroup .actor-image {
  margin-right: 6px;
  vertical-align: 2px;
}
.CommitInfoGroup i {
  margin-right: 4px;
}
.CommitInfoGroup .info a:last-child {
  font-family: "Source Code Pro", monospace;
}
.CommitInfoGroup span.separator {
  margin: 0 9px;
  color: #929C9A;
}
