.StagesSelectList {
  width: 238px;
  max-height: 700px;
  overflow-y: scroll;
}
.StagesSelectList a {
  padding: 10px;
  font-size: 14px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #EFEFF1;
  border-top: 1px solid #E2E2E5;
  transition: background 0.3s;
}
.StagesSelectList a:hover {
  color: #6A5CA2;
  background-color: #E6E6E8;
}
.StagesSelectList a:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top: 0;
}
.StagesSelectList a:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.StagesSelectList a.active {
  background-color: #DBDADE;
  border-color: transparent;
  font-weight: 500;
}
.StagesSelectList a.active+a {
  border-top: 1px solid transparent;
}

/**
 * Loading
 */
.StagesSelectList.loading {
  padding-top: 128px;
  height: 300px;
  background-color: #EFEFF1;
  opacity: 0;

  animation: fadeIn ease-in 1;
  animation-fill-mode:forwards;
  animation-duration: 0.3s;

  animation-delay: 0.5s; /* Wait before showing the loading state */
}
.StagesSelectList.loading .spinner {
  margin: 0 auto;
  opacity: 1;
}
.StagesSelectList.loading .spinner > div {
  margin-right: 4px;
  width: 8px;
  height: 8px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
