.BuildImageSettingsPanel {
}

.BuildImageSettingsPanel .SectionHeader {
  margin-bottom: 7px;
}
.BuildImageSettingsPanel p.info {
  margin-bottom: 0;
  font-size: 14px;
  color: #908c99;
}

.BuildImageSettingsPanel form {
}
.BuildImageSettingsPanel form .form-group {
  margin-bottom: 0;
}

.BuildImageSettingsPanel p.status {
  margin: 7px 0 -1px;
  font-size: 14px;
  color: #fd9527;
}

.BuildImageSettingsPanel .controls {
  margin-top: 20px;
}
.BuildImageSettingsPanel .controls .TextButton {
  margin-left: 15px;
  font-size: 14px;
}
