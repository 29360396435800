.NewAppDetectServicePanel {
}
.NewAppDetectServicePanel > .wrapper {
  padding: 15px 15px 15px 5px;
  border: 2px solid #B1A9C5;
  border-radius: 3px;
  display: flex;
}
.NewAppDetectServicePanel.detected > .wrapper {
  border-color: #FD9527;
  padding-bottom: 18px;
}
.NewAppDetectServicePanel.not-found > .wrapper {
  border-color: #D43F3A;
  padding-bottom: 18px;
}
.NewAppDetectServicePanel.confirmed > .wrapper {
  align-items: center;
}
.NewAppDetectServicePanel.disabled > .wrapper,
.NewAppDetectServicePanel.searching > .wrapper {
  align-items: center;
  border-color: #C7C6CC;
}
.NewAppDetectServicePanel.disabled > .wrapper {
  opacity: 0.5;
}
.NewAppDetectServicePanel.search > .wrapper {
  padding-bottom: 22px;
}

.NewAppDetectServicePanel .icon {
  flex: 0 0 50px;
  text-align: center;
}
.NewAppDetectServicePanel .icon .fa {
  color: #74649A;
  font-size: 28px;
  opacity: 0.8;
  display: block;
}
.NewAppDetectServicePanel.detected .icon .fa {
  margin-top: 4px;
  color: #FD9527;
}
.NewAppDetectServicePanel.not-found .icon .fa {
  margin-top: 1px;
  color: #A35502;
}
.NewAppDetectServicePanel.add .icon .fa {
  margin-top: 4px;
}
.NewAppDetectServicePanel.disabled .icon .fa,
.NewAppDetectServicePanel.searching .icon .fa {
  color: #908C99;
}
.NewAppDetectServicePanel .body {
}
.NewAppDetectServicePanel .body p {
  margin-bottom: 0;
  color: #5D5966;
  line-height: 1.6;
}
.NewAppDetectServicePanel.search .body p {
  margin-top: 2px;
}
.NewAppDetectServicePanel.disabled .body p {
  color: #908C99;
}
.NewAppDetectServicePanel .body p b {
  font-weight: 500;
}
.NewAppDetectServicePanel.detected .body p code,
.NewAppDetectServicePanel.searching .body p code,
.NewAppDetectServicePanel.confirmed .body p code {
  background-color: #EFEFF1;
  color: #2E2D33;
}
.NewAppDetectServicePanel.detected .body .form-group {
  margin-top: 20px;
}
.NewAppDetectServicePanel.detected .body .form-group select {
  max-width: 450px;
}

.NewAppDetectServicePanel .body form.manual-add {
  margin-top: 20px;
}
.NewAppDetectServicePanel .body form.manual-add .fields {
  display: flex;
}
.NewAppDetectServicePanel .body form.manual-add .fields .form-group:first-child {
  margin-right: 15px;
  flex-grow: 1;
}
.NewAppDetectServicePanel .body form.manual-add .fields .form-group.service-type {
  margin-right: 15px;
}
.NewAppDetectServicePanel .body form.manual-add .fields .form-group.service-type select {
  font-size: 16px;
}
.NewAppDetectServicePanel .body form.manual-add .fields .form-group:last-child {
}
.NewAppDetectServicePanel .body form.manual-add input {
  width: 100%;
}
.NewAppDetectServicePanel .body form.select-add {
  margin-top: 20px;
}
.NewAppDetectServicePanel .body form.select-add .form-group {
  margin-top: 0;
  margin-right: 15px;
}
.NewAppDetectServicePanel .body form.select-add .form-group select {
  color: #5D5966;
  min-width: 320px;
}
.NewAppDetectServicePanel .body form.manual-add .help-block {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
  color: #A94442;
}
.NewAppDetectServicePanel .body .form-group .StyledControlLabel {
  margin-bottom: 7px;
}
.NewAppDetectServicePanel .body .btnChange {
  margin-top: 10px;
  display: inline-block;
  font-size: 14px;
}
.NewAppDetectServicePanel .body .controls {
  margin-top: 15px;
  padding-bottom: 4px;
}
.NewAppDetectServicePanel .body .controls .TextButton {
  font-size: 14px;
}
.NewAppDetectServicePanel .body .controls .TextButton:last-child {
  margin-left: 15px;
}

.NewAppDetectServicePanel > .controls {
  margin-top: 10px;
}
.NewAppDetectServicePanel > .controls .TextButton {
  font-size: 14px;
}
